import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
	tableCellClasses,
	styled,
} from '@mui/material';
import PreviewRichText from '../Inputs/PreviewRichText';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: '#FCFDFD',
		color: '#7C7C7C',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		borderBottom: '1px solid white',
		width: 'fit-content',
	},
	[`&.${tableCellClasses.body}`]: {
		backgroundColor: '#F7F9FA',
		color: '#333333',
		fontFamily: 'Centra',
		fontSize: 12,
		fontWeight: 300,
		height: '76px',
		borderBottom: '1px solid white',
		borderTop: '1px solid white',
		width: 'fit-content',
	},
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0,
	},
}));

export default function ContactsTable({ totalContacts, workflowContacts }) {
	return (
		<div>
			<Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
				<TableContainer sx={{ maxHeight: 160 }}>
					<Table stickyHeader aria-label='sticky table' sx={{ minWidth: 700 }}>
						<TableHead>
							<TableRow>
								<StyledTableCell>Name</StyledTableCell>
								<StyledTableCell>Title</StyledTableCell>
								<StyledTableCell>Email</StyledTableCell>
								<StyledTableCell>Phone</StyledTableCell>
								<StyledTableCell>Mobile</StyledTableCell>
								<StyledTableCell>Address</StyledTableCell>
								<StyledTableCell>Extras</StyledTableCell>
								<StyledTableCell>Notes</StyledTableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{totalContacts?.length > 0 &&
								totalContacts
									?.filter((contact) =>
										workflowContacts?.find((id) => id === contact.id),
									)
									?.map((item, index) => (
										<StyledTableRow key={index}>
											<StyledTableCell>
												{item?.first_name + ' ' + item?.last_name}
											</StyledTableCell>
											<StyledTableCell>{item?.title || '-'}</StyledTableCell>
											<StyledTableCell>
												{(item?.contact?.email?.length > 0 &&
													item?.contact?.email[0]) ||
													'-'}
											</StyledTableCell>
											<StyledTableCell style={{ whiteSpace: 'nowrap' }}>
												{item?.contact?.direct?.length > 0 && (
													<div
														key={index}
														style={{
															paddingTop: '5px',
														}}
													>
														<span>{item?.contact?.direct[0]}</span>
													</div>
												)}
											</StyledTableCell>
											<StyledTableCell>
												{item?.contact?.general?.length > 0 && (
													<div
														key={index}
														style={{
															paddingTop: '5px',
														}}
													>
														<span>{item?.contact?.general[0]}</span>
													</div>
												)}
											</StyledTableCell>
											<StyledTableCell>
												<div className='d-flex flex-column'>
													{item?.address[0] && (
														<span>
															{item?.address[0]?.city}{' '}
															{item?.address[0]?.street}{' '}
															{item?.address[0]?.suite}{' '}
															{item?.address[0]?.zip}{' '}
															{item?.address[0]?.state}{' '}
														</span>
													)}

													{item?.address[1] && (
														<span>
															{item?.address[1]?.city}{' '}
															{item?.address[1]?.street}{' '}
															{item?.address[1]?.suite}{' '}
															{item?.address[1]?.zip}{' '}
															{item?.address[1]?.state}{' '}
														</span>
													)}
												</div>
											</StyledTableCell>
											<StyledTableCell>
												<div className='d-flex flex-column'>
													{item?.contact?.general?.length > 1 && (
														<div className=' mb-0 dark-text'>
															<span className=' mb-0 light-text pr-1'>
																Sec. Mobile:{' '}
															</span>
															{item?.contact?.general[1]}
														</div>
													)}
													{item?.contact?.direct?.length > 1 && (
														<div className=' mb-0 dark-text'>
															<span className=' mb-0 light-text pr-1'>
																Sec. Phone:{' '}
															</span>
															{item?.contact?.direct[1]}
														</div>
													)}
													{item?.contact?.email?.length > 1 && (
														<div className=' mb-0 dark-text'>
															<span className=' mb-0 light-text pr-1'>
																Sec. Email:{' '}
															</span>
															{item?.contact?.email[1]}
														</div>
													)}
												</div>
											</StyledTableCell>
											<StyledTableCell>
												<PreviewRichText
													content={item?.notes}
													className={['BodyThreeLight']}
												/>
											</StyledTableCell>
										</StyledTableRow>
									))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</div>
	);
}
