/* eslint-disable no-mixed-spaces-and-tabs */
import { Modal } from '@mui/material';
import { useState, useEffect, useMemo } from 'react';
import {
	REPEAT_TYPE,
	nameOfDaysOfTheWeek,
	ordinal_suffix_of,
	weekOfMonth,
	isWeekend,
	onWeekendPriority,
} from '../../../utilities/utilities';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import DummyModal from '../../Modal/DummyModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import useModal from '../../Modal/useModal';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DropDown from '../../Inputs/DropDown';
import Select from '../../Inputs/Select';
import * as builderActions from '../../../redux/actions/workflow-builder-actions';
import { getUserSettings } from '../../../hooks/getUserSettings';
import FormButtonOne from '../../Buttons/FormButtonOne';

function RecurrenceModal({ recurrence, onCancel, draft }) {
	const recurrences = [
		{ type: REPEAT_TYPE.DAILY, name: 'Day' },
		{ type: REPEAT_TYPE.WEEKLY, name: 'Week' },
		{ type: REPEAT_TYPE.MONTHLY, name: 'Month' },
		{ type: REPEAT_TYPE.YEARLY, name: 'Year' },
	];
	const dispatch = useDispatch();
	const [formControls, setFormControls] = useState({
		error: '',
		loading: false,
	});
	const [alertModal, setAlertModal] = useState(false);
	const [monthlySpecialDate, setMonthlySpecialDate] = useState(false);
	const [CurrentRecurrence, setCurrentRecurrence] = useState({});
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);
	const { modalIsOpen, toggleModal } = useModal();
	const [selectedDays, setSelectedDays] = useState([]);
	const [dailySelectedDaysValidationError, setDailySelectedDaysValidationError] = useState(null);
	const [scheduleAdjustmentAction, setScheduleAdjustmentAction] = useState(null);

	const handleDayChange = ({ day, id }) => {
		setSelectedDays((prevSelectedDays) => {
			if (prevSelectedDays.find((item) => item.day == day)) {
				// If the day is already selected, remove it
				return prevSelectedDays.filter((item) => item.day !== day);
			} else {
				// If the day is not selected, add it
				return [...prevSelectedDays, { day, id }];
			}
		});
	};

	const recurPreferenceChangeHandler = (event) => {
		const { name, value } = event.target;

		setCurrentRecurrence({
			...CurrentRecurrence,
			[name]: Number(value),
		});
	};

	const changeHandler = (event) => {
		let { name, value } = event.target;

		if (name === 'repeat_interval') {
			value = parseInt(value);
		}

		if (name == 'required_days' && value != '') {
			value = parseInt(value);
		}

		setCurrentRecurrence({
			...CurrentRecurrence,
			[name]: value,
		});
	};

	const endOnChangeHandler = (e) => {
		const { name, value } = e.target;

		if (name === 'type') {
			setCurrentRecurrence({
				...CurrentRecurrence,
				end_on: {
					type: value,
					occurrence: null,
					end_date: null,
				},
			});
		}

		if (name === 'occurrence') {
			setCurrentRecurrence({
				...CurrentRecurrence,
				end_on: {
					type: name,
					[name]: Number(value),
					end_date: null,
				},
			});
		}
	};

	const onEndDateChange = (date) => {
		// if (date < CurrentRecurrence?.start_date) {
		// 	alert("End date can't be less than start date");
		// }
		// else {
		setCurrentRecurrence({
			...CurrentRecurrence,
			end_on: {
				type: 'end_date',
				occurrence: null,
				end_date: date,
			},
		});
		// }
	};

	const onDateChange = (date) => {
		// if (draft) {
		// 	if (CurrentRecurrence?.repeat_type === REPEAT_TYPE.MONTHLY) {
		// 		if (
		// 			weekOfMonth(moment(date)) === 4 &&
		// 			moment(date).add(7, 'days').month() > moment(date).month() &&
		// 			!CurrentRecurrence?.is_monthly_on_day
		// 		) {
		// 			setMonthlySpecialDate(true);
		// 			setCurrentRecurrence({
		// 				...CurrentRecurrence,
		// 				start_date: date,
		// 				recur_preference: null,
		// 			});
		// 		} else {
		// 			setCurrentRecurrence({
		// 				...CurrentRecurrence,
		// 				recur_preference: null,
		// 				start_date: date,
		// 			});
		// 			setMonthlySpecialDate(false);
		// 		}
		// 	} else {
		// 		setCurrentRecurrence({
		// 			...CurrentRecurrence,
		// 			start_date: date,
		// 		});
		// 	}
		// } else {
		if (CurrentRecurrence?.repeat_type === REPEAT_TYPE.MONTHLY) {
			if (
				weekOfMonth(moment(date)) === 4 &&
				moment(date).add(7, 'days').month() > moment(date).month()
				// && !CurrentRecurrence?.is_monthly_on_day
			) {
				setMonthlySpecialDate(true);
				setCurrentRecurrence({
					...CurrentRecurrence,
					start_date: date,
					// recur_preference: null,
				});
			} else {
				setCurrentRecurrence({
					...CurrentRecurrence,
					recur_preference: 0,
					start_date: date,
				});
				setMonthlySpecialDate(false);
			}
		} else {
			setCurrentRecurrence({
				...CurrentRecurrence,
				start_date: date,
			});
		}
		// }
	};

	const validateMonthlyType = () => {
		if (CurrentRecurrence.repeat_type) {
			recurrenceFormSubmit();
		} else {
			alert('Please Select Recurrence Type');
		}
	};

	const toggleAlertModal = () => {
		setAlertModal(!alertModal);
	};

	const onKeepTasks = () => {
		toggleAlertModal();
		validateMonthlyType();
	};

	const onDeleteTasks = () => {
		toggleAlertModal();
		validateMonthlyType();
	};

	// is_monthly_on_day option true:
	// 1- day date

	// is_monthly_on_day false and special_date true
	// 2- that day in current week
	// 3- that day in last day

	// is_monthly_on_day option false and special_date false:
	// 1- that day in current week

	const recur_preferences = useMemo(() => {
		const options = [
			{
				value: 0,
				label: `Monthly on day ${moment(CurrentRecurrence.start_date).format('DD')}`,
			},
			{
				value: 1,
				label: `Monthly on the ${ordinal_suffix_of(
					weekOfMonth(moment(CurrentRecurrence?.start_date)),
				)} ${moment(CurrentRecurrence?.start_date).format('dddd')}`,
			},
		];

		if (monthlySpecialDate) {
			options.push({
				value: 2,
				label: `Monthly on the last ${moment(CurrentRecurrence?.start_date).format(
					'dddd',
				)}`,
			});
		}

		return options;
	}, [CurrentRecurrence.start_date, monthlySpecialDate]);

	const END_ON = [
		{ value: 'occurrence', label: 'Occurrences' },
		{ value: 'end_date', label: 'Date' },
		{ value: 'never', label: 'Never' },
	];

	const recurrenceFormSubmit = () => {
		setFormControls({
			loading: true,
			error: '',
		});

		const recurrenceObj = {
			// ...clientWorkflow,
			id: CurrentRecurrence.id,
			schedule_adjustment_action:
				CurrentRecurrence.repeat_type !== REPEAT_TYPE.DAILY &&
				CurrentRecurrence.repeat_type !== REPEAT_TYPE.WEEKLY
					? scheduleAdjustmentAction
					: 'DO_NOTHING',
			recurring_days:
				CurrentRecurrence.repeat_type == REPEAT_TYPE.DAILY
					? JSON.stringify(selectedDays?.map(({ day }) => day))
					: null,
			start_date: moment(CurrentRecurrence.start_date).format('MM-DD-YYYY'),
			repeat_interval:
				CurrentRecurrence?.repeat_type == REPEAT_TYPE.DAILY
					? 1
					: CurrentRecurrence.repeat_interval,
			repeat_type: CurrentRecurrence.repeat_type,
			// client_workflow_tasks: allWorkflowTasks,
			is_monthly_on_day: monthlySpecialDate
				? CurrentRecurrence.recur_preference == 0
					? true
					: false
				: CurrentRecurrence.recur_preference == 0
				? true
				: false,
			recur_preference: monthlySpecialDate
				? CurrentRecurrence.recur_preference == 0
					? null
					: CurrentRecurrence.recur_preference
				: null,
			end_on: CurrentRecurrence.end_on,
			required_days: parseInt(CurrentRecurrence.required_days) || 0,
		};
		if (CurrentRecurrence.repeat_type !== REPEAT_TYPE.MONTHLY) {
			delete recurrenceObj.is_monthly_on_day;
			recurrenceObj.recur_preference = null;
		}

		const data = {
			...recurrenceObj,
		};
		delete data.workflow_builder;
		delete data.workflow_submission;

		// if (draft) {
		// dispatch(
		// 	builderActions.setAllWorkflowTasks(
		// 		{
		// 			...recurrenceObj,
		// 		},
		// 		{ ...data },
		// 		// onSubmitComplete,
		// 	),
		// );
		// } else {

		dispatch(builderActions.updateClientWorkflow(recurrenceObj))
			.then(() => {
				setFormControls({
					error: '',
					loading: false,
				});
				onCancel();
			})
			.catch((error) => {
				setFormControls({
					error: error?.message,
					loading: false,
				});
			});

		// }
	};

	useEffect(() => {
		const currentDate = moment()?.format('MM/DD/YYYY');
		const startDate = moment(recurrence?.start_date);
		if (!startDate.isSameOrAfter(currentDate)) {
			recurrence.start_date = currentDate;
		}
		setCurrentRecurrence(recurrence);
		recurrence?.required_days &&
			setScheduleAdjustmentAction(recurrence?.schedule_adjustment_action);
		recurrence?.required_days &&
			setSelectedDays(
				recurrence?.recurring_days?.map((item) =>
					nameOfDaysOfTheWeek?.find(({ day }) => day == item),
				) || [],
			);
		if (
			weekOfMonth(moment(recurrence?.start_date)) === 4 &&
			moment(recurrence?.start_date).add(7, 'days').month() >
				moment(recurrence?.start_date).month()
			//     &&
			// !recurrence?.is_monthly_on_day
		) {
			setMonthlySpecialDate(true);
			setCurrentRecurrence({
				...recurrence,
				recur_preference: recurrence?.is_monthly_on_day ? 0 : 1,
			});
		} else if (recurrence?.is_monthly_on_day) {
			setMonthlySpecialDate(false);
			setCurrentRecurrence({
				...recurrence,
				recur_preference: 0,
			});
		} else {
			setMonthlySpecialDate(false);
			setCurrentRecurrence({
				...recurrence,
				recur_preference: 1,
			});
		}
	}, [recurrence]);

	// useEffect(() => {
	// 	if (
	// 		moment(
	// 			moment(CurrentRecurrence?.start_date).format('YYYY-MM-DD'),
	// 		).isBefore(moment(recurrence?.start_date?._i).format('YYYY-MM-DD'))
	// 	) {
	// 		setRecurrenceAlert(true);
	// 	} else {
	// 		setRecurrenceAlert(false);
	// 	}
	// }, [CurrentRecurrence, setRecurrenceAlert]);

	const validateStartEndDate = () => {
		if (CurrentRecurrence.repeat_type == REPEAT_TYPE.DAILY && selectedDays?.length < 1) {
			setDailySelectedDaysValidationError('Select at least one day');
			return null;
		}
		setDailySelectedDaysValidationError(null);

		const startDate = moment(CurrentRecurrence?.start_date).startOf('day').toISOString(); // Start Date
		const endDate = moment(CurrentRecurrence?.end_on?.end_date).startOf('day').toISOString(); // End Date

		const currentDate = moment().startOf('day').toISOString(); // Current date to ISO String format, ignoring time

		const isDateGreaterOrSameCurrent = moment(startDate).isSameOrAfter(currentDate); // If start date is after or the same as the current date
		const isDateGreaterOrSameStart = moment(endDate).isSameOrAfter(startDate); // If end date is after or the same as the start date

		if (isDateGreaterOrSameCurrent && (endDate ? isDateGreaterOrSameStart : true)) {
			toggleModal();
		}
	};

	return (
		<>
			<form
				autoComplete='off'
				className='ContactAdd-Form'
				onSubmit={(e) => {
					e.preventDefault();
					draft
						? validateMonthlyType()
						: // : recurrenceAlert
						  // ? toggleAlertModal()
						  validateStartEndDate();
				}}
			>
				<div className='p-3'>
					<div className='improved-input-designs pb-3'>
						<label className=' '>Repeats</label>
						<div className='TabWrapper'>
							{recurrences.map((item, i) => {
								return (
									<span
										key={i}
										className={
											item.type == CurrentRecurrence.repeat_type
												? 'Tab selected'
												: 'Tab'
										}
										onClick={() => {
											setSelectedDays([]);
											setCurrentRecurrence({
												...CurrentRecurrence,
												repeat_type: item.type,
											});
										}}
										data-cy={`recurrence-${item.type}`}
									>
										{item.type}
									</span>
								);
							})}
						</div>
					</div>

					<div className='improved-input-designs pb-3 d-flex flex-column'>
						<label className='w-100 require'>Starting on</label>
						<KeyboardDatePicker
							autoOk
							disableToolbar
							value={CurrentRecurrence?.start_date || null}
							name='start_date'
							onChange={onDateChange}
							format={date_format}
							minDate={moment()}
							minDateMessage='Date should not be before current date'
							// helperText={null}
							required
							// InputProps={{
							// 	readOnly: true
							// }}
						/>
					</div>

					<div className='improved-input-designs pb-3'>
						<label className='w-100 require'>
							Every{' '}
							{selectedDays
								?.sort((a, b) => a.id - b.id)
								?.map(({ day }, index) =>
									index == 0 ? `- ${day.slice(0, 3)}` : `, ${day.slice(0, 3)}`,
								)}
						</label>
						{REPEAT_TYPE.DAILY == CurrentRecurrence.repeat_type ? (
							<div className='select-day-of-the-week-container d-flex justify-content-between'>
								{nameOfDaysOfTheWeek?.map(({ day, id }) => {
									return (
										<span
											className={`day-of-the-week ${
												selectedDays?.find((item) => item.id == id)
													? 'selected'
													: ''
											}`}
											key={day}
											onClick={() => handleDayChange({ day, id })}
										>
											{day.charAt(0)}
										</span>
									);
								})}
							</div>
						) : (
							<div
								className='d-flex flex-row align-items-center'
								style={{ gap: '5px' }}
							>
								<input
									className='w-50 input-style'
									type='number'
									name='repeat_interval'
									required={true}
									value={CurrentRecurrence?.repeat_interval}
									onChange={changeHandler}
									min={1}
								/>
								<span className='pt-1 pl-1 BodyTwoBold dark-text'>
									{
										recurrences.find(
											(item) => item.type === CurrentRecurrence.repeat_type,
										)?.name
									}
								</span>
							</div>
						)}

						{CurrentRecurrence.repeat_type == REPEAT_TYPE.DAILY &&
							dailySelectedDaysValidationError && (
								<div>
									<span className='error-text BodyTwoLight'>
										{dailySelectedDaysValidationError}
									</span>
								</div>
							)}
					</div>

					{CurrentRecurrence.repeat_type === REPEAT_TYPE.MONTHLY && (
						<div className='improved-input-designs pb-3'>
							<label className=''>Recur Preferences</label>
							<DropDown
								onChange={recurPreferenceChangeHandler}
								className='w-100 '
								value={recur_preferences.find(
									(item) => item.value === CurrentRecurrence?.recur_preference,
								)}
								name='recur_preference'
								// options={RECUR_PREFERENCES}
								options={recur_preferences}
								disableNull
							/>
						</div>
					)}

					<div className='improved-input-designs pb-3'>
						<label className='require'>Ends on</label>
						<Select
							onChange={endOnChangeHandler}
							selectClassName='w-100'
							value={END_ON.find(
								(item) => item.value === CurrentRecurrence?.end_on?.type,
							)}
							name='type'
							options={END_ON}
							required={true}
						/>
					</div>

					{CurrentRecurrence.repeat_type !== REPEAT_TYPE.DAILY &&
						CurrentRecurrence.repeat_type !== REPEAT_TYPE.WEEKLY && (
							<div className='improved-input-designs pb-3'>
								<label className='require'>If the Task Starts on the Weekend</label>
								<Select
									onChange={(event) =>
										setScheduleAdjustmentAction(event.target.value)
									}
									selectClassName='w-100'
									value={onWeekendPriority.find(
										(item) => item.value == scheduleAdjustmentAction,
									)}
									name='type'
									options={onWeekendPriority}
									required={true}
								/>
							</div>
						)}

					{CurrentRecurrence?.end_on?.type === 'occurrence' ? (
						<div className='improved-input-designs pb-3'>
							<label className='w-100 require'>Number of Occurrences</label>
							<div
								className='d-flex flex-row align-items-center'
								style={{ gap: '5px' }}
							>
								<input
									className='w-50'
									type='number'
									name='occurrence'
									required={true}
									value={CurrentRecurrence?.end_on?.occurrence || null}
									onChange={endOnChangeHandler}
									min={1}
								/>
							</div>
						</div>
					) : (
						CurrentRecurrence?.end_on?.type === 'end_date' && (
							<div className='improved-input-designs pb-3 d-flex flex-column'>
								<label className='w-100 require'>Ends On</label>
								<KeyboardDatePicker
									autoOk
									disableToolbar
									value={CurrentRecurrence?.end_on?.end_date || null}
									name='end_date'
									onChange={onEndDateChange}
									format={date_format}
									minDate={CurrentRecurrence?.start_date || moment()}
									required
								/>
							</div>
						)
					)}

					<div className='improved-input-designs pb-3'>
						<label className='require'>Days to complete</label>
						<input
							data-cy='required_days'
							className='w-100'
							type='number'
							name='required_days'
							required={true}
							value={CurrentRecurrence?.required_days}
							onChange={changeHandler}
							min={1}
						/>
					</div>

					{CurrentRecurrence?.repeat_type && (
						<div className='improved-input-designs'>
							<label className='w-100'> </label>
							<h3 className='BodyTwoLight text-center w-100 Recurrence-text'>
								{/* For Daily Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.DAILY && (
									<>
										{selectedDays?.length < 1
											? 'Please select at least one day'
											: `This workflow will recur every ${selectedDays
													?.sort((a, b) => a.id - b.id)
													?.map(
														({ day }) => ` ${day.slice(0, 3)}`,
													)} starting from ${moment(
													CurrentRecurrence.start_date,
											  ).format('MMMM DD, YYYY')}`}
									</>
								)}

								{/* For Weekly Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.WEEKLY &&
									`This workflow will recur on ${moment(
										CurrentRecurrence?.start_date,
									).format('dddd')} of every ${
										CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''
									} week starting from ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD, YYYY')}`}

								{/* For Monthly Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.MONTHLY && (
									<>
										This workflow will recur on{' '}
										{CurrentRecurrence.recur_preference == 0 &&
											ordinal_suffix_of(
												moment(CurrentRecurrence.start_date).format('DD'),
											)}{' '}
										{CurrentRecurrence.recur_preference == 1 &&
											`${ordinal_suffix_of(
												weekOfMonth(moment(CurrentRecurrence?.start_date)),
											)} ${moment(CurrentRecurrence?.start_date).format(
												'dddd',
											)}`}{' '}
										{CurrentRecurrence.recur_preference == 2 &&
											`last ${moment(CurrentRecurrence?.start_date).format(
												'dddd',
											)}`}{' '}
										of every{' '}
										{CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''}{' '}
										month starting from{' '}
										{moment(CurrentRecurrence.start_date).format(
											'MMMM DD, YYYY',
										)}
									</>
								)}

								{/* For Yearly Recurrence */}
								{CurrentRecurrence.repeat_type === REPEAT_TYPE.YEARLY &&
									`This workflow will recur  on ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD')} of every ${
										CurrentRecurrence.repeat_interval != 1
											? ordinal_suffix_of(
													CurrentRecurrence.repeat_interval || 1,
											  )
											: ''
									}  year starting from ${moment(
										CurrentRecurrence.start_date,
									).format('MMMM DD, YYYY')}`}
							</h3>
						</div>
					)}
				</div>

				<div className='VerticalSpacer bg-white p-3 rounded-bottom d-flex justify-content-between'>
					<FormButtonOne onClick={onCancel} type='button' className='secondary-btn-light'>
						Cancel
					</FormButtonOne>
					<FormButtonOne loading={formControls.loading} type='submit'>
						Submit
					</FormButtonOne>
				</div>
			</form>

			{modalIsOpen && !draft && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Alert'} onClose={toggleModal}>
						<ConfirmModal
							description={
								'You’re about to change workflow recurrence. Changes of future tasks will override. Are you sure to want to continue?'
							}
							OnCancel={toggleModal}
							Onsubmit={validateMonthlyType}
							descriptionClassName='text-left'
							isLoading={formControls.loading}
							error={formControls.error}
						/>
					</DummyModal>
				</Modal>
			)}
			{/* {alertModal && !draft && (
				<Modal
					onClose={toggleAlertModal}
					open={alertModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<DummyModal title={'Alert'} onClose={toggleAlertModal}>
						<div>
							<div className='pb-3'>
								<span className='BodyTwoLight pt-1 pb-4'>
									You are setting the start date of this workflow before the end
									of the current workflow cycle. You can either keep your
									existing tasks or delete them. Deleting the current tasks will
									remove them along with any time tracked or comments. What do
									you want to do?
								</span>
							</div>
							<div className='d-flex justify-content-between'>
								<button
									id='Element'
									className='secondary'
									onClick={onKeepTasks}
								>
									Keep Tasks
								</button>
								<button
									className='d-flex align-items-center'
									id='Element'
									onClick={onDeleteTasks}
								>
									Delete Tasks
								</button>
							</div>
						</div>
					</DummyModal>
				</Modal>
			)} */}
		</>
	);
}

export default RecurrenceModal;
