/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/no-unescaped-entities */
import { useEffect, useMemo, useRef, useState } from 'react';
import FormButtonOne from '../components/Buttons/FormButtonOne';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { ReactComponent as TrashIcon } from '../assets/icons/Trash-Light.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/Infogrey.svg';
import { CircularProgress, Tooltip } from '@mui/material';
import { useLocation, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../redux/actions/dashboard/workspaces-actions';
import * as clientActions from '../redux/actions/client-actions';
import moment from 'moment';
import { DOCUMENT_INVITE_STATUSES } from '../utilities/utilities';
import ProgressBarModal from '../components/Modal/ProgressBarModal';
import UploadDocumentConfirmation from './UploadDocumentConfirmation';
import PreviewRichText from '../components/Inputs/PreviewRichText';

function UploadDocument() {
	const dispatch = useDispatch();

	const dropzoneRef = useRef(null);
	const inputRef = useRef(null);

	const { id } = useParams();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const tenantId = queryParams.get('tenant_id');
	const clientWorkflowName = queryParams.get('client_workflow_name');
	const [isProgressModal, setIsProgressModal] = useState(false);
	const [files, setFiles] = useState([]);
	const [loading, setLoading] = useState({
		tenantProfile: false,
		inviteRequest: false,
		upload: false,
	});
	const [tenantProfile, setTenantProfile] = useState(null);
	const [inviteRequest, setInviteRequest] = useState(null);
	const [isUploaded, setIsUploaded] = useState(false);
	const [isExpired, setIsExpired] = useState(false);
	const upload_status = useSelector((state) => state.clientReducer.upload_status);
	const now = moment();

	const handleDragOver = (e) => {
		if (loading.upload) return;
		e.preventDefault();
		e.stopPropagation();
		dropzoneRef.current.style.opacity = '0.5';
	};

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		dropzoneRef.current.style.opacity = '1';
	};

	const handleDrop = (e) => {
		if (loading.upload) return;
		e.preventDefault();
		e.stopPropagation();
		dropzoneRef.current.style.opacity = '1';
		handleFileSelect(e);
	};

	const handleAddFiles = () => {
		if (inputRef.current) {
			inputRef.current.value = '';
			inputRef.current.click();
		}
	};

	const handleFileSelect = (event) => {
		event.preventDefault();
		event.stopPropagation();
		const filesFromInput = event.target.files;
		const filesFromDrop = event.dataTransfer?.files;
		const uploadedFiles = filesFromDrop
			? Array.from(filesFromDrop)
			: Array.from(filesFromInput);
		setFiles((prevFiles) => [...prevFiles, ...uploadedFiles]);
	};

	const getFileExtension = (fileName) => {
		return fileName.split('.').pop().toLowerCase();
	};

	const handleRemoveFile = (index) => () => {
		setFiles((prevFiles) => prevFiles?.filter((_, ind) => ind !== index));
	};
	const uploadFiles = async () => {
		setLoading({ ...loading, upload: true });
		setIsProgressModal(true);
		const uploadPromises = [];

		files?.map((file) => {
			const formData = new FormData();
			formData?.append('file', file);
			const data = dispatch(Actions.uploadDocument(formData, id, file));
			uploadPromises.push(data);
		});

		Promise.allSettled(uploadPromises).finally(() => {
			setLoading({ ...loading, upload: false });
			setIsUploaded(true);
		});
	};

	useMemo(() => {
		setIsExpired(
			inviteRequest?.status === DOCUMENT_INVITE_STATUSES.EXPIRED ||
				inviteRequest?.status === DOCUMENT_INVITE_STATUSES.FULFILED ||
				now.isAfter(moment(inviteRequest?.expires_at)),
		);
	}, [inviteRequest]);

	const expireString = useMemo(() => {
		return `Link expires in ${moment(inviteRequest?.expires_at).diff(
			now,
			'days',
		)} days (${moment(inviteRequest?.expires_at).format('MM/DD/YYYY')})`;
	}, [inviteRequest]);

	useEffect(() => {
		setLoading({
			...loading,
			tenantProfile: true,
		});
		dispatch(Actions.getTenantProfile(tenantId))
			.then((response) => {
				setLoading({
					...loading,
					tenantProfile: false,
				});
				setTenantProfile(response);
			})
			.catch((error) => {
				dispatch(Actions.setSnackBarIsOpen(true, true, error.message));
				setLoading({
					...loading,
					tenantProfile: false,
				});
			});
	}, [tenantId]);

	useEffect(() => {
		setLoading({
			...loading,
			inviteRequest: true,
		});
		dispatch(Actions.getInviteData(id))
			.then((response) => {
				setLoading({
					...loading,
					inviteRequest: false,
				});
				setInviteRequest(response);
			})
			.catch(() => {
				setLoading({
					...loading,
					inviteRequest: false,
				});
			});
	}, [id]);

	return (
		<div
			style={{
				width: '100vw',
				height: '100vh',
				padding: '24px',
			}}
		>
			{loading.tenantProfile || loading.inviteRequest ? (
				<div className='w-100 h-100 d-flex align-items-center justify-content-center'>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '50px',
							width: '50px',
						}}
					/>
				</div>
			) : isUploaded ? (
				<UploadDocumentConfirmation
					logo_url={tenantProfile?.logo_url}
					title='Files Uploaded Successfully'
					content='The files you have uploaded are successfully delivered to the manager.'
					upload_status={upload_status}
				/>
			) : isExpired ? (
				<UploadDocumentConfirmation
					logo_url={tenantProfile?.logo_url}
					title='Link is Expired'
					content='The link you’re trying to access is expired. Please contact with your
						manager for further help.'
				/>
			) : (
				<div className='document-upload-container scroll'>
					<div className='mb-2'>
						{tenantProfile?.logo_url && (
							<img src={tenantProfile?.logo_url} width={100} />
						)}
					</div>
					<h2 className='HeadlineThreeBold'>Upload files for {clientWorkflowName}</h2>
					<div
						className='d-flex flex-column'
						style={{
							height: '70%',
							marginTop: '5px',
						}}
					>
						<div className='d-flex w-100 justify-content-center'>
							<div className='document-upload-message'>
								<PreviewRichText
									content={inviteRequest?.message}
									classNames={['BodyTwoLight', 'text-left']}
								/>
							</div>
						</div>
						<div className='uploaded-files-list'>
							<div
								className='d-flex flex-column'
								style={{
									gap: '5px',
								}}
							>
								{files.map((file, index) => (
									<div
										className='d-flex align-items-center justify-content-between'
										key={file.name}
									>
										<div
											className='d-flex align-items-center'
											style={{
												gap: '4px',
											}}
										>
											<span style={{ width: '24px' }}>
												<FileIcon
													extension={getFileExtension(file?.name)}
													{...defaultStyles[getFileExtension(file?.name)]}
													width='14px'
												/>
											</span>
											<span key={index}>{file.name}</span>
										</div>
										<TrashIcon
											className='pointer'
											onClick={handleRemoveFile(index)}
										/>
									</div>
								))}
							</div>
						</div>
						<div
							ref={dropzoneRef}
							onDragOver={handleDragOver}
							onDragLeave={handleDragLeave}
							onDrop={handleDrop}
							className='drop-zone-container w-100 d-flex align-items-center justify-content-center'
						>
							<div
								className='d-flex flex-column align-items-center'
								style={{ gap: '10px' }}
							>
								<FormButtonOne
									onClick={handleAddFiles}
									className='rounded-btn primary-btn'
									disabled={loading.upload}
								>
									Add Files
								</FormButtonOne>
								<span className='BodyTwoLight'>or drop files here</span>
								<div className='d-flex align-items-center' style={{ gap: '5px' }}>
									<span className='BodyTwoLight placeholder-text mt-1'>
										Maximum file size is 500 MB
									</span>
									<Tooltip
										title={
											<>
												Supported Files Format:
												<br />
												.pdf, .xls, .xlsx, .csv, .docx, .pptx, .jpeg, .png,
												.svg, .mp3, .mov
											</>
										}
										componentsProps={{
											tooltip: {
												sx: {
													backgroundColor: '#FED0A5',
													color: '#333333',
													fontSize: 14,
													maxWidth: '400px',
												},
											},
										}}
									>
										<InfoIcon />
									</Tooltip>
								</div>
								<input
									type='file'
									multiple
									onChange={handleFileSelect}
									ref={inputRef}
									className='d-none'
									disabled={loading.upload}
								/>
							</div>
						</div>
					</div>
					<span className='w-100 d-block mt-3 text-left BodyThreeLight light-text'>
						{expireString || ''}
					</span>
					<div className='d-flex justify-content-end' style={{ marginTop: '38px' }}>
						<FormButtonOne
							onClick={uploadFiles}
							loading={loading.upload}
							disabled={loading.upload || files?.length === 0}
							className='rounded-btn primary-btn'
						>
							Confirm Upload
						</FormButtonOne>
					</div>
				</div>
			)}
			{isProgressModal && (
				<ProgressBarModal
					modalIsOpen={isProgressModal}
					files={files}
					onClose={() => {
						setIsProgressModal(false);
						dispatch(clientActions.resetUploadState());
					}}
				/>
			)}
		</div>
	);
}

export default UploadDocument;
