import { useEffect, useMemo, useState } from 'react';
import Sidebar from '../components/sidebar/SideBar';
import NewBarGraph from '../components/CompanyProfile/NewBarGraph';
import { getTimeRange, TIME_RANGE_OPTIONS } from '../utilities/TimeRangeUtils';
import DropDown from '../components/Inputs/DropDown';
import NewDateRangePicker from '../components/Inputs/NewDateRangePicker';
import AnalyticsDetails from '../components/CompanyProfile/AnalyticsDetails';
import { useDispatch, useSelector } from 'react-redux';
import * as cubeActions from '../redux/actions/cube-actions';
import * as clientActions from '../redux/actions/client-actions';
import * as teamActions from '../redux/actions/team-management.actions';
import * as profileActions from '../redux/actions/company-profile';
import IndustryPicker from '../components/sidebar/IndustryPicker';
import BusinessPicker from '../components/sidebar/BusinessPicker';
import { business_types2, industries2 } from '../components/DemoData';
import { generateGraphsQuery } from '../utilities/cubeUtils';
import getSymbolFromCurrency from 'currency-symbol-map';
import useBreadCrumb from '../hooks/useBreadCrumb';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box } from '@mui/material';
import { lightGreen } from '../components/Style.jsx';
import AnalyticsTable from '../components/CompanyProfile/AnalyticsTable.jsx';
import AnalyticsSlidingPanel from '../components/Modal/AnalyticsSlidingPanel.jsx';
import MultipleDropDown from '../components/Inputs/MultipleDropDown.jsx';
import moment from 'moment';
import KPIComponent from '../components/sub_components/KPIComponent.jsx';
import { convertGraphDataToSums } from '../utilities/utilities.js';

const ProfitabilityAnalytics = () => {
	const dispatch = useDispatch();
	const { BreadCrumbs } = useBreadCrumb();

	const teams = useSelector((state) => state.teamReducer.teams);
	const clients = useSelector((state) => state.clientReducer.clients);
	const { graphData, tableData } = useSelector((state) => state.cubeReducer.data);
	const user = useSelector((state) => state.authReducer.user);
	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
    let filteredIndustry = useSelector(
		(state) => state.clientReducer.filteredClientManagement?.industry,
	);
	let filteredBusinessType = useSelector(
		(state) => state.clientReducer.filteredClientManagement?.business_type,
	);
	const currencySymbol = companyProfileData?.currency
		? getSymbolFromCurrency(companyProfileData?.currency)
		: '';
	const [multiSelectOpen, setMultiSelectOpen] = useState(false);
	const [selectedUser, setSelectedUser] = useState(null);
	const [isPanelVisible, setIsPanelVisible] = useState(false);
	const [dateRange, setDateRange] = useState(TIME_RANGE_OPTIONS[2].value);
	const [dateRanges, setDateRanges] = useState(null);
	const [selectedRow, setSelectedRow] = useState(null);
	const [loading, setLoading] = useState(false);
	const [selectedValues, setSelectedValues] = useState({
		teams: [],
		clients: [],
		businesses: [],
		industries: [],
	});

	const result = graphData && convertGraphDataToSums(graphData);

	useEffect(() => {
		const fetchGraphData = async () => {
			if (
				selectedValues?.teams.length > 0 ||
				selectedValues?.clients?.length > 0 ||
				selectedValues?.businesses.length > 0 ||
				selectedValues?.industries.length > 0 ||
				(dateRanges?.from && dateRanges?.to)
			)
				try {
					setLoading(true);

					if (dateRanges?.from && dateRanges?.to) {
						dateRanges.from = moment.utc(dateRanges.from);
						dateRanges.to = moment.utc(dateRanges.to);
					}

					const query = generateGraphsQuery(selectedValues, dateRanges);
					query.filters = query.filters.filter(
						(filter) => !(filter.values.includes('null') || filter.values.includes('')),
					);

					await dispatch(cubeActions.getProfitabilityGraph(query, selectedUser?.id));
				} catch (error) {
					console.error('Error fetching graph data:', error);
				} finally {
					setLoading(false);
				}
		};

		const debounceFetch = setTimeout(() => {
			fetchGraphData();
		}, 500);

		return () => clearTimeout(debounceFetch);
	}, [dispatch, selectedValues, dateRanges, selectedUser?.id]);


    if (filteredIndustry) {
		filteredIndustry = industries2
			.filter((obj) => filteredIndustry.find((item) => item.industry == obj.value))
			?.map((obj) => {
				return {
					...obj,
					is_checked: selectedValues?.industries?.includes(obj.value),
				};
			});
	}

	if (filteredBusinessType) {
		filteredBusinessType = business_types2
			.filter((obj) => filteredBusinessType.find((item) => item.business_type == obj.value))
			?.map((obj) => {
				return {
					...obj,
					is_checked: selectedValues?.businesses?.includes(obj.value),
				};
			});
	}
	const handleViewClick = (data) => {
		setSelectedUser(data);
		setIsPanelVisible(true);

	};

	const handleClosePanel = () => {
		setIsPanelVisible(false);
	};

	const handleIndustryCheckbox = (e) => {
		const { value, checked } = e.target;
		setSelectedValues((prevValues) => {
			const updatedIndustries = checked
				? [...(prevValues.industries || []), value]
				: (prevValues.industries || [])?.filter((industry) => industry !== value);
			return { ...prevValues, industries: updatedIndustries };
		});
	};

	const handleBusinessCheckbox = (e) => {
		const { value, checked } = e.target;
		setSelectedValues((prevValues) => {
			const updatedBusinesses = checked
				? [...(prevValues.businesses || []), value]
				: (prevValues.businesses || [])?.filter((business) => business !== value);
			return { ...prevValues, businesses: updatedBusinesses };
		});
	};

	const handleOnClear = (type) => {
		setSelectedValues((prevValues) => {
			if (type === 'industry') {
				return { ...prevValues, industries: [] };
			}
			if (type === 'business') {
				return { ...prevValues, businesses: [] };
			}
			return prevValues;
		});
	};

	const onTimeChange = (event) => {
		setDateRange(event.target.value);
	};

	const formattedTeams = useMemo(
		() =>
			teams?.map((team) => ({
				label: team.name,
				value: team.id,
				is_checked: selectedValues?.teams?.includes(team.id),
			})),
		[teams, selectedValues.teams],
	);

	const formattedClients = useMemo(
		() =>
			clients?.map((client) => ({
				label: client?.dba || client?.company_name,
				value: client.id,
				is_checked: selectedValues?.clients?.includes(client.id),
			})),
		[clients, selectedValues.clients],
	);

	const handleMultiSelectChange = (type, selectedItems) => {
		setSelectedValues((prevValues) => ({
			...prevValues,
			[type]: selectedItems
				.filter((item) => item.is_checked)
				.map(({ value }) => value.toString()),
		}));
	};

    const handleExportCSV = (data) => {
        console.log(data,'data1234');
        if (!data || data.length === 0) {
          alert('No data available to export');
          return;
        }
    
        const headers = Object.keys(data[0]);
        const csvRows = [
          headers.join(','), 
          ...data.map(row => 
            headers.map(field => JSON.stringify(row[field] || '')).join(',')
          ) 
        ];
    
        const csvContent = csvRows.join('\n');
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
    
        link.href = url;
        link.setAttribute('download', 'export.csv');
        link.click();
    
        URL.revokeObjectURL(url); 
      };

	useEffect(() => {
		const selectedDateRanges = getTimeRange(dateRange);
		if (selectedDateRanges) setDateRanges(selectedDateRanges);
	}, [dateRange]);

	useEffect(() => {
		dispatch(teamActions.getTeams());
		dispatch(clientActions.getClients());
		dispatch(profileActions.getTenantProfile(user?.tenantId));
        dispatch(clientActions.getFilteredClientManagement());
	}, [dispatch]);

	return (
		<div className='AppBody'>
			<Sidebar title='Analytics'>
				{!selectedRow && (
					<div className='SidebarContentWrapper'>
						<div className='User-filters'>
							<div className='improved-input-designs text-left w-100'>
								<label className='text-white'>Date Range</label>
								<DropDown
									normalDropDown
									style={{
										height: '30px',
										borderRadius: '4px',
										minWidth: '200px',
									}}
									onChange={onTimeChange}
									options={TIME_RANGE_OPTIONS}
									value={dateRange}
									disableIcon
									disableNull
								/>
							</div>
							{dateRange === TIME_RANGE_OPTIONS[0].value && (
								<div className='d-flex w-100' style={{ gap: '24px' }}>
									<NewDateRangePicker
										labelClass='text-white'
										label='Select Date Ranges'
										dateRanges={dateRanges}
										applyDates={(dates) => setDateRanges(dates)}
									/>
								</div>
							)}
							<div className='improved-input-designs' style={{ width: '100%' }}>
								<label className='text-left text-white'>Team</label>
								<MultipleDropDown
									toggleSelect={() =>
										setMultiSelectOpen((prev) => ({
											...prev,
											team: !prev.team,
										}))
									}
									isOpen={multiSelectOpen.team}
									options={formattedTeams}
									changeHandler={(teams) =>
										handleMultiSelectChange('teams', teams)
									}
									displayStyle={{
										display: 'flex',
										alignItems: 'start',
										padding: 0,
									}}
									selectStyle={{ padding: '4px' }}
									required
								/>
							</div>
							<div className='improved-input-designs' style={{ width: '100%' }}>
								<label className='text-left text-white'>Client</label>
								<MultipleDropDown
									label={'Clients'}
									toggleSelect={() =>
										setMultiSelectOpen((prev) => ({
											...prev,
											client: !prev.client,
										}))
									}
									displayStyle={{ display: 'flex', alignItems: 'start' }}
									selectStyle={{ padding: '4px' }}
									isOpen={multiSelectOpen.client}
									options={formattedClients}
									changeHandler={(clients) =>
										handleMultiSelectChange('clients', clients)
									}
									required
								/>
							</div>
							<div>
								<div className='SidebarListWrapper'>
									<IndustryPicker
										onClear={() => handleOnClear('industry')}
										options={filteredIndustry}
										onChange={handleIndustryCheckbox}
									/>
								</div>
							</div>
							<div>
								<div className='SidebarListWrapper'>
									<BusinessPicker
										onClear={() => handleOnClear('business')}
										options={filteredBusinessType}
										onChange={handleBusinessCheckbox}
									/>
								</div>
							</div>
						</div>
					</div>
				)}
			</Sidebar>
			<div className='content-container'>
				{/* <AlertBar /> */}
				<div
					className='workflow-browser w-100 d-flex flex-column'
					style={{
						paddingTop: '35px',
						backgroundColor: '#EEF2F3',
						padding: '24px 32px',
					}}
				>
					{selectedRow && (
						<Box
							className=''
							sx={{
								display: 'flex',
								flexDirection: 'row',
								alignItems: 'center',
								':hover': {
									cursor: 'pointer',
								},
								color: `${lightGreen}`,
							}}
						>
							<ArrowLeftIcon />
							<BreadCrumbs>
								<h2
									className='HeadlineThreeBook text-left'
									onClick={() => setSelectedRow(null)}
								>
									Back
								</h2>
							</BreadCrumbs>
						</Box>
					)}
					<div className='workflow-browser-header' style={{ marginBottom: '32px' }}>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<span className='HeadlineTwoBold pl-2 mt-1'>Analytics</span>
						</div>
					</div>
					<div className='pb-5'>
						<KPIComponent symbol={currencySymbol} row={result} />
					</div>

					<div className='' style={{ padding: '18px', backgroundColor: '#FFFFFF' }}>
						{/* {selectedRow ? (
							<AnalyticsDetails row={selectedRow} symbol={currencySymbol} />
						) : ( */}
							<div className='d-flex flex-column' style={{ gap: '24px' }}>
								<div className='d-flex'>
									<span className='HeadlineThreeBold'>Profitability</span>
								</div>
								<NewBarGraph
									data={graphData}
									currencySymbol={currencySymbol}
									loading={loading}
								/>

								<div
									className='d-flex w-100 justify-content-between'
									style={{
										backgroundColor: '#EEF2F3',
										borderRadius: '4px',
										padding: '12px',
									}}
								>
									<span className='HeadlineThreeBold'>Profitability Details</span>

                                    <button className='rounded-button-1' onClick={() => handleExportCSV(tableData)}>Export CSV</button>
								</div>

                                {console.log(tableData,'tabledata123')}

								<AnalyticsTable
									data={tableData}
									loading={loading}
									onViewClick={handleViewClick}
                                    currencySymbol={currencySymbol}
								/>
							</div>
						{/* )} */}
					</div>
				</div>
			</div>
			<AnalyticsSlidingPanel
				isVisible={isPanelVisible}
				data={selectedUser}
				onClose={handleClosePanel}
                loading={loading}
                currencySymbol={currencySymbol}
			/>
		</div>
	);
};

export default ProfitabilityAnalytics;
