import { memo, useEffect, useMemo, useState } from 'react';
import { ReactComponent as TaskIcon } from '../../assets/icons/ClipboardText.svg';
import { Avatar, Modal, Tooltip } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Trash-Light.svg';
import { ReactComponent as HoursIcon } from '../../assets/icons/ApprovalClock.svg';
import { ReactComponent as DependencyIcon } from '../../assets/icons/Stack.svg';
import { ReactComponent as ApprovalIcon } from '../../assets/icons/CheckCircle.svg';
import { ReactComponent as WarningIcon } from '../../assets/icons/WarningCircle.svg';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIndicator.svg';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import {
	convertDecimalToTime,
	executivePermissions,
	getAvatarLabel,
	Member,
	standardPermissions,
} from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import useModal from '../Modal/useModal';
import WorkflowTaskModal from './Modals/WorkflowTaskModal';
import DummyModal from '../Modal/DummyModal';
import ConfirmModal from '../Modal/ConfirmModal';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import { useDispatch, useSelector } from 'react-redux';
import TaskDescriptionModal from './Modals/TaskDescriptionModal';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import PreviewRichText from '../Inputs/PreviewRichText';

function ClientWorkflowTask({
	task,
	user_team_role,
	dependent_task_name,
	assignee_user,
	dependency_order,
	disableActions = false,
	expandTasks = true,
	index,
	handleDragStart,
	handleDragEnd,
	handleDragOver,
	draggedTask,
	onExpandChange,
	client_workflow_id,
	client_id,
}) {
	const dispatch = useDispatch();
	const [deleteLoading, setDeleteLoading] = useState(false);
	const [expandTask, setExpandTask] = useState(true);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const { title, description, budgeted_hours, is_approval_required, dependent_task_id, id } =
		task;

	const isDraggable = standardPermissions?.includes(user_team_role);

	const toggleExpand = () => {
		setExpandTask(!expandTask);
		onExpandChange(index, !expandTask);
	};
	const onDragOver = (event) => {
		handleDragOver(event, task);
	};

	const onDragStart = (event) => {
		handleDragStart(event, task);
	};

	const onEdit = () => {
		toggleModal();
		ModalType('CLIENT_WORKFLOW_TASK');
	};

	const handleDeleteTask = () => {
		toggleModal();
		ModalType('DELETE_TASK_ALERT');
	};

	const handleDeleteConfirm = () => {
		setDeleteLoading(true);
		dispatch(builderActions.deleteClientWorkflowTask(id))
			.then(() => {
				toggleModal();
				setDeleteLoading(false);
				dispatch(builderActions.getClientWorkflowTasks(client_id, client_workflow_id))
					.then((response) => {
						dispatch(builderActions.setClientWorkflowTasks(response));
					})
					.catch((error) => {
						dispatch(setSnackBarIsOpen(false, true, error.message));
					});
			})
			.catch(() => {
				setDeleteLoading(false);
				toggleModal();
			});
	};

	useEffect(() => {
		setExpandTask(expandTasks);
	}, [expandTasks]);
	return (
		<div
			className='client-workflow-task'
			style={{
				cursor: isDraggable ? 'grab' : '',
				border: draggedTask?.id === id ? '1px solid #FB7A03' : 'none',
			}}
			draggable={isDraggable}
			onDragStart={onDragStart}
			onDragOver={onDragOver}
			onDragEnd={handleDragEnd}
		>
			<div
				className='w-100 text-left d-flex flex-column align-items-center justify-content-between'
				style={{ gap: '16px' }}
			>
				<div
					className='d-flex align-items-center w-100 justify-content-between'
					style={{ gap: '24px' }}
				>
					<span className='d-flex align-items-center' style={{ gap: '24px' }}>
						<span className='d-flex align-items-center' style={{ gap: '12px' }}>
							{!expandTask && <DragIcon />}
							<TaskIcon />
							<h3 className='HeadlineThreeBold'>{title}</h3>
							<span className='BodyOneLight ml-2'>{dependency_order}</span>
						</span>

						<span className='d-flex align-items-center' style={{ gap: '8px' }}>
							{assignee_user && (
								<Avatar
									sx={{
										bgcolor: `#FEF2E7`,
										color: `#333`,
										marginRight: '10px',
										height: '23px',
										width: '23px',
										fontSize: '0.8rem',
									}}
									alt={assignee_user?.full_name}
									src={assignee_user?.image_url ? assignee_user?.image_url : ''}
								>
									{getAvatarLabel(assignee_user?.full_name)}
								</Avatar>
							)}

							{assignee_user?.full_name ? (
								<p className='BodyTwoLight p-0 m-0 light-text pt-1'>
									{assignee_user?.full_name}
								</p>
							) : (
								<div className='pending-task-assingee'>
									<WarningIcon style={{ stroke: '#fb7a03' }} />
									<p className='BodyTwoLight p-0 m-0 primary-text pt-1'>
										Unassigned
									</p>
								</div>
							)}
						</span>
					</span>
					<div
						className='d-flex justify-content-between align-items-center'
						style={{ gap: '12px' }}
					>
						{!disableActions && (
							<>
								<Restricted
									AllowedUsers={standardPermissions}
									userTeamRole={[user_team_role]}
								>
									<span
										className='d-flex justify-content-between align-items-center'
										style={{ gap: '12px' }}
									>
										<Tooltip title='Delete Task'>
											<DeleteIcon
												className='pointer'
												onClick={handleDeleteTask}
											/>
										</Tooltip>
										<Tooltip title='Edit Task'>
											<EditIcon className='pointer' onClick={onEdit} />
										</Tooltip>
									</span>
								</Restricted>
							</>
						)}
						<span className='pointer' onClick={toggleExpand}>
							{expandTask ? (
								<KeyboardArrowUpIcon sx={{ color: '#FB7A03' }} />
							) : (
								<KeyboardArrowDownIcon sx={{ color: '#FB7A03' }} />
							)}
						</span>
					</div>
				</div>
				{expandTask && (
					<div className='d-flex flex-column w-100' style={{ gap: '16px' }}>
						<div className='task-sub-details d-flex'>
							<span
								className='d-flex align-items-center justify-content-center'
								style={{ gap: '8px' }}
							>
								<HoursIcon />
								<span className='BodyTwoBold pt-1'>Budgeted Hours:</span>
								<span className='BodyTwoLight pt-1'>
									{convertDecimalToTime(budgeted_hours)}
								</span>
							</span>
							<span
								className='d-flex align-items-center justify-content-center'
								style={{ gap: '8px' }}
							>
								<DependencyIcon />
								<span className='BodyTwoBold pt-1'>Dependency:</span>
								<span className='BodyTwoLight pt-1'>
									{dependent_task_id ? dependent_task_name : 'None'}
								</span>
							</span>

							{is_approval_required && (
								<span
									className='d-flex align-items-center justify-content-center'
									style={{ gap: '8px' }}
								>
									<ApprovalIcon />
									<span className='BodyTwoBold pt-1'>Requires Approval</span>
								</span>
							)}
						</div>

						<div
							className='client-workflow-task-details'
							style={{ minHeight: '100px', maxHeight: '190px', overflow: 'auto' }}
						>
							{description?.length < 1 ||
							description == null ||
							description?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
								<p className='BodyTwoLight placeholder-text m-0'>
									No Instructions available
								</p>
							) : (
								<PreviewRichText
									content={task?.description}
									classNames={['BodyTwoLight', 'm-0', 'text-break']}
								/>
							)}
						</div>
					</div>
				)}

				{modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							{ModalName == 'CLIENT_WORKFLOW_TASK' && (
								<DummyModal
									style={{ width: '900px' }}
									hideCrossIcon
									title='Task Details'
									onClose={toggleModal}
								>
									<WorkflowTaskModal task={task} onCancel={toggleModal} />
								</DummyModal>
							)}
							{ModalName == 'DELETE_TASK_ALERT' && (
								<DummyModal title={'Alert'} onClose={toggleModal}>
									<ConfirmModal
										description={'Are you sure you want to remove this task?'}
										OnCancel={toggleModal}
										Onsubmit={handleDeleteConfirm}
										descriptionClassName='text-left'
										isLoading={deleteLoading}
									/>
								</DummyModal>
							)}
							{ModalName == 'TASK_MEETING_DESCRIPTION' && (
								<DummyModal title='Agenda' onClose={toggleModal} height='400px'>
									<TaskDescriptionModal
										type={'text'}
										value={description}
										Edit={false}
										OnCancel={toggleModal}
										richTextStyle={{ height: '250px' }}
									/>
								</DummyModal>
							)}
						</>
					</Modal>
				)}
			</div>
		</div>
	);
}

export default memo(ClientWorkflowTask);
