// eslint-disable-next-line no-unused-vars
import React, { useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../redux/actions/client-actions';
import useModal from '../Modal/useModal';
import { CircularProgress, Modal } from '@mui/material';
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import * as teamActions from '../../redux/actions/team-management.actions';
import * as userActions from '../../redux/actions/user-management.actions';
import * as profileActions from '../../redux/actions/company-profile.js';
import { useParams } from 'react-router-dom';
import SharedAccess from './SharedAccess';
import WorkflowModal from './Modals/WorkflowModal';
import DummyModal from '../Modal/DummyModal';
import GenerateInvoice from './Modals/GenerateInvoice';
import WorkflowMyTasks from './WorkflowMyTasks';
import ClientBillingDetail from './ClientBillingDetail';
import MeetingMyTasks from './MeetingMyTasks';
import ClientProfileBar from './ClientProfileBar';
import ClientDetail from './ClientDetail';
import ClientWorkflow from './ClientWorkflow';
import DocumentManagement from './DocumentManagement.jsx';

function Profile({ activeTab }) {
	const dispatch = useDispatch();
	const { id } = useParams();

	const client = useSelector((state) => state.clientReducer.client);
	const user = useSelector((state) => state.authReducer.user);

	const clientWorkflowLoading = useSelector(
		(state) => state.WorkflowBuilder.setSelectedWorkflowLoading,
	);
	const clientDetailsLoading = useSelector(
		(state) => state.clientReducer.clientSectionLoading,
	);

	useEffect(() => {
		client?.team_client_permissions[0]?.team_id &&
			dispatch(
				teamActions.getTeamMembers(client?.team_client_permissions[0]?.team_id),
			);
	}, [dispatch, client?.team_client_permissions[0]?.team_id]);

	useEffect(() => {
		dispatch(WorkSpaceActions.getAssignees());
		dispatch(teamActions.getTeams());
		dispatch(clientActions.getClient(id));
		dispatch(clientActions.getClients());
		dispatch(userActions.getUsers());
	}, [id]);

    useEffect(() => {
		dispatch(profileActions.getTenantProfile(user?.tenantId));
	}, [user?.tenantId]);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const handleClose = () => {
		dispatch(builderActions.getAllWorkflows(id));
		dispatch(builderActions.getAllWorkflowTasks(id));
		dispatch(builderActions.resetSelectedWorkflows());
		toggleModal();
	};

	return (
		<>
			<div
				style={{
					height: '100%',
					boxSizing: 'border-box',
					display: 'flex',
					flexDirection: 'column',
				}}
			>
				<ClientProfileBar
					clientId={client?.id}
					teamName={client?.team_client_permissions[0]?.team?.name}
					clientName={
						client?.dba?.trim() == '' ? client?.company_name : client?.dba
					}
				/>

				<div
					style={{
						flex: 1,
						boxSizing: 'border-box',
					}}
				>
					{activeTab == 'PROFILE' && <ClientDetail />}

					{activeTab == 'ENGAGEMENT_OVERVIEW' && <ClientBillingDetail />}

                    {activeTab == 'RESOURCES' && <SharedAccess />}

                    {activeTab == 'FOLDERS_AND_FILES' && <DocumentManagement client={client} sharedAccess={true} />}

					{activeTab == 'MEETING_TASKS' && <MeetingMyTasks />}

					{activeTab == 'AD_HOC_TASKS' && <WorkflowMyTasks />}

					{Number.isInteger(activeTab) && <ClientWorkflow />}
				</div>
			</div>

			{(clientDetailsLoading || clientWorkflowLoading) && (
				<div
					style={{
						height: '100%',
						width: 'inherit',
						backgroundColor: 'rgba(0, 0, 0, 0.05)',
						position: 'fixed',
						top: 0,
						bottom: 0,
					}}
				>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '50px',
							width: '50px',
							marginTop: '47vh',
						}}
					/>
				</div>
			)}

			{modalIsOpen && (
				<>
					{ModalName == 'GENERATE_INVOICE' && (
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<DummyModal
								title={'Generate Invoice'}
								onClose={() => {
									toggleModal();
								}}
							>
								<GenerateInvoice
									onCancel={() => {
										toggleModal();
									}}
								/>
							</DummyModal>
						</Modal>
					)}

					{ModalName == 'WORKFLOW_BROWSER' && (
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<WorkflowModal onClose={handleClose} />
						</Modal>
					)}
				</>
			)}
		</>
	);
}
export default memo(Profile);
