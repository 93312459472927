export const graphVariations = Object.freeze({
	WORKFLOW_VS_PERCENTAGE: 'workflow-vs-percentage',
	WORKFLOW_VS_TIME_TRACKING_GRAPH: 'workflow-vs-time-tracking',
	WORKFLOW_CUMULATIVE_GRAPH: 'completed-workflow-cumulative-graph',
	PROFITABILITY_BY_PERCENTAGE_GRAPH: 'profitability-by-percentage-graph',
});

export const graphTypes = Object.freeze({
	// defaultGraph: 'worlflow-total-vs-completed-workflow-percent',
	groupByNone: 'group-by-none',
	groupByClients: 'group-by-client',
	groupByTeams: 'group-by-teams',
	groupByEmployees: 'group-by-employees',
	// groupByWorkflows: 'group-by-workflows',
});

export const PREFIX = Object.freeze({ NEW_GRAPH_PROFITABILITY: 'newGraphProfitability' });

export const generateGraphsQuery = (selectedValues, dateRanges) => {
	const prefix = PREFIX.NEW_GRAPH_PROFITABILITY;
	const filters = [];

	if (selectedValues?.teams.length > 0) {
		filters.push({
			member: `${prefix}.team_id`,
			operator: 'equals',
			values: selectedValues.teams,
		});
	}

	if (selectedValues?.clients.length > 0) {
		filters.push({
			member: `${prefix}.client_id`,
			operator: 'equals',
			values: selectedValues.clients,
		});
	}

	if (selectedValues?.businesses.length > 0) {
		filters.push({
			member: `${prefix}.client_business_type`,
			operator: 'contains',
			values: selectedValues.businesses,
		});
	}

	if (selectedValues?.industries.length > 0) {
		filters.push({
			member: `${prefix}.client_industry`,
			operator: 'contains',
			values: selectedValues.industries,
		});
	}

	return {
		timeDimensions: [
            {
                'dimension': `${prefix}.logged_date`,
                'dateRange': [
                    dateRanges.from,
                    dateRanges.to
                ]
              }
        ],
		filters,
		dimensions: [
			`${prefix}.client_id`,
			`${prefix}.client_name`,
			`${prefix}.revenue`,
			`${prefix}.user_name`,
			`${prefix}.total_billable_hours`,
			`${prefix}.tenant_id`,
			`${prefix}.client_dba`,
			`${prefix}.billing_start_date`,
			`${prefix}.billing_end_date`,
			`${prefix}.line_items`,
			`${prefix}.invoice_id`,
			`${prefix}.user_id`,
			`${prefix}.task_id`,
			`${prefix}.logged_date`,
			`${prefix}.workflow_id`,
			`${prefix}.workflow_name`,
			`${prefix}.team_id`,
			`${prefix}.team_name`,
			`${prefix}.total_user_cost`,
			`${prefix}.total_non_billable_hours`,
			`${prefix}.client_business_type`,
			`${prefix}.client_industry`,
			`${prefix}.ub_rate`,
			`${prefix}.ub_start_date`,
			`${prefix}.ub_end_date`,
            `${prefix}.task_type`,
            `${prefix}.my_task_workflow_id`,
            `${prefix}.total_discount`,
		],
	};
};

function generateTooltipQuery(cubeName, dimensions, measures) {
	if (cubeName === 'Graphdatawithtimetracking') {
		this.measures = measures;
		this.dimensions = dimensions;
		this.timeDimensions = [
			{
				dimension: `${cubeName}.taskdate`,
				dateRange: 'This year',
			},
		];
		this.filters = [];
	} else {
		this.dimensions = dimensions;
		this.timeDimensions = [
			{
				dimension: `${cubeName}.taskdate`,
				dateRange: 'This year',
			},
		];
		this.filters = [];
	}
}
function tooltipQueries(cubeName, measures) {
	// this[graphTypes.simpleGraph]= new generateTooltipQuery(cubeName,
	// 	[`${cubeName}.tasktitle`, `${cubeName}.clientcompany`,`${cubeName}.clientid`,`${cubeName}.workflowname`]);
	if (cubeName === 'Newgraphdatauserbilling') {
		this[graphTypes.groupByClients] = new generateTooltipQuery(
			cubeName,
			[
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.taskdate`,
				`${cubeName}.payment_type`,
				`${cubeName}.payment_mode`,
				`${cubeName}.rate`,
				`${cubeName}.bill_start_date`,
				`${cubeName}.bill_end_date`,
				`${cubeName}.client_billing_id`,
				`${cubeName}.user_billing_id`,
				`${cubeName}.user_bill_start_date`,
				`${cubeName}.user_bill_end_date`,
				`${cubeName}.user_cost_per_hour`,
				`${cubeName}.comsumedtime`,
			],
			measures,
		);
	} else if (cubeName === 'Graphdatawithtimetracking') {
		this[graphTypes.groupByTeams] = new generateTooltipQuery(
			cubeName,
			[
				`${cubeName}.teamname`,
				`${cubeName}.workflowname`,
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.teamID`,
				`${cubeName}.workflowID`,
			],
			measures,
		);
		this[graphTypes.groupByEmployees] = new generateTooltipQuery(
			cubeName,
			[`${cubeName}.username`, `${cubeName}.userID`],
			measures,
		);
	} else if (cubeName === 'Graphdataclientworklow') {
		this[graphTypes.groupByClients] = new generateTooltipQuery(
			cubeName,
			[
				`${cubeName}.task_count`,
				`${cubeName}.completedTaskCount`,
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.workflowname`,
				`${cubeName}.teamname`,
				`${cubeName}.teamID`,
				`${cubeName}.taskdate`,
			],
			measures,
		);
		this[graphTypes.groupByTeams] = new generateTooltipQuery(
			cubeName,
			[
				`${cubeName}.task_count`,
				`${cubeName}.completedTaskCount`,
				`${cubeName}.workflowname`,
				`${cubeName}.teamname`,
				`${cubeName}.teamID`,
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.taskdate`,
			],
			measures,
		);
	} else {
		//
	}

	// this[graphTypes.groupByWorkflows]= new generateTooltipQuery(cubeName,
	// 	[`${cubeName}.tasktitle`, `${cubeName}.workflowname`, `${cubeName}.clientcompany`,`${cubeName}.clientid`]);
}

function generateGraphQuery(cubeName, dimension, measures) {
	if (cubeName === 'Graphdatawithtimetracking') {
		this.measures = [...measures];
		this.dimensions = [...dimension];
		this.timeDimensions = [
			{
				dimension: `${cubeName}.taskdate`,
				dateRange: 'This year',
			},
		];
	} else {
		this.dimensions = [...dimension];
		this.timeDimensions = [
			{
				dimension: `${cubeName}.taskdate`,
				dateRange: 'This year',
			},
		];
	}
}

function graphQueries(cubeName, measures) {
	// this[graphTypes.defaultGraph]=new generateGraphQuery(cubeName,[`${cubeName}.workflowname`]);
	if (cubeName === 'Newgraphdatauserbilling') {
		this[graphTypes.groupByClients] = new generateGraphQuery(
			cubeName,
			[
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.taskdate`,
				`${cubeName}.payment_type`,
				`${cubeName}.payment_mode`,
				`${cubeName}.rate`,
				`${cubeName}.bill_start_date`,
				`${cubeName}.bill_end_date`,
				`${cubeName}.client_billing_id`,
				`${cubeName}.user_billing_id`,
				`${cubeName}.user_bill_start_date`,
				`${cubeName}.user_bill_end_date`,
				`${cubeName}.user_cost_per_hour`,
				`${cubeName}.comsumedtime`,
			],
			measures,
		);
	} else if (cubeName === 'Graphdatawithtimetracking') {
		this[graphTypes.groupByTeams] = new generateGraphQuery(
			cubeName,
			[`${cubeName}.teamname`],
			measures,
		);
		this[graphTypes.groupByEmployees] = new generateGraphQuery(
			cubeName,
			[`${cubeName}.username`],
			measures,
		);
	} else {
		this[graphTypes.groupByClients] = new generateGraphQuery(
			cubeName,
			[
				`${cubeName}.workflowID`,
				`${cubeName}.totalWorkflows`,
				`${cubeName}.workflowname`,
				`${cubeName}.completedWorkflows`,
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.taskdate`,
			],
			measures,
		);
		this[graphTypes.groupByTeams] = new generateGraphQuery(
			cubeName,
			[
				`${cubeName}.teamname`,
				`${cubeName}.workflowID`,
				`${cubeName}.totalWorkflows`,
				`${cubeName}.workflowname`,
				`${cubeName}.completedWorkflows`,
				`${cubeName}.clientcompany`,
				`${cubeName}.clientid`,
				`${cubeName}.teamID`,
				`${cubeName}.taskdate`,
			],
			measures,
		);
	}

	// this[graphTypes.groupByWorkflows]=new generateGraphQuery(cubeName,[`${cubeName}.workflowname`]);
}

function filterQueries(cubeName) {
	if (cubeName === 'Newgraphdatauserbilling') {
		this.clientid = {
			dimensions: [`${cubeName}.clientcompany`, `${cubeName}.clientid`],
		};
		this.teamID = {
			dimensions: [`${cubeName}.teamID`, `${cubeName}.teamname`],
		};
		// this.userID = {
		// 	dimensions: [`${cubeName}.userID`, `${cubeName}.username`],
		// };
	} else if (cubeName === 'Graphdataclientworklow') {
		this.clientid = {
			dimensions: [`${cubeName}.clientcompany`, `${cubeName}.clientid`],
		};
		this.teamID = {
			dimensions: [`${cubeName}.teamID`, `${cubeName}.teamname`],
		};
		this.workflowID = {
			dimensions: [`${cubeName}.workflowname`, `${cubeName}.workflowID`],
		};
	} else {
		this.clientid = {
			dimensions: [`${cubeName}.clientcompany`, `${cubeName}.clientid`],
		};
		this.teamID = {
			dimensions: [`${cubeName}.teamID`, `${cubeName}.teamname`],
		};
		this.userID = {
			dimensions: [`${cubeName}.userID`, `${cubeName}.username`],
		};
		this.workflowID = {
			dimensions: [`${cubeName}.workflowname`, `${cubeName}.workflowID`],
		};
	}
}
export const workflowVsTimeTrackingQueries = Object.freeze({
	tooltipQueries: new tooltipQueries('Graphdatawithtimetracking', [
		`Graphdatawithtimetracking.totalEstimatedHours`,
		'Graphdatawithtimetracking.totalConsumeHours',
	]),
	graphQueries: new graphQueries('Graphdatawithtimetracking', [
		'Graphdatawithtimetracking.totalEstimatedHours',
		'Graphdatawithtimetracking.totalConsumeHours',
	]),
	filtersDataQueries: new filterQueries('Graphdatawithtimetracking'),
});
export const workflowVsPercentQueries = Object.freeze({
	tooltipQueries: new tooltipQueries('Graphdataclientworklow', []),
	graphQueries: new graphQueries('Graphdataclientworklow', []),
	filtersDataQueries: new filterQueries('Graphdataclientworklow'),
});

export const workflowCumulativeQueries = Object.freeze({
	tooltipQueries: new tooltipQueries('Newgraphdatauserbilling', []),
	graphQueries: new graphQueries('Newgraphdatauserbilling', []),
	filtersDataQueries: new filterQueries('Newgraphdatauserbilling'),
});

export const profitabilityByPercentageQueries = Object.freeze({
	tooltipQueries: new tooltipQueries('Newgraphdatauserbilling', []),
	graphQueries: new graphQueries('Newgraphdatauserbilling', []),
	filtersDataQueries: new filterQueries('Newgraphdatauserbilling'),
});

export const varRelations = Object.freeze({
	clientid: {
		resultedVar: 'clientcompany',
		dataStoredVar: 'clients',
	},
	teamID: {
		resultedVar: 'teamname',
		dataStoredVar: 'teams',
	},
	userID: {
		resultedVar: 'username',
		dataStoredVar: 'employees',
	},
	workflowID: {
		resultedVar: 'workflowname',
		dataStoredVar: 'workflows',
	},
	countVariable: {
		[graphVariations.WORKFLOW_VS_PERCENTAGE]: 'count',
		[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH]: 'totalEstimatedHours',
		[graphVariations.WORKFLOW_CUMULATIVE_GRAPH]: 'totalCost',
		[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH]: 'totalCost',
	},
	[graphTypes.groupByNone]: 'workflowname',
	[graphTypes.groupByClients]: 'clientcompany',
	[graphTypes.groupByTeams]: 'teamname',
	[graphTypes.groupByEmployees]: 'username',
	[graphTypes.groupByWorkflows]: 'workflowname',
	[graphVariations.WORKFLOW_VS_PERCENTAGE]: 'Graphdataclientworklow',
	[graphVariations.WORKFLOW_VS_TIME_TRACKING_GRAPH]: 'Graphdatawithtimetracking',
	[graphVariations.WORKFLOW_CUMULATIVE_GRAPH]: 'Newgraphdatauserbilling',
	[graphVariations.PROFITABILITY_BY_PERCENTAGE_GRAPH]: 'Newgraphdatauserbilling',
});

export const getQueryForFilters = (data) => {
	const filters = data?.filters?.map((item) => {
		const key = Object.keys(item);
		return {
			member: `${data.mainVariable}.${key}`,
			operator: 'equals',
			values: item[key],
		};
	});
	const query = { ...data.query?.filtersDataQueries[data.filterFor], filters };
	return query;
};

// export const getDays = (date) => {
// 	const dateFormat=new Date(date).toLocaleDateString().split('/');
// 	return dateFormat[0]+'/'+dateFormat[1];
// };
// export const getMonthName = (date) => {
// 	return new Date(date).toLocaleString('default', { month: 'short' });
// };
// export const getYears = (date) => {
// 	return new Date(date).getFullYear();
// };
