import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import PreviewRichText from './PreviewRichText';

export default function EditableTextArea({
	id,
	richText,
	label,
	name,
	value,
	Class,
	onChange,
	Edit,
	style,
	type,
	pattern,
	title,
	required,
	column,
	rows,
	placeholder,
	rootClass,
	textClass,
	labelClass,
}) {
	EditableTextArea.modules = {
		toolbar: [
			['bold', 'italic', 'underline', 'strike'],
			[{ color: [] }, { background: [] }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			[
				{ list: 'ordered' },
				{ list: 'bullet' },
				// { indent: "-1" },
				// { indent: "+1" },
			],
			['link'],
		],
		clipboard: {
			// toggle to add extra line breaks when pasting HTML:
			matchVisual: false,
		},
	};
	if (richText) {
		return (
			<div className='ProfilePage d-flex flex-column w-100'>
				<label className='text-left m-0'>{label}</label>

				{Edit ? (
					<ReactQuill
						theme='snow'
						modules={EditableTextArea.modules}
						className={Class}
						style={style}
						type={type}
						value={value}
						onChange={onChange}
						pattern={pattern}
						title={title}
						required={required}
						rows={rows ? rows : 4}
						cols={1}
						placeholder={placeholder}
					/>
				) : (
					<PreviewRichText content={value} classNames={['BodyTwoLight', 'text-left']} />
				)}
			</div>
		);
	} else
		return (
			<div className={`ProfilePage d-flex flex-column w-100 ${rootClass}`}>
				<label className={`text-left m-0 ${labelClass}`}>{label}</label>

				{Edit ? (
					<textarea
						className={Class}
						name={name}
						style={style}
						type={type}
						value={value}
						onChange={onChange}
						pattern={pattern}
						title={title}
						required={required}
						rows={rows ? rows : 4}
						cols={1}
						placeholder={placeholder}
					/>
				) : (
					<h3 className={`BodyTwoLight text-left ${textClass}`} name={name} style={style}>
						{value}
					</h3>
				)}
			</div>
		);
}
