import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import Sidebar from '../sidebar/SideBar';
import * as userActions from '../../redux/actions/user-management.actions';
import ProfileBarV2 from '../TimeReportsV2/ProfileBarV2';
import TimeReportsUserInfoV2 from '../TimeReportsV2/TimeReportsUserInfoV2';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import TimeReportsDateV2 from '../TimeReportsV2/TimeReportsDateV2';

function TimeReportsUserDetailsV2() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const start_date = searchParams.get('start_date');
	const end_date = searchParams.get('end_date');
    const monthlyView = searchParams.get('monthly_view') === 'true';
	const dailyView = searchParams.get('daily_view') === 'true';

	const [activeTab, setActiveTab] = useState(0);
	const userData = useSelector((state) => state.TimeReportsV2.userDetails);
	const timeReportsLoading = useSelector((state) => state.TimeReportsV2.timeReportsLoading);
	const initialLoading = useSelector((state) => state.TimeReportsV2.initialLoading);

	const ChangeActiveTab = (e) => {
		setActiveTab(e.target.id);
	};

	const userBillingList = useSelector((state) => state.userReducer.userBillingList);
	const billingMap = new Map(
		userBillingList?.map((user) => [
			user.user_id,
			dailyView
				? user.available_hours / 7
				: monthlyView
				? user.available_hours * 4.3
				: user.available_hours,
		]),
	);

	const clientList = userData?.clients
		?.filter(
			(client) =>
				client?.client_workflows?.length > 0 ||
				client?.workflow_my_tasks !== null ||
				client?.meetings !== null,
		)
		?.map((client) => {
			return {
				name: client?.client_name,
				sectionId: client?.client_name,
			};
		});

	let navList = [{ name: 'Meetings and My Tasks', sectionId: 'MEETINGS' }];
	if (clientList?.length > 0) {
		navList = [...navList, ...clientList];
	}

	useEffect(() => {
		dispatch(userActions.getUsersBilling());
		dispatch(TimeReportsActions.getUserRole(Number(id)));
	}, [dispatch]);

	useEffect(() => {
		!timeReportsLoading &&
			start_date &&
			end_date &&
			dispatch(
				TimeReportsActions.getUserDetails(Number(id), start_date, end_date, initialLoading),
			);
		!timeReportsLoading && dispatch(TimeReportsActions.setInitialLoading(true));
	}, [dispatch, start_date, end_date, timeReportsLoading]);

	return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div className='side-menu m-0 d-flex flex-column align-items-start'>
							{navList?.map((navItem, i) => {
								return (
									<span
										key={i}
										className={
											activeTab == i
												? 'active d-flex flex-row align-items-center text-left'
												: 'side-menu-link d-flex flex-row align-items-center text-left'
										}
										style={{
											gap: '10px',
											paddingBlock: '10px',
										}}
									>
										<div className='dot'></div>
										<a
											onClick={ChangeActiveTab}
											href={`#${navItem.sectionId}`}
											id={i}
											className='BodyTwoLight'
										>
											{navItem.name}
										</a>
									</span>
								);
							})}
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='timeReports-main time-reports-main'>
				<div className='time-reports-content' style={{ gap: '0' }}>
					<ProfileBarV2
						barText={userData.full_name}
						availbleHours={billingMap.get(userData.user_id)}
						capacity={billingMap.get(userData.user_id) - userData?.user_budgeted_hours}
						budgeted_hours={userData?.user_budgeted_hours}
						userId={userData.user_id}
					/>
					<div className='time-reports-body'>
						<TimeReportsDateV2 />
						<div
							className='d-flex flex-column'
							style={{
								gap: '3em',
							}}
						>
							<TimeReportsUserInfoV2 userData={userData} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default TimeReportsUserDetailsV2;
