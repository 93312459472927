/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';
import SkeletonLoading from '../Others/SkeletonLoading';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth3, TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import EditableText from '../Inputs/EditableText';
import { ReactComponent as Edit } from '../../assets/icons/PencilSimpleColor.svg';
import { ReactComponent as Check } from '../../assets/icons/Check.svg';
import { ReactComponent as Cross } from '../../assets/icons/closeColor.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { convertDecimalToTime, convertToDecimal } from '../../utilities/utilities';
import useEditTimeFormat from '../../hooks/useEditTimeFormat';
import moment from 'moment';
import { Tooltip } from '@mui/material';
import { getUserSettings } from '../../hooks/getUserSettings';
import useTimeZone from '../../hooks/useTimeZone';
import { useLocation } from 'react-router';

function ExpandableTimeReportsTasksViewV2({ tasks, isAllowed, userId, clientInfo }) {
	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const dailyView = searchParams.get('daily_view') === 'true';

	const [edit, setEdit] = useState(false);
	const [taskToEdit, setTaskToEdit] = useState(null);

	const logged_date = searchParams.get('start_date');

	const tasksLoading = useSelector((state) => state.TimeReportsV2.tasksLoading);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const timeReportsLoading = useSelector((state) => state.TimeReportsV2.timeReportsLoading);
    
	const { date_format } = getUserSettings(userDetails);
	const force_update = true;
	const { inputValue, setInputValue, handleInputChange } = useEditTimeFormat(
		taskToEdit?.consumed_time,
	);

	const handleDone = (task) => {
		const { formattedTimeZone } = useTimeZone();
		!timeReportsLoading && setEdit(!edit);
		dispatch(TimeReportsActions.setInitialLoading(false));
		dispatch(TimeReportsActions.setTimeReportsLoading(true));
		dispatch(
			Actions.handleTaskTimer(
				{
					task_id: task?.id,
					user_id: Number(userId),
					consumed_time: JSON.stringify(Math.floor(task?.consumed_time * 3600000)),
					logged_date: logged_date,
					timezone: formattedTimeZone,
				},
				force_update,
				undefined,
			),
		)
			.then(() => {
				dispatch(TimeReportsActions.setTimeReportsLoading(false));
			})
			.catch(() => {
				dispatch(TimeReportsActions.setTimeReportsLoading(false));
			});
	};

	const handleCancel = (task) => {
		setInputValue(convertDecimalToTime(taskToEdit?.consumed_time));
		dispatch(TimeReportsActions.handleLoggedTime(taskToEdit?.consumed_time, task.id));
	};

	const handleInputBlur = (task_id) => {
		let value = 0;
		if (inputValue === '') {
			value = 0;
		} else if (inputValue.includes(':')) {
			value = convertToDecimal(inputValue);
		} else if (!isNaN(inputValue)) {
			value = parseFloat(inputValue);
		}

		const formattedValue = convertDecimalToTime(value);
		setInputValue(formattedValue);
		dispatch(TimeReportsActions.handleLoggedTime(value, task_id));
	};

	const EditProfile = (task) => {
		setTaskToEdit(task);
		setEdit(!edit);
	};

	useEffect(() => {
		dispatch(Actions.resetTaskTimer());
	}, [dispatch, taskToEdit]);
    
	return (
		<div
			className='team-members-body'
			style={{
				backgroundColor: '#f7f9fa',
				borderBottom: '2px solid white',
				padding: '16px 12px',
			}}
		>
			{tasks?.length < 1 ? (
				<SkeletonLoading loading={tasksLoading} variant='text' width='100%' height={45}>
					<span className='BodyTwoLight placeholder-text text-center'>No Tasks</span>
				</SkeletonLoading>
			) : (
				<>
					<SkeletonLoading loading={tasksLoading} variant='text' width='100%' height={45}>
						<TableHeader ClassName='new-table-header' border={false}>
							<TableHeaderItem
								labelClass='centraBodyOne'
								label='Task Name'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='centraBodyOne'
								label='Budgeted Hours'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='centraBodyOne'
								label='Logged Time'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='centraBodyOne'
								label={clientInfo ? 'Capacity' : 'Utilization'}
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='centraBodyOne'
								label=''
								width={TableItemWidth3}
							/>
						</TableHeader>
					</SkeletonLoading>
					<div className='ListingLoading scroll'>
						{tasks?.map((task, index) => {
							const userBudgetedHours = !task.budgeted_hours
								? 0
								: task.budgeted_hours;
							const userLoggedHours = !task.consumed_time ? 0 : task.consumed_time;
							const userUtlizationPercentage = (
								(userLoggedHours / userBudgetedHours) *
								100
							)
								.toFixed(2)
								.replace(/\.?0*$/, '');

							const userCapacity = convertDecimalToTime(
								userBudgetedHours - userLoggedHours,
							);

							return (
								<SkeletonLoading
									loading={
										tasksLoading ||
										(timeReportsLoading && taskToEdit?.id == task.id)
									}
									variant='text'
									width='100%'
									height={45}
									key={index}
								>
									<div
										className='d-flex align-items-center justify-content-between'
										style={{
											backgroundColor: '#FCFDFD',
											padding: '12px 16px 8px 16px',
											borderBottom: '2px solid white',
										}}
										key={task.id}
									>
										<TableRowItem
											ClassName='text-left'
											textClass='centra-body2-light'
											label={task.title}
											width={TableItemWidth5}
											input={true}
										>
											<div className='d-flex flex-column'>
												<h3 className={`BodyTwoLight dark-text m-0`}>
													{task.title}
												</h3>
												<span className='BodyFourLight light-grey-text'>
													{task.extended_date === null
														? '(' +
														  moment(
																task?.start_date?.replace(
																	/T.*$/,
																	'',
																),
														  ).format(date_format) +
														  ')'
														: '(' +
														  moment(
																task?.extended_date?.replace(
																	/T.*$/,
																	'',
																),
														  ).format(date_format) +
														  ')'}
												</span>
											</div>
										</TableRowItem>

										<TableRowItem
											ClassName='text-left'
											textClass='centra-body2-light'
											label={
												!task.budgeted_hours
													? convertDecimalToTime(0)
													: convertDecimalToTime(task.budgeted_hours)
											}
											width={TableItemWidth5}
											input={false}
										/>
										<TableRowItem
											ClassName='text-left'
											textClass='centra-body2-light'
											label={
												task.consumed_time == null
													? convertDecimalToTime(0)
													: convertDecimalToTime(task?.consumed_time)
											}
											width={TableItemWidth5}
											input={true}
										>
											{edit && taskToEdit?.id == task.id ? (
												<Tooltip
													title='Entered value will be converted to hh:mm format'
													placement='bottom-start'
												>
													<div
														className='d-flex align-items-center justify-content-between'
														style={{ gap: '10px' }}
													>
														<EditableText
															onChange={handleInputChange}
															name='logged-time'
															value={inputValue}
															style={{ backgroundColor: '#F7F9FA' }}
															Edit={edit && taskToEdit?.id == task.id}
															onBlur={() => handleInputBlur(task.id)}
															type='text'
														/>
														<div
															className='d-flex'
															style={{ gap: '10px' }}
														>
															<span
																className='pointer centra-body2-light primary-text w-100 text-right'
																onClick={() => {
																	setEdit(!edit);
																	handleDone(task);
																}}
															>
																<Check />
															</span>
															<span
																className='pointer centra-body2-light primary-text w-100 text-right'
																onClick={() => {
																	setEdit(!edit);
																	handleCancel(task);
																}}
															>
																<Cross />
															</span>
														</div>
													</div>
												</Tooltip>
											) : (
												<div className='d-flex align-items-center'>
													<h3
														className={`BodyTwoLight dark-text m-0 w-100`}
													>
														{convertDecimalToTime(task?.consumed_time)}
													</h3>
													{dailyView && isAllowed && (
														<span
															className='pointer w-100 mb-2'
															onClick={() => EditProfile(task)}
														>
															<Edit />
														</span>
													)}
												</div>
											)}
										</TableRowItem>

										<TableRowItem
											ClassName='text-left'
											textClass={`centra-body2-light ${
												clientInfo
													? userCapacity < 0
														? 'red-text'
														: 0
													: userUtlizationPercentage < 0
													? 'red-text'
													: ''
											}`}
											label={
												clientInfo
													? userCapacity
													: `${userUtlizationPercentage} %`
											}
											width={TableItemWidth5}
											input={false}
											toolTipText='Budgeted Hours - Logged Hours'
										/>
										<TableRowItem
											className='text-right'
											label=''
											width={TableItemWidth3}
											input={true}
										/>
									</div>
								</SkeletonLoading>
							);
						})}
					</div>
				</>
			)}
		</div>
	);
}

export default ExpandableTimeReportsTasksViewV2;
