/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ExpandableView from '../ExpandableView';
import { Avatar } from '@mui/material';
import { MEETING_ACCEPT_STATUS, getAvatarLabel } from '../../../utilities/utilities';
import SkeletonLoading from '../../Others/SkeletonLoading';
import { ReactComponent as ArrowsOut } from '../../../assets/icons/ArrowsOutSimple.svg';
import { ReactComponent as Edit } from '../../../assets/icons/PencilLight.svg';
import { ReactComponent as AcceptInvite } from '../../../assets/icons/CheckCircleinvite.svg';
import { ReactComponent as DeclineInvite } from '../../../assets/icons/XCircleInvite.svg';
import * as userActions from '../../../redux/actions/user-management.actions';
import PreviewRichText from '../../Inputs/PreviewRichText';

const MeetingGuestsTab = ({ setExpandView }) => {
	const dispatch = useDispatch();
	const taskDetails = useSelector((state) => state.workSpaceReducer.newTaskDetails);
	let allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	// const loading = useSelector((state) => state.workSpaceReducer.loading);
	const user = useSelector((state) => state.authReducer.user);

	if (allTenantUsers?.length > 1) {
		allTenantUsers = allTenantUsers.map((u) => {
			return {
				id: u.id,
				full_name: u.full_name,
			};
		});
	}

	useEffect(() => {
		dispatch(userActions.getAllTenantUsers());
	}, [dispatch]);

	return (
		<div className='d-flex flex-column h-100' style={{ gap: '1rem' }}>
			<div className='meeting-guest-container d-flex flex-column'>
				<ExpandableView
					labelClass='HeadlineThreeBook text-left dark-text'
					subLabelClass='BodyTwoLight text-left dark-text'
					label='Guests'
					subLabel={`${
						(taskDetails?.assignee_users?.filter((assignee) =>
							allTenantUsers?.map((user) => user.id)?.includes(assignee?.user_id),
						)?.length || 0) +
						(taskDetails?.metadata?.outside_attendee_emails_with_status?.length || 0)
					} members`}
				>
					<div
						className='d-flex flex-column'
						style={{
							maxHeight: '86px',
							overflow: 'auto',
							gap: '8px',
							paddingTop: '8px',
						}}
					>
						{taskDetails?.assignee_users?.filter((assignee) =>
							allTenantUsers
								?.map((assignee) => assignee.id)
								?.includes(assignee?.user_id),
						)?.length < 1 &&
						taskDetails?.metadata?.outside_attendee_emails_with_status?.length < 1 ? (
							<span className='text-center BodyThreeLight'>No Guests Invited</span>
						) : (
							taskDetails?.assignee_users
								?.sort((a, b) =>
									a?.user_profile?.full_name.localeCompare(b?.full_name),
								)
								?.map((assignee) => {
									const currentMember = allTenantUsers?.find(
										(item) => item.id == assignee.user_id,
									);
									return (
										currentMember && (
											<div
												className='w-100 d-flex flex-row justify-content-start align-items-center'
												style={{ gap: '6px' }}
												key={user}
											>
												<div className='position-relative'>
													<Avatar
														sx={{
															bgcolor: `#FEF2E7`,
															color: `#333`,
															marginRight: '10px',
															height: '23px',
															width: '23px',
															fontSize: '0.8rem',
														}}
														alt={currentMember?.full_name}
														src={currentMember?.image_url}
													>
														{getAvatarLabel(currentMember?.full_name)}
													</Avatar>
													<div
														className='position-absolute'
														style={{ top: 5, right: 5 }}
													>
														{assignee?.status ==
														MEETING_ACCEPT_STATUS.ACCEPTED ? (
															<AcceptInvite
																width='16px'
																height='16px'
															/>
														) : assignee?.status ==
														  MEETING_ACCEPT_STATUS.REJECTED ? (
															<DeclineInvite
																width='16px'
																height='16px'
															/>
														) : (
															''
														)}
													</div>
												</div>

												<span className='BodyTwoLight pt-1 dark-text text-left'>
													{currentMember?.full_name}
												</span>
											</div>
										)
									);
								})
						)}
						{taskDetails?.metadata?.outside_attendee_emails_with_status?.length &&
							taskDetails.metadata.outside_attendee_emails_with_status.map(
								(email) => {
									const label = email?.email?.charAt(0);
									return (
										<div
											className='w-100 d-flex flex-row justify-content-start align-items-center'
											style={{ gap: '6px' }}
											key={user}
										>
											<div className='position-relative'>
												<Avatar
													sx={{
														bgcolor: `#FEF2E7`,
														color: `#333`,
														marginRight: '10px',
														height: '23px',
														width: '23px',
														fontSize: '0.8rem',
													}}
													alt={label}
												>
													{getAvatarLabel(label?.toUpperCase())}
												</Avatar>
												<div
													className='position-absolute'
													style={{ top: 5, right: 5 }}
												>
													{email?.status ==
													MEETING_ACCEPT_STATUS.ACCEPTED ? (
														<AcceptInvite width='16px' height='16px' />
													) : email?.status ==
													  MEETING_ACCEPT_STATUS.REJECTED ? (
														<DeclineInvite width='16px' height='16px' />
													) : (
														''
													)}
												</div>
											</div>

											<span className='BodyTwoLight pt-1 dark-text text-left'>
												{email?.email}
											</span>
										</div>
									);
								},
							)}
					</div>
				</ExpandableView>
			</div>
			{
				<SkeletonLoading
					loading={taskDetails ? false : true}
					variant='text'
					width={150}
					height={30}
				>
					<div className='meeting-task-notes-container'>
						<div className='h-100 position-relative'>
							<div className='d-flex justify-content-between align-items-center'>
								<label className='HeadlineThreeBook text-left dark-text'>
									Notes
								</label>
								<div className='d-flex' style={{ gap: '14px' }}>
									{taskDetails?.my_task?.creator_id == user.userId && (
										<span
											className='pointer'
											onClick={() =>
												setExpandView({
													isExpanded: true,
													content: taskDetails?.notes,
													title: 'Notes',
													isEdit: true,
												})
											}
										>
											<Edit />
										</span>
									)}
									<span
										className='pointer'
										onClick={() => {
											setExpandView({
												isExpanded: true,
												content: taskDetails?.notes,
												title: 'Notes',
												isEdit: false,
											});
										}}
									>
										<ArrowsOut />
									</span>
								</div>
							</div>
							<div
								className='position-absolute'
								style={{
									maxHeight: 'calc(100% - 28px)',
									overflow: 'auto',
									width: '100%',
									top: 40,
								}}
							>
                                <PreviewRichText content={taskDetails?.notes} classNames={['BodyTwoLight']}/>
							</div>
						</div>
					</div>
				</SkeletonLoading>
			}
		</div>
	);
};

export default MeetingGuestsTab;
