import { memo, useState } from 'react';
import TableRowItem from '../Others/TableRowItem';
import { TableItemWidth2, TableItemWidth3 } from '../TableSpacing/TableSpacingStyles';
import { useDispatch } from 'react-redux';
import useModal from '../Modal/useModal';
import { formatPhoneNumber, standardPermissions, truncateString } from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import TableRowButton from '../Others/TableRowButton';
import { Modal, Tooltip } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash-Light.svg';
import ConfirmModal from '../Modal/ConfirmModal';
import DummyModal from '../Modal/DummyModal';
import NotesModal from './Modals/NotesModal';
import AddContact from './AddContact';
import * as clientActions from '../../redux/actions/client-actions';
import PreviewRichText from '../Inputs/PreviewRichText';

function ClientContactRow({ clientContact, userTeamRole }) {
	const dispatch = useDispatch();

	const [deleteControls, setDeleteControls] = useState({
		error: '',
		loading: false,
	});

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const viewContactNotes = () => {
		ModalType('VIEW_NOTES_MODAL');
		toggleModal();
	};

	const onDelete = () => {
		setDeleteControls({
			error: '',
			loading: true,
		});

		dispatch(clientActions.deleteClientContact(clientContact.id))
			.then(() => {
				setDeleteControls({
					error: '',
					loading: false,
				});
				toggleModal();
			})
			.catch((error) => {
				setDeleteControls({
					error: error?.message,
					loading: false,
				});
			});
	};

	let address = '';
	if (clientContact?.address[0]) {
		address = [
			clientContact?.address[0]?.street,
			clientContact?.address[0]?.suite,
			clientContact?.address[0]?.city,
			clientContact?.address[0]?.state,
			clientContact?.address[0]?.zip,
		]
			.filter(Boolean)
			.join(', ');
	}

	let secondAddress = '';
	if (clientContact?.address[1]) {
		secondAddress = [
			clientContact?.address[1]?.street,
			clientContact?.address[1]?.suite,
			clientContact?.address[1]?.city,
			clientContact?.address[1]?.state,
			clientContact?.address[1]?.zip,
		]
			.filter(Boolean)
			.join(', ');
	}

	return (
		<div className='TableRow d-flex align-items-center h-auto'>
			<TableRowItem
				ClassName='text-left align-self-start'
				label={clientContact.first_name + ' ' + clientContact.last_name}
				width={TableItemWidth3}
			/>

			<TableRowItem
				ClassName=' text-left align-self-start'
				label={clientContact.title}
				width={TableItemWidth3}
			/>

			<TableRowItem
				ClassName=' text-left align-self-start'
				label={
					clientContact?.contact?.email?.length > 0
						? clientContact?.contact?.email[0]
						: ''
				}
				width={TableItemWidth3}
			/>

			<TableRowItem
				ClassName=' text-left align-self-start'
				label={
					clientContact?.contact?.direct?.length > 0
						? clientContact?.contact?.direct[0]
						: ''
				}
				width={TableItemWidth3}
			/>

			<TableRowItem
				ClassName=' text-left align-self-start'
				width={TableItemWidth3}
				label={
					clientContact?.contact?.general?.length > 0
						? clientContact?.contact?.general[0]
						: ''
				}
			/>

			<TableRowItem
				ClassName='text-left align-self-start'
				width={TableItemWidth3}
				input={true}
			>
				<div className='d-flex flex-column'>
					{clientContact?.address[0] && (
						<span className='BodyTwoLight mb-0'>{address}</span>
					)}

					{clientContact?.address[1] && (
						<span className='BodyTwoLight mb-0'>{secondAddress}</span>
					)}
				</div>
			</TableRowItem>

			<TableRowItem
				ClassName=' text-left align-self-start'
				width={TableItemWidth3}
				input={true}
			>
				<div className='d-flex flex-column'>
					{clientContact?.contact?.general?.length > 1 &&
						clientContact?.contact?.general[1] && (
							<div className='BodyTwoLight mb-0 dark-text'>
								<span className='BodyTwoLight mb-0 light-text pr-1'>
									Sec. Mobile:{' '}
								</span>
								{clientContact?.contact?.general[1]}
							</div>
						)}
					{clientContact?.contact?.direct?.length > 1 &&
						clientContact?.contact?.direct[1] && (
							<div className='BodyTwoLight mb-0 dark-text'>
								<span className='BodyTwoLight mb-0 light-text pr-1'>
									Sec. Phone:{' '}
								</span>
								{clientContact?.contact?.direct[1]}
							</div>
						)}
					{clientContact?.contact?.email?.length > 1 &&
						clientContact?.contact?.email[1] && (
							<div className='BodyTwoLight mb-0 dark-text'>
								<span className='BodyTwoLight mb-0 light-text pr-1'>
									Sec. Email:{' '}
								</span>
								{clientContact?.contact?.email[1]}
							</div>
						)}
				</div>
			</TableRowItem>

			<TableRowItem
				ClassName=' text-left align-self-start'
				label={
					<div>
						<p className='BodyTwoLight dark-text m-0 text-break'>
							{clientContact?.notes?.length < 1 ||
							clientContact?.notes == null ||
							clientContact?.notes?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
								'-'
							) : (
								<PreviewRichText
									content={truncateString(clientContact?.notes)}
									classNames={['BodyTwoLight', 'dark-text', 'm-0', 'text-break']}
								/>
							)}
						</p>
						{clientContact?.notes?.length > 28 && (
							<span
								onClick={() => {
									viewContactNotes(clientContact?.notes);
								}}
								className='primary-text BodyThreeLight'
							>
								Read More
							</span>
						)}
					</div>
				}
				width={TableItemWidth3}
			/>

			<Restricted AllowedUsers={standardPermissions} userTeamRole={[userTeamRole]}>
				<TableRowButton ClassName='d-flex text-left mr-0' width={TableItemWidth2}>
					<div
						className='d-flex flex-row align-items-center'
						style={{
							gap: '7px',
						}}
					>
						<Tooltip title='Edit Contact'>
							<EditIcon
								fontSize='small'
								style={{
									color: '#fb7a03',
								}}
								onClick={() => {
									ModalType('EDIT_CONTACT_MODAL');
									toggleModal();
								}}
							/>
						</Tooltip>
						<Tooltip title='Remove Contact'>
							<TrashIcon
								data-cy='delete-client-contact'
								fontSize='small'
								sx={{
									color: '#fb7a03',
								}}
								onClick={() => {
									ModalType('CONFIRM_MODAL');
									toggleModal();
								}}
								className='pointer'
							/>
						</Tooltip>
					</div>
				</TableRowButton>
			</Restricted>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'CONFIRM_MODAL' && (
							<DummyModal title={'Delete Contact'} onClose={toggleModal}>
								<ConfirmModal
									description={
										'You’re about to delete this contact. Are you sure to want to continue?'
									}
									OnCancel={toggleModal}
									Onsubmit={onDelete}
									descriptionClassName='text-left'
									isLoading={deleteControls?.loading}
									error={deleteControls?.error}
								/>
							</DummyModal>
						)}
						{ModalName == 'EDIT_CONTACT_MODAL' && (
							<DummyModal title='Edit Client Contact' onClose={toggleModal}>
								<AddContact onCancel={toggleModal} clientContact={clientContact} />
							</DummyModal>
						)}
						{ModalName == 'VIEW_NOTES_MODAL' && (
							<DummyModal title='Notes' onClose={toggleModal}>
								<NotesModal
									edit={false}
									onCancel={toggleModal}
									initialNotes={clientContact?.notes}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(ClientContactRow);
