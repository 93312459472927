import { memo, useEffect, useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import { useDispatch, useSelector } from 'react-redux';
import ClientWorkflowModal from './Modals/ClientWorkflowModal';
import { ClickAwayListener, Modal, Tooltip } from '@mui/material';
import ConfirmModal from '../Modal/ConfirmModal';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import { useHistory, useParams } from 'react-router';
import Restricted from '../Permissions/Restricted';
import { standardPermissions } from '../../utilities/utilities';
import * as clientActions from '../../redux/actions/client-actions';
import { SketchPicker } from 'react-color';
import FormButtonOne from '../Buttons/FormButtonOne';
import ExportClientWorkflow from './ExportClientWorkflow';

function WorkflowBar({
	display_name,
	draft,
	client_workflow_id,
	enableAction,
	onActivate,
	user_team_role,
	workflowName,
	color,
}) {
	const dispatch = useDispatch();
	const history = useHistory();

	const { id } = useParams();
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [formControls, setFormControls] = useState({
		error: '',
		loading: false,
	});

	const onEdit = () => {
		ModalType('CHANGE_WORKFLOW_NAME');
		toggleModal();
	};
	const onDelete = () => {
		ModalType('DELETE_WORKFLOW_ALERT');
		toggleModal();
	};
	const deactivateWorkflow = () => {
		ModalType('DEACTIVATE_WORKFLOW_ALERT');
		toggleModal();
	};
	const deleteDraftWorkflow = () => {
		setFormControls({
			error: '',
			loading: true,
		});

		dispatch(builderActions.deleteDraftWorkflow(client_workflow_id))
			.then((data) => {
				toggleModal();
				setFormControls({
					error: '',
					loading: false,
				});
				dispatch(clientActions.setClientProfileActiveTab('PROFILE'));
				history.push(`/client-profile/${id}`);
			})
			.catch((error) => {
				setFormControls({
					error: error.message,
					loading: false,
				});
			});
	};

	const onDeactivate = () => {
		setFormControls({
			error: '',
			loading: true,
		});

		dispatch(builderActions.deactivatetWorkflow(client_workflow_id))
			.then((data) => {
				toggleModal();
				setFormControls({
					error: '',
					loading: false,
				});
				dispatch(clientActions.setClientProfileActiveTab('PROFILE'));
				history.push(`/client-profile/${id}`);
			})
			.catch((error) => {
				setFormControls({
					error: error.message,
					loading: false,
				});
			});
	};

	return (
		<div
			style={{
				padding: '12px 18px',
				background: 'white',
				position: 'sticky',
				top: '0',
				zIndex: '100',
			}}
			className='text-left w-100 d-flex align-items-center justify-content-between'
		>
			<span className='d-flex' style={{ gap: '12px' }}>
				<h3 className='HeadlineThreeBold'>{display_name}</h3>
				<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
					<>
						<p className='p-0 m-0 pt-1 BodyOneLight light-text'>
							{draft ? 'draft' : ''}
						</p>

						<Tooltip title='Edit'>
							<EditIcon className='pointer mt-1' onClick={onEdit} />
						</Tooltip>
					</>
				</Restricted>
			</span>
			<div className='d-flex align-items-center' style={{ gap: 20 }}>
				{!draft && (
					<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
						<div className='d-flex' style={{ gap: '12px' }}>
							<button
								title={'Deactivate'}
								className='rounded-button'
								onClick={deactivateWorkflow}
							>
								<p className='BodyTwoBold m-0 p-0 pt-1'>Deactivate</p>
							</button>
						</div>
					</Restricted>
				)}
				{draft && (
					<div className='d-flex' style={{ gap: '12px' }}>
						<button className='rounded-button' onClick={onDelete}>
							<p className='BodyTwoBold m-0 p-0 pt-1'>Delete</p>
						</button>
						<Tooltip
							title={enableAction ? 'Set recurrence first' : 'Activate workflow'}
						>
							<button
								disabled={enableAction}
								className='rounded-button'
								onClick={onActivate}
							>
								<p className='BodyTwoBold m-0 p-0 pt-1'>Activate</p>
							</button>
						</Tooltip>
					</div>
				)}
				<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
					<WorkflowColorPicker color={color || '#fe7a03'} />
				</Restricted>
				<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
					<ExportClientWorkflow />
				</Restricted>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'CHANGE_WORKFLOW_NAME' && (
							<DummyModal title='Workflow Name' onClose={toggleModal}>
								<ClientWorkflowModal
									clientWorkflowName={display_name}
									closeModal={toggleModal}
								/>
							</DummyModal>
						)}
						{ModalName == 'DELETE_WORKFLOW_ALERT' && (
							<DummyModal title={'Alert'} onClose={toggleModal}>
								<ConfirmModal
									description={'Are you sure you want to remove this workflow?'}
									OnCancel={toggleModal}
									Onsubmit={deleteDraftWorkflow}
									descriptionClassName='text-left'
									isLoading={formControls.loading}
									error={formControls.error}
								/>
							</DummyModal>
						)}
						{ModalName == 'DEACTIVATE_WORKFLOW_ALERT' && (
							<DummyModal
								title={'Deactivate Workflow?'}
								onClose={toggleModal}
								style={{ width: '50%' }}
							>
								<ConfirmModal
									description={
										'Are you sure you want to deactivate this workflow? All future occurrences will be deleted.'
									}
									note={`The default upload folder named ${
										workflowName || 'Workflow Name'
									} will not be deleted. You can access and move the files to another folder after deactivation.`}
									title={'Confirm'}
									workflowName={workflowName}
									OnCancel={toggleModal}
									Onsubmit={onDeactivate}
									descriptionClassName='text-left'
									isLoading={formControls.loading}
									error={formControls.error}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(WorkflowBar);

const WorkflowColorPicker = ({ color }) => {
	const dispatch = useDispatch();
	const [workflowColor, setWorkflowColor] = useState();
	const [isColorPicker, setIsColorPicker] = useState(false);
	const { id } = useParams();

	const [formControl, setFormControl] = useState({
		loading: false,
		error: '',
	});
	const clientWorkflow = useSelector((state) => state.WorkflowBuilder.selectedClientWorkflow);

	const showColorPicker = () => {
		setIsColorPicker(true);
	};

	const colorChangeHandler = (color) => {
		setWorkflowColor(color);
	};

	const updateClientWorkflowColor = () => {
		setFormControl({
			loading: true,
			error: '',
		});
		const payload = {
			...clientWorkflow,
		};

		delete payload.client_workflow_tasks;
		delete payload.workflow_submission;
		delete payload.repeat_type;
		delete payload.repeat_interval;

		dispatch(
			builderActions.patchClientworkflow(
				{
					color: workflowColor,
					client_id: id,
				},
				payload.id,
			),
		)
			.then(() => {
				setFormControl({
					loading: false,
					error: '',
				});
				setIsColorPicker(false);
			})
			.catch((error) => {
				setFormControl({
					error: error?.message,
					loading: false,
				});
			});
	};

	useEffect(() => {
		setWorkflowColor(color);
	}, [color]);

	return (
		<div
			className='d-flex align-items-center'
			style={{
				position: 'relative',
			}}
		>
			<span onClick={showColorPicker} className='BodyTwoLight pointer primary-text mr-2'>
				Change Workflow Color
			</span>
			<div
				style={{
					width: '24px',
					height: '24px',
					borderRadius: '50%',
					backgroundColor: workflowColor,
					cursor: 'pointer',
				}}
				onClick={showColorPicker}
			></div>
			{isColorPicker && (
				<ClickAwayListener onClickAway={() => setIsColorPicker(false)}>
					<div className='workflow-color-picker d-flex flex-column align-items-center'>
						<div className='w-100 color-picker-header'>
							<h2 className='HeadlineTwoBold'>Workflow Color</h2>
						</div>
						<div className='d-flex align-items-center color-picker-body'>
							<SketchPicker
								width='90'
								onChangeComplete={(color) => colorChangeHandler(color.hex)}
								onChange={(color) => colorChangeHandler(color.hex)}
								disableAlpha
								color={workflowColor}
							/>
						</div>
						<span className='w-100 color-picker-error error-text BodyThreeLight'>
							{formControl?.error}
						</span>

						<div className='d-flex justify-content-between w-100 color-picker-footer'>
							<FormButtonOne
								onClick={() => setIsColorPicker(false)}
								className='secondary-btn'
							>
								Cancel
							</FormButtonOne>
							<FormButtonOne
								onClick={updateClientWorkflowColor}
								disabled={formControl?.loading}
								loading={formControl?.loading}
							>
								Update
							</FormButtonOne>
						</div>
					</div>
				</ClickAwayListener>
			)}
		</div>
	);
};
