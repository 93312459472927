import { useEffect, useRef, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getUserSettings } from '../../hooks/getUserSettings';
import { ClickAwayListener, Popper } from '@mui/material';

function NewDateRangePicker({ dateRanges, applyDates, label, labelClass }) {
	const [datePickerRanges, setDatePickerRanges] = useState(dateRanges);
	const [popperOpen, setPopperOpen] = useState(false);
	const popperRelativeElement = useRef(null);

	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const clickHandler = (event) => {
		popperRelativeElement.current = event.target;
		setPopperOpen(true);
	};

	const handleApply = () => {
		setPopperOpen(false);
		applyDates(datePickerRanges);
	};

	useEffect(() => {
		if (dateRanges) setDatePickerRanges(dateRanges);
	}, [dateRanges]);

	return (
		<>
			<div className='improved-input-designs date-range-input flex-column d-flex w-100'>
				{label && <label className={`text-left ${labelClass}`}>{label}</label>}

				<div
					onClick={clickHandler}
					className='BodyTwoLight value-renderer d-flex pointer'
					style={{ gap: '6px' }}
				>
					<span className='light-text'>From</span>
					{moment(datePickerRanges?.from).format(date_format)}
					<span className='light-text'>To</span>
					{moment(datePickerRanges?.to).format(date_format)}
				</div>
			</div>

			<Popper
				open={popperOpen}
				anchorEl={popperRelativeElement?.current}
				onClose={() => setPopperOpen(false)}
				style={{ zIndex: 9999 }}
			>
				<ClickAwayListener onClickAway={() => setPopperOpen(false)}>
					<div className='d-flex flex-column date-ranger-picker-popper'>
						<div
							className='d-flex'
							style={{
								gap: '24px',
							}}
						>
							<div className='d-flex flex-column'>
								<label className='pl-3 pt-3 BodyTwoLight light-text'>From:</label>
								<DatePicker
									autoOk
									value={datePickerRanges?.from}
									onChange={(date) =>
										setDatePickerRanges({
											...datePickerRanges,
											from: moment(date),
										})
									}
									inputVariant='outlined'
									variant='static'
									disableToolbar
								/>
							</div>
							<div className='d-flex flex-column'>
								<label className='pl-3 pt-3 BodyTwoLight light-text'>To:</label>
								<DatePicker
									autoOk
									value={datePickerRanges?.to}
									onChange={(date) =>
										setDatePickerRanges({
											...datePickerRanges,
											to: moment(date),
										})
									}
									inputVariant='outlined'
									variant='static'
									minDate={datePickerRanges?.from}
									disableToolbar
								/>
							</div>
						</div>
						<div
							className='d-flex justify-content-end'
							style={{ paddingBottom: '10px', paddingRight: '24px' }}
						>
							<span
								className='d-flex primary-text BodyOneBold pointer'
								onClick={handleApply}
							>
								Apply
							</span>
						</div>
					</div>
				</ClickAwayListener>
			</Popper>
		</>
	);
}

export default NewDateRangePicker;
