import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function ExportClientWorkflow() {
	const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = useState(null);
	const [downloadLoading, setDownloadLoading] = useState(false);
	const clientWorkflow = useSelector((state) => state.WorkflowBuilder.selectedClientWorkflow);

	const handleExportClick = (event) => {
		event.stopPropagation();
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const exportClientWorkflow = (fileFormat) => () => {
		setDownloadLoading(true);
		dispatch(builderActions.downloadClientWorkflowFile(clientWorkflow?.id, fileFormat))
			.then((response) => {
				const contentType = response.type || response.headers?.['content-type'];
				const mimeTypeMapping = {
					'application/pdf': 'pdf',
					'application/msword': 'doc',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
						'docx',
				};
				const fileExtension = mimeTypeMapping[contentType];
				if (!fileExtension) {
					dispatch(
						setSnackBarIsOpen(true, true, `Unsupported file type: ${contentType}`),
					);

					return;
				}
				const blob = new Blob([response], { type: contentType });
				const href = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = href;
				link.target = '_blank';
				link.setAttribute('download', `${clientWorkflow?.display_name}.${fileExtension}`);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
				setDownloadLoading(false);
			})
			.catch((error) => {
				setDownloadLoading(false);
				dispatch(setSnackBarIsOpen(true, true, error.message));
			});
	};

	const handleCloseExportMenu = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return (
		<div>
			<div onClick={handleExportClick}>
				<button
					className='rounded-button d-flex align-items- justify-content-center'
					disabled={downloadLoading}
				>
					{downloadLoading ? (
						<CircularProgress size={20} sx={{ color: 'white' }} disableShrink />
					) : (
						<p className='BodyTwoBold m-0 p-0 pt-1'>Export</p>
					)}
				</button>
				<Menu
					open={open}
					onClose={handleCloseExportMenu}
					anchorEl={anchorEl}
					placement='bottom-left'
					transition
					sx={{
						borderRadius: '4px',
						marginTop: '5px',
					}}
				>
					<MenuItem
						onClick={exportClientWorkflow('PDF')}
						sx={{
							'&:hover': {
								backgroundColor: '#fb7a03',
								color: 'white',
							},
						}}
					>
						<span className='BodyTwoLight'>Export as PDF</span>
					</MenuItem>
					<MenuItem
						onClick={exportClientWorkflow('DOC')}
						sx={{
							'&:hover': {
								backgroundColor: '#fb7a03',
								color: 'white',
							},
						}}
					>
						<span className='BodyTwoLight'>Export as Word DOC</span>
					</MenuItem>
				</Menu>
			</div>
		</div>
	);
}

export default ExportClientWorkflow;
