/* eslint-disable no-mixed-spaces-and-tabs */

import { ReactComponent as EditIcon } from '../../assets/icons/PencilSimpleColor.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Trash-Light.svg';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIndicator.svg';
import { useEffect, useMemo, useState } from 'react';
import { ReactComponent as ClipBoardIcon } from '../../assets/icons/ClipboardText.svg';
import { ReactComponent as ClockIcon } from '../../assets/icons/ApprovalClock.svg';
import { ReactComponent as StackIcon } from '../../assets/icons/Stack.svg';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import ConfirmModal from '../Modal/ConfirmModal';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import AddTemplateTask from '../ClientManagement/Modals/AddTemplateTask';
import { useParams } from 'react-router';
import { executivePermissions } from '../../utilities/utilities';
import PreviewRichText from '../Inputs/PreviewRichText';

export default function TasksComponent({
	task,
	index,
	dependencyOrder,
	dependencyTitle,
	expandTasks,
	handleDragStart,
	handleDragOver,
	handleDragEnd,
	draggedTask,
	onExpandChange,
}) {
	const dispatch = useDispatch();
	const { id } = useParams();
	const [expandTask, setExpandTask] = useState(true);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [actionLoading, setActionLoading] = useState(false);
	const [formProp, setFormProp] = useState({});

	const user = useSelector((state) => state.authReducer.user);

	const isDraggable = useMemo(() => {
		return executivePermissions?.includes(user?.userRole?.id);
	}, [user]);

	const toggleExpand = () => {
		setExpandTask(!expandTask);
		onExpandChange(index, !expandTask);
	};

	const onDragOver = (event) => {
		handleDragOver(event, task);
	};

	const onDragStart = (event) => {
		handleDragStart(event, task);
	};

	const handleEditTask = () => {
		ModalType('TEMPLATE_TASK');
		toggleModal();
		setFormProp(task);
	};

	const handleDeleteTask = () => {
		ModalType('DELETE_TASK_ALERT');
		toggleModal();
	};

	const handleDeleteConfirm = () => {
		setActionLoading(true);
		dispatch(builderActions.removeTasksInTaskTemplates(task.id))
			.then(() => {
				dispatch(builderActions.getTaskTemplates(id));
				dispatch(setSnackBarIsOpen(true, true, 'Task deleted successfully'));
				setActionLoading(false);
				toggleModal();
			})
			.catch((error) => {
				setActionLoading(false);
				toggleModal();
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const onUpdateTask = () => {
		toggleModal();
		setFormProp({});
		dispatch(builderActions.getTaskTemplates(id));
	};

	useEffect(() => {
		setExpandTask(expandTasks);
	}, [expandTasks]);

	return (
		<div
			className='task-section'
			style={{
				cursor: isDraggable ? 'grab' : '',
				border: draggedTask?.id === task.id ? '1px solid #FB7A03' : 'none',
			}}
			draggable={isDraggable}
			onDragStart={onDragStart}
			onDragOver={onDragOver}
			onDragEnd={handleDragEnd}
		>
			<div className='d-flex justify-content-between'>
				<div className='d-flex align-items-center'>
					<div style={{ marginRight: '8px' }}>
						{!expandTask && <DragIcon />}
						<ClipBoardIcon />
					</div>
					<span className='HeadlineThreeBold'>{task?.title}</span>
					<span className='BodyOneLight ml-2'>{dependencyOrder}</span>
				</div>
				<div className='d-flex align-items-center'>
					<EditIcon
						style={{ marginRight: '8px', cursor: 'pointer' }}
						onClick={() => handleEditTask(task)}
					/>
					<DeleteIcon style={{ cursor: 'pointer' }} onClick={handleDeleteTask} />
					<span className='pointer' onClick={toggleExpand}>
						{expandTask ? (
							<KeyboardArrowUpIcon sx={{ color: '#FB7A03' }} />
						) : (
							<KeyboardArrowDownIcon sx={{ color: '#FB7A03' }} />
						)}
					</span>
				</div>
			</div>
			{expandTask && (
				<div className='h-100'>
					<div
						className='d-flex align-items-center'
						style={{ marginTop: '17px', gap: '12px' }}
					>
						<div className='d-flex align-items-center'>
							<ClockIcon />
							<div className='pl-1'>
								<span className='BodyTwoBold'>
									Budgeted Hours:{' '}
									<span className='BodyTwoLight'>{task.budgeted_hours}</span>
								</span>
							</div>
						</div>
						<div className='d-flex align-items-center'>
							<StackIcon />
							<div className='pl-1'>
								<span className='BodyTwoBold'>
									Dependency:{' '}
									<span className='BodyTwoLight'>{dependencyTitle}</span>
								</span>
							</div>
						</div>
					</div>

					<div
						className='task-section-body w-100'
						style={{ maxHeight: '190px', overflow: 'auto' }}
					>
						<div className='w-100 text-left'>
							<span className='BodyTwoLight placeholder-text'>
								{task?.description?.length < 1 ||
								task?.description == null ||
								task?.description?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
									'No instructions available'
								) : (
									<div className='d-flex flex-column justify-content-between position-relative'>
										<PreviewRichText
											content={task?.description}
											classNames={['BodyTwoLight', 'dark-text']}
										/>
									</div>
								)}
							</span>
						</div>
					</div>
				</div>
			)}
			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'DELETE_TASK_ALERT' && (
							<DummyModal title={'Alert'} onClose={toggleModal}>
								<ConfirmModal
									description={'Are you sure you want to remove this task?'}
									OnCancel={toggleModal}
									Onsubmit={handleDeleteConfirm}
									descriptionClassName='text-left'
									isLoading={actionLoading}
								/>
							</DummyModal>
						)}
						{ModalName === 'TEMPLATE_TASK' && (
							<DummyModal
								title={'Add New Task'}
								onClose={toggleModal}
								style={{ width: '900px' }}
								hideCrossIcon
							>
								<AddTemplateTask
									onSubmit={onUpdateTask}
									onCancel={toggleModal}
									editor
									formValues={formProp}
									editTask={true}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}
