/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import { TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import HeadingOne from '../TypoGraphy/HeadingOne';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import * as userActions from '../../redux/actions/user-management.actions';
import { useHistory, useLocation } from 'react-router';
import TableRowButton from '../Others/TableRowButton';
import { convertDecimalToTime, countWeekdays } from '../../utilities/utilities';

function TimeReportsUsersV2({ sectionId }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const loading = useSelector((state) => state.TimeReportsV2.loading);
	const users = useSelector((state) => state.TimeReportsV2.users);
	const userProfiles = useSelector((state) => state.userReducer.users);
	const userBillingList = useSelector((state) => state.userReducer.userBillingList);

	const start_date = searchParams.get('start_date');
	const end_date = searchParams.get('end_date');
	const monthlyView = searchParams.get('monthly_view') === 'true';

	const numberOfWeekdays = countWeekdays(start_date, end_date);
	const numberOfWeeksOfWeekdays = numberOfWeekdays / 5;
	const billingMap = new Map(
		userBillingList?.map((user) => [
			user.user_id,
			monthlyView ? user.available_hours * numberOfWeeksOfWeekdays : user.available_hours,
		]),
	);
	const navigateToUser = (user) => {
		history.push(`time-reports-user/${user.user_id}`);
	};
	const userProfileMap = new Map(userProfiles?.map((up) => [up.user_id, up]));
	const usersMap = new Map(
		users?.map((user) => [
			user.user_id,
			{
				...userProfileMap.get(user.user_id),
				budgeted_hours: user.budgeted_hours,
				consumed_time: user.consumed_time / 3600000,
			},
		]),
	);

	const navigateToUsersExport = () => {
		history.push('/users-payroll-export');
	};

	useEffect(() => {
		if (start_date && end_date) dispatch(TimeReportsActions.getUserStats(start_date, end_date));
	}, [start_date, end_date]);

	useEffect(() => {
		dispatch(userActions.getUsersBilling());
		dispatch(userActions.getUsers(null));
	}, [dispatch]);

	return (
		<div className='profile-section' id={sectionId}>
			<SectionBar
				header={<HeadingOne>Users</HeadingOne>}
				headerActions={
					<SkeletonLoading loading={loading} variant='text' width={100} height={50}>
						<button onClick={navigateToUsersExport} className='rounded-button-2'>
							Payroll Export
						</button>
					</SkeletonLoading>
				}
			/>

			<div className='profile-section-body border'>
				<div className='team-members-body'>
					<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
						<TableHeader ClassName='new-table-header' border={false}>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='User Name'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Available Hours'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Budgeted Hours'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Logged Time'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Capacity'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Utilization'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Details'
								width={TableItemWidth5}
							/>
						</TableHeader>
					</SkeletonLoading>
					<div className='ListingLoading scroll'>
						{users?.length < 1 ? (
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
									<h4 className='BodyTwoLight'>No Users</h4>
								</div>
							</SkeletonLoading>
						) : (
							users?.map((user) => {
								const availableHours = billingMap.get(user.user_id)
									? billingMap.get(user.user_id)
									: 0;
								const budgetedHours = usersMap?.get(user.user_id)?.budgeted_hours
									? usersMap?.get(user.user_id)?.budgeted_hours
									: 0;

								const Capacity = convertDecimalToTime(
									availableHours - budgetedHours,
								);
								const percentageUtilization =
									availableHours > 0
										? Math.floor((budgetedHours / availableHours) * 100)
										: 0;

								return (
									<SkeletonLoading
										loading={loading}
										variant='text'
										width='100%'
										height={45}
										key={user.user_id}
									>
										<div className='new-table-row d-flex align-items-center'>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={user?.full_name}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={
													billingMap?.get(user.user_id)
														? convertDecimalToTime(
																billingMap?.get(user.user_id),
														  )
														: convertDecimalToTime(0)
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={
													usersMap?.get(user.user_id)?.budgeted_hours
														? convertDecimalToTime(
																usersMap?.get(user.user_id)
																	?.budgeted_hours,
														  )
														: convertDecimalToTime(0)
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={
													usersMap?.get(user.user_id)?.consumed_time
														? convertDecimalToTime(
																usersMap?.get(user.user_id)
																	?.consumed_time,
														  )
														: convertDecimalToTime(0)
												}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass={`BodyTwoLight ${
													(billingMap.get(user.user_id)
														? billingMap.get(user.user_id)
														: 0) -
														(usersMap?.get(user.user_id)?.budgeted_hours
															? usersMap?.get(user.user_id)
																	?.budgeted_hours
															: 0) <
													0
														? 'red-text'
														: ''
												}`}
												label={Capacity}
												width={TableItemWidth5}
												input={false}
												toolTipText='Available Hours - Budgeted Hours'
											/>
											<TableRowItem
												ClassName='text-left'
												textClass={`BodyTwoLight`}
												label={`${percentageUtilization} %`}
												width={TableItemWidth5}
												input={false}
												toolTipText='Budgeted Hours / Available Hours'
											/>
											<TableRowButton
												ClassName='text-left pointer primary-text'
												textClass='BodyTwoLight'
												label='View'
												width={TableItemWidth5}
												input={false}
												clickHandler={() => navigateToUser(user)}
												dataCy='view-user'
											/>
										</div>
									</SkeletonLoading>
								);
							})
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsUsersV2;
