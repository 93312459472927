/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';
import { ReactComponent as MoreIcon } from '../../assets/icons/More.svg';
import { ReactComponent as WorkflowIcon } from '../../assets/icons/Package.svg';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/CircleWavyCheck.svg';
import { ClickAwayListener, Menu, MenuItem, Modal } from '@mui/material';
import NewWorkflowDetailModal from '../Modal/NewWorkflowDetailModal';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/workflow-builder-actions';
import { useHistory, useLocation } from 'react-router';
import { executivePermissions } from '../../utilities/utilities';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

export default function NewWorkflowCard({
	workflow,
	profileData,
	companyLibrary,
	isChecked,
	categoryId,
	categoryName,
	handleCheckBoxes,
	moveWorkflowsToDraft,
	publishWorkflows,
	archivePublishedWorkflows,
	handleSelectClient,
	handleAddToClient,
	unArchiveSingleWorkflow,
	archiveView,
}) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const [workflowModal, setWorkflowModal] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [subMenuAnchorEl, setSubMenuAnchorEl] = useState(null);
	const user = useSelector((state) => state.authReducer.user);
	const clientForbuilder = location?.state;
	const workflowChecked = isChecked?.map((workflow) => workflow?.id)?.includes(workflow.id);

	const handleMenuClose = (event) => {
		event.stopPropagation();
		setAnchorEl(null);
		setSubMenuAnchorEl(null);
	};

	const handleMenuOpen = (event) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleSubmenuOpen = (event) => {
		event.stopPropagation();
		setSubMenuAnchorEl(event.currentTarget);
	};

	const exportWorkflow = (event, fileFormat) => {
		event?.stopPropagation();
		handleMenuClose(event);
		dispatch(Actions.downloadWorkflowFile(workflow?.id, fileFormat))
			.then((response) => {
				const contentType = response.type || response.headers?.['content-type'];
				const mimeTypeMapping = {
					'application/pdf': 'pdf',
					'application/msword': 'doc',
					'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
						'docx',
				};
				const fileExtension = mimeTypeMapping[contentType];
				if (!fileExtension) {
					dispatch(
						setSnackBarIsOpen(true, true, `Unsupported file type: ${contentType}`),
					);

					return;
				}
				const blob = new Blob([response], { type: contentType });
				const href = URL.createObjectURL(blob);
				const link = document.createElement('a');
				link.href = href;
				link.target = '_blank';
				link.setAttribute('download', `${workflow?.name}.${fileExtension}`);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				URL.revokeObjectURL(href);
			})
			.catch((error) => {
				dispatch(setSnackBarIsOpen(true, true, error.message));
			});
	};

	const handleWorkflowModal = () => {
		setWorkflowModal(!workflowModal);
	};

	const handleDone = () => {
		if (workflow?.status === 'DRAFT') {
			setWorkflowModal(!workflowModal);
			publishWorkflows(workflow?.id);
			return;
		}
		if (companyLibrary) {
			clientForbuilder
				? handleAddToClient(clientForbuilder?.clientId, workflow)
				: handleSelectClient(workflow);
			return;
		}
		if (archiveView) {
			unArchiveSingleWorkflow(workflow?.id);
			setWorkflowModal(!workflowModal);
			return;
		}
		setWorkflowModal(!workflowModal);
		moveWorkflowsToDraft({ id: workflow?.id, name: workflow.name });
	};

	const openWorkflowEditor = () => {
		dispatch(
			Actions.setWorkflowForm({
				name: workflow.name,
				category_id: categoryId,
				description: workflow.description,
				color: workflow.color,
			}),
		);
		history.push(`/workflow-editor/${workflow.id}`, clientForbuilder);
	};
	return (
		<>
			<div
				className={`new-workflow-card pointer ${
					isChecked?.length == 10 && !workflowChecked ? 'new-workflow-card-disabled' : ''
				}`}
				style={{ margin: '0px 0px 18px 18px' }}
				onClick={handleWorkflowModal}
			>
				<div className='d-flex justify-content-between align-items-start w-100'>
					<div>
						{workflow?.status && (
							<div
								className='d-flex align-items-center'
								style={{
									height: '24px',
									padding: '4px 8px',
									width: 'fit-content',
									borderRadius: '4px',
									backgroundColor:
										workflow?.status === 'PUBLISHED' ? '#18C58D' : '#Fb7a03',
								}}
							>
								<span className='BodyThreeBold' style={{ color: 'white' }}>
									{workflow?.status === 'PUBLISHED' ? 'Published' : 'Draft'}
								</span>
							</div>
						)}
						<div className='d-flex align-items-center' style={{ padding: '8px 0px' }}>
							{workflow?.status !== 'DRAFT' && (
								<input
									data-cy={`workflow-browser-${workflow.id}`}
									type='checkbox'
									className='multi-select-checkbox'
									onChange={(e) => {
										handleCheckBoxes(workflow);
									}}
									onClick={(event) => event.stopPropagation()}
									checked={workflowChecked}
								/>
							)}
							<div style={{ padding: '0px 4px' }}>
								<WorkflowIcon />
							</div>
							<div className='d-flex flex-column align-items-start'>
								<span className='BodyOneBold'>
									{workflow?.name
										? workflow?.name?.length > 28
											? workflow.name.substring(0, 28) + '...'
											: workflow?.name
										: 'No Task Title'}
								</span>
								{categoryName && (
									<span className='BodyThreeLight'>{categoryName}</span>
								)}
							</div>
						</div>
					</div>
					<ClickAwayListener onClickAway={handleMenuClose}>
						<>
							<MoreIcon onClick={handleMenuOpen} />
							<Menu
								anchorEl={anchorEl}
								open={Boolean(anchorEl)}
								onClose={handleMenuClose}
							>
								{workflow?.status === 'DRAFT' && (
									<>
										<MenuItem
											onClick={() => {
												publishWorkflows(workflow?.id);
											}}
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
										>
											<span className='BodyTwoLight'>Publish</span>
										</MenuItem>
										<MenuItem
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
											onClick={openWorkflowEditor}
										>
											<span className='BodyTwoLight'>Edit in Builder</span>
										</MenuItem>
										<MenuItem
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
											onClick={() => archivePublishedWorkflows(workflow?.id)}
										>
											<span className='BodyTwoLight'>Delete</span>
										</MenuItem>
									</>
								)}
								{workflow?.status === 'PUBLISHED' && !workflow?.deleted && (
									<>
										{executivePermissions?.includes(user?.userRole?.id) && (
											<MenuItem
												onClick={openWorkflowEditor}
												sx={{
													'&:hover': {
														backgroundColor: '#fb7a03',
														color: 'white',
													},
												}}
											>
												<span className='BodyTwoLight'>
													Edit in Builder
												</span>
											</MenuItem>
										)}

										<MenuItem
											onClick={handleDone}
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
										>
											<span className='BodyTwoLight'>Add To Client</span>
										</MenuItem>
										<MenuItem
											onClick={() => archivePublishedWorkflows(workflow?.id)}
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
										>
											<span className='BodyTwoLight'>Archive Workflow</span>
										</MenuItem>

										<MenuItem
											onClick={handleSubmenuOpen}
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
										>
											<span className='BodyTwoLight'>Export Workflow</span>
										</MenuItem>
									</>
								)}
								{workflow?.status !== 'DRAFT' &&
									workflow?.status !== 'PUBLISHED' && (
										<MenuItem
											onClick={() =>
												moveWorkflowsToDraft({
													id: workflow?.id,
													name: workflow.name,
												})
											}
											sx={{
												'&:hover': {
													backgroundColor: '#fb7a03',
													color: 'white',
												},
											}}
										>
											<span className='BodyTwoLight'>Save as Draft</span>
										</MenuItem>
									)}
								{workflow?.deleted && (
									<MenuItem
										onClick={() => unArchiveSingleWorkflow(workflow?.id)}
										sx={{
											'&:hover': {
												backgroundColor: '#fb7a03',
												color: 'white',
											},
										}}
									>
										<span className='BodyTwoLight'>Unarchive</span>
									</MenuItem>
								)}
							</Menu>
							<Menu
								open={Boolean(subMenuAnchorEl)}
								onClose={handleMenuClose}
								anchorEl={subMenuAnchorEl}
								transition
								anchorOrigin={{
									vertical: 'center',
									horizontal: 'right',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: 'left',
								}}
							>
								<MenuItem
									onClick={(event) => exportWorkflow(event, 'PDF')}
									sx={{
										'&:hover': {
											backgroundColor: '#fb7a03',
											color: 'white',
										},
									}}
								>
									<span className='BodyTwoLight'>Export as PDF</span>
								</MenuItem>
								<MenuItem
									onClick={(event) => exportWorkflow(event, 'DOC')}
									sx={{
										'&:hover': {
											backgroundColor: '#fb7a03',
											color: 'white',
										},
									}}
								>
									<span className='BodyTwoLight'>Export as Word DOC</span>
								</MenuItem>
							</Menu>
						</>
					</ClickAwayListener>
				</div>
				<div className='description-container text-left' style={{ position: 'relative' }}>
					<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
						{workflow?.description
							? workflow?.description?.length > 120
								? workflow?.description?.substring(0, 120) + '...'
								: workflow?.description
							: 'No description available'}
					</span>
					<div
						className='d-flex align-items-center'
						style={{ paddingTop: '0px', position: 'absolute', bottom: '5px' }}
					>
						<span className='BodyTwoBold'>
							Created by:
							<span className='BodyTwoLight pl-1 pr-1'>
								{companyLibrary || archiveView ? profileData?.name : 'Levvy'}
							</span>
						</span>
						{!companyLibrary && !archiveView && <VerifiedIcon />}
					</div>
				</div>
			</div>
			{workflowModal && (
				<Modal
					onClose={handleWorkflowModal}
					open={workflowModal}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						<NewWorkflowDetailModal
							workflow={workflow}
							companyLibrary={companyLibrary}
							editWorkflow={openWorkflowEditor}
							onClose={handleWorkflowModal}
							onDone={handleDone}
							archiveView={archiveView}
						/>
					</>
				</Modal>
			)}
		</>
	);
}
