import { useEffect } from 'react';
import moment from 'moment';
import { ReactComponent as LeftIcon } from '../../assets/icons/chevron-left-large.svg';
import { ReactComponent as RightIcon } from '../../assets/icons/chevron-right-large.svg';
import { useSelector } from 'react-redux';
import SkeletonLoading from '../Others/SkeletonLoading';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ReactComponent as CalendarIcon } from '../../assets/icons/Calendarcolor.svg';
import { useHistory, useLocation, useParams } from 'react-router';

function TimeReportsDateV2() {
	const history = useHistory();
	const { id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const start_date = searchParams.get('start_date');
	const end_date = searchParams.get('end_date');
	const monthlyView = searchParams.get('monthly_view') === 'true';
	const dailyView = searchParams.get('daily_view') === 'true';

	const loading = useSelector((state) => state.TimeReportsV2.loading);

	function getDates(date) {
		const start_date = dailyView
			? moment(date).format('YYYY-MM-DD')
			: monthlyView
			? moment(date).startOf('month').format('YYYY-MM-DD')
			: moment(date).startOf('isoWeek').format('YYYY-MM-DD');
		const end_date = dailyView
			? moment(date).format('YYYY-MM-DD')
			: monthlyView
			? moment(date).endOf('month').format('YYYY-MM-DD')
			: moment(date).endOf('isoWeek').format('YYYY-MM-DD');
		return { startDate: start_date, endDate: end_date };
	}

	const handleNavigation = (direction, date) => {
		let newDate = undefined;
		if (direction == 'left') {
			newDate = moment(date)
				.subtract(1, dailyView ? 'day' : monthlyView ? 'month' : 'week')
				.format('YYYY-MM-DD');
		}
		if (direction == 'right') {
			newDate = moment(date)
				.add(1, dailyView ? 'day' : monthlyView ? 'month' : 'week')
				.format('YYYY-MM-DD');
		}

		const { startDate, endDate } = getDates(newDate);
		searchParams.set('start_date', startDate);
		searchParams.set('end_date', endDate);
		history.replace(`${location.pathname}?${searchParams.toString()}`);
	};

	const handleDatePicker = (date) => {
		const { startDate, endDate } = getDates(date.format('YYYY-MM-DD'));
		searchParams.set('start_date', startDate);
		searchParams.set('end_date', endDate);
		history.replace(`${location.pathname}?${searchParams.toString()}`);
	};

	useEffect(() => {
		if (!start_date) {
			searchParams.set('monthly_view', false);
			searchParams.set('daily_view', false);
			history.replace(`${location.pathname}?${searchParams.toString()}`);
		}
	}, []);

	useEffect(() => {
		const { startDate, endDate } = getDates(start_date || moment().format('YYYY-MM-DD'));
		searchParams.set('start_date', startDate);
		searchParams.set('end_date', endDate);
		history.replace(`${location.pathname}?${searchParams.toString()}`);
	}, [dailyView, monthlyView]);

	return (
		<div
			className='d-flex align-items-center justify-content-between w-100'
			style={{
				backgroundColor: 'white',
				padding: '20px 18px',
				borderRadius: '4px 4px 0px 0px',
				gap: '1.5em',
				boxSizing: 'border-box',
			}}
		>
			<div className='d-flex justify-content-between w-100'>
				<SkeletonLoading loading={loading} variant='text' width='25%' height={45}>
					<div className='d-flex align-items-center' style={{ gap: '2em' }}>
						<div className='d-flex' style={{ gap: '1em' }}>
							{id && (
								<button
									onClick={() => {
										searchParams.set('monthly_view', false);
										searchParams.set('daily_view', true);
                                        searchParams.delete('start_date');
										history.replace(
											`${location.pathname}?${searchParams.toString()}`,
										);
									}}
									className={dailyView ? 'primary-btn' : 'secondary-btn'}
								>
									Daily
								</button>
							)}
							<button
								onClick={() => {
									searchParams.set('monthly_view', false);
									searchParams.set('daily_view', false);
                                    searchParams.delete('start_date');
									history.replace(
										`${location.pathname}?${searchParams.toString()}`,
									);
								}}
								className={
									!monthlyView && !dailyView ? 'primary-btn' : 'secondary-btn'
								}
							>
								Weekly
							</button>
							<button
								onClick={() => {
									searchParams.set('monthly_view', true);
									searchParams.set('daily_view', false);
                                    searchParams.delete('start_date');
									history.replace(
										`${location.pathname}?${searchParams.toString()}`,
									);
								}}
								className={monthlyView ? 'primary-btn' : 'secondary-btn'}
							>
								Monthly
							</button>
						</div>
					</div>
				</SkeletonLoading>
				<SkeletonLoading loading={loading} variant='text' width='25%' height={45}>
					<div className='d-flex align-items-center'>
						<div
							className='d-flex align-items-center'
							onClick={() => !dailyView && handleNavigation('left', start_date)}
						>
							<LeftIcon
								onClick={() => dailyView && handleNavigation('left', start_date)}
								className='pointer'
							/>
							<p className='m-0 BodyTwoLight'>
								<span className='m-0 BodyTwoLight-light pointer'>
									{moment(start_date).format('MMMM D YYYY')}{' '}
								</span>
							</p>
						</div>
						{!dailyView && (
							<p className='m-0 BodyTwoLight'>
								<span className='m-0 BodyTwoLight-light'>
									&nbsp;&nbsp;to&nbsp;&nbsp;
								</span>{' '}
							</p>
						)}
						<div
							className='d-flex align-items-center'
							onClick={() => handleNavigation('right', start_date)}
						>
							{!dailyView && (
								<p className='m-0 BodyTwoLight'>
									<span
										className='m-0 BodyTwoLight-light pointer'
										onClick={() => handleNavigation('right', start_date)}
									>
										{moment(end_date).format('MMMM D YYYY')}
									</span>
								</p>
							)}
							<RightIcon className='pointer' />
						</div>
						{dailyView && (
							<div className='improved-input-desigms'>
								<KeyboardDatePicker
									autoOk
									disableToolbar
									name='date'
									onChange={handleDatePicker}
									keyboardIcon={<CalendarIcon />}
									className='styledKeyboardDatePicker'
									variant='inline'
                                    value={start_date}
								/>
							</div>
						)}
					</div>
				</SkeletonLoading>
			</div>
		</div>
	);
}

export default TimeReportsDateV2;
