import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import Sidebar from '../sidebar/SideBar';
import ProfileBarV2 from '../TimeReportsV2/ProfileBarV2';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import TimeReportsClientInfoV2 from '../TimeReportsV2/TimeReportsClientInfoV2';
import TimeReportsDateV2 from '../TimeReportsV2/TimeReportsDateV2';

function TimeReportsClientDetailsV2() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const start_date = searchParams.get('start_date');
	const end_date = searchParams.get('end_date');

	const [activeTab, setActiveTab] = useState(0);

	const clientData = useSelector((state) => state.TimeReportsV2.clientDeatils);
	const timeReportsLoading = useSelector((state) => state.TimeReportsV2.timeReportsLoading);
	const initialLoading = useSelector((state) => state.TimeReportsV2.initialLoading);

	const navList = clientData?.users
		?.filter(
			(user) =>
				user?.workflow_my_tasks?.length > 0 ||
				user?.workflows?.length > 0 ||
				user?.meetings?.length > 0,
		)
		?.map((user) => {
			return {
				name: user?.full_name,
				sectionId: user?.user_id,
			};
		});

	const ChangeActiveTab = (e) => {
		setActiveTab(e.target.id);
	};

	useEffect(() => {
		!timeReportsLoading &&
			start_date &&
			end_date &&
			dispatch(
				TimeReportsActions.getClientDetails(
					Number(id),
					start_date,
					end_date,
					initialLoading,
				),
			);
		!timeReportsLoading && dispatch(TimeReportsActions.setInitialLoading(true));
	}, [dispatch, start_date, end_date, timeReportsLoading]);
	return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						<div className='side-menu m-0 d-flex flex-column align-items-start'>
							{navList?.map((navItem, i) => {
								return (
									<span
										key={i}
										className={
											activeTab == i
												? 'active d-flex flex-row align-items-center text-left'
												: 'side-menu-link d-flex flex-row align-items-center text-left'
										}
										style={{
											gap: '10px',
											paddingBlock: '10px',
										}}
									>
										<div className='dot'></div>
										<a
											onClick={ChangeActiveTab}
											href={`#${navItem.sectionId}`}
											id={i}
											className='BodyTwoLight'
										>
											{navItem.name}
										</a>
									</span>
								);
							})}
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='timeReports-main time-reports-main'>
				<div className='time-reports-content' style={{ gap: '0' }}>
					<ProfileBarV2
						barText={clientData?.dba == '' ? clientData?.company_name : clientData?.dba}
						clientDetails={clientData}
					/>
					<div className='time-reports-body'>
						<TimeReportsDateV2 />
						<div
							className='d-flex flex-column'
							style={{
								gap: '3em',
							}}
						>
							<TimeReportsClientInfoV2 clientData={clientData} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsClientDetailsV2;
