import { memo, useRef, useState } from 'react';
import { ReactComponent as DownArrow } from '../../../assets/icons/CaretDown.svg';
import { ReactComponent as RightArrow } from '../../../assets/icons/CaretRight.svg';
import { ReactComponent as FolderIcon } from '../../../assets/icons/FolderNotch.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/more_horiz-24px.svg';
import useModal from '../../Modal/useModal';
import RenameFolderModal from '../../Modal/RenameFodlerModal';
import DeleteModal from '../../Modal/DeleteModal';
import AccessModal from '../../Modal/AccessModal';
import { useDispatch, useSelector } from 'react-redux';
import * as clientActions from '../../../redux/actions/client-actions';
import FileExistModal from '../../Modal/FileExistModal';
import CreateFolderModal from '../../Modal/CreateFolderModal';
import ProgressBarModal from '../../Modal/ProgressBarModal';
import useOutsideClick from '../../../hooks/useOutsideClick';
import { CircularProgress, Tooltip } from '@mui/material';
import Restricted from '../../Permissions/Restricted';
import { generateUniqueFiles, standardPermissions } from '../../../utilities/utilities';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';

function DocumentFolder({
	children,
	onFolderClick,
	expanded,
	depth,
	onDragEnd,
	onDragEnter,
	onSelectFolder,
	isSelected,
	item,
	handleDragLeave,
	dragOverContainer,
	disabled = false,
	headers,
	showCheckBox,
	disableDelete,
	defaultChecked,
	handleFolderCheckboxes,
	isChecked,
	attachmentLoading,
	disableDnd,
	folderRef,
}) {
	const dropdownRef = useRef();
	const [isBoxOpen, setIsBoxOpen] = useState(false);
	const [dragActive, setDragActive] = useState(false);
	const [files, setFiles] = useState();
	const [isFolderDefault, checkIsFolderDefault] = useState(false);
	const [loading, setLoading] = useState(false);
	const dispatch = useDispatch();
	const allTenantUsers = useSelector((state) => state.userReducer.allTenantUsers);
	const uploadedBy =
		allTenantUsers?.find((user) => user.id === item.created_by)?.full_name || '-';
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const inputRef = useRef(null);

	const handleDrag = function (e) {
		e.preventDefault();
		e.stopPropagation();
	};

	const handleEventIfEnabled =
		(handler, handler_1) =>
		(e, ...args) => {
			if (!disabled && !modalIsOpen) {
				handler(e, ...args);
				handler_1(e, ...args);
			}
		};

	const handleDragEventIfEnabled =
		(handler) =>
		(e, ...args) => {
			if (!disabled && !disableDnd) {
				handler(e, ...args);
			}
		};

	const uploadFile = async (file, parentId) => {
		const formData = new FormData();
		formData.append('file', file);
		formData.append('parent_folder_id', parentId);

		try {
			const response = await dispatch(clientActions.createFile(formData, file));
			dispatch(clientActions.createFileReducer(response));
		} catch (error) {
			if (error.message === 'Document name already exists') {
				handleModalOpen('ALREADY_EXISTS');
			} else {
				dispatch(setSnackBarIsOpen(true, true, 'File upload failed.'));
			}
		}
	};

	const handleDrop = async (e, item) => {
		e.preventDefault();
		e.stopPropagation();
		setDragActive(false);

		const files = Array.from(e.dataTransfer.files);
		const updatedFiles = generateUniqueFiles(files);
		setFiles(updatedFiles);
		dispatch(clientActions.resetUploadState());

		if (updatedFiles && updatedFiles?.length > 0) {
			handleModalOpen('PROGRESS_BAR');

			const uploadPromises = updatedFiles?.map((file) => uploadFile(file, item));

			try {
				await Promise.all(uploadPromises);
			} catch (error) {
				console.log('test1234');
			} finally {
				setTimeout(() => {
					toggleModal();
				}, 3000);
			}
		}
	};

	const handleMoreIcon = (e) => {
		e.stopPropagation();
		e.preventDefault();
		setIsBoxOpen(true);
	};

	const handleModalOpen = (type) => {
		ModalType(type);
		toggleModal();
	};

	const checkDefaultFolder = (id) => {
		setLoading(true);
		dispatch(clientActions.checkDefaultFolder(id))
			.then((res) => {
				checkIsFolderDefault(res?.is_set_default);
			})
			.catch((err) => {
				console.log(err, 'err');
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useOutsideClick(dropdownRef, () => setIsBoxOpen(false));

	return (
		<>
			<div onSubmit={(e) => e.preventDefault()} className='folder-container'>
				<input
					ref={inputRef}
					type='file'
					id='input-file-upload'
					multiple={true}
					hidden
					disabled={true}
					className='folder-container'
				/>
				<label
					id='label-file-upload'
					htmlFor='input-file-upload'
					className={dragActive ? 'drag-active' : ''}
				>
					<div
						draggable={!disabled && !disableDnd}
						className='folder-container'
						onDragEnd={handleDragEventIfEnabled(onDragEnd)}
						onDragEnter={handleDragEventIfEnabled(onDragEnter)}
						id={item.id}
						onDrop={handleDragEventIfEnabled((e) => handleDrop(e, item.id))}
						onDragLeave={handleDragEventIfEnabled(handleDragLeave)}
					>
						<div
							ref={folderRef}
							onClick={handleEventIfEnabled(onSelectFolder, onFolderClick)}
							id='drag-file-element'
							onDragOver={handleDragEventIfEnabled(handleDrag)}
							className='folder-body'
							style={{
								width: '100%',
								backgroundColor:
									dragOverContainer == item?.id || isSelected
										? '#FED0A5'
										: '#F7F9FA',
								paddingLeft: `${10 * depth + 10}px`,
							}}
						>
							<div className='d-flex align-items-center'>
								<div onClick={handleEventIfEnabled(onSelectFolder, onFolderClick)}>
									{expanded ? (
										<DownArrow style={{ width: '14px', height: '14px' }} />
									) : (
										<RightArrow style={{ width: '14px', height: '14px' }} stroke='#333333' />
									)}
								</div>

								{showCheckBox && (
									<div style={{ marginInline: '4px' }}>
										{attachmentLoading ? (
											<CircularProgress
												size={12}
												sx={{ color: '#fb7a03' }}
												disableShrink
											/>
										) : (
											<>
												{defaultChecked && (
													<Tooltip title='The workflow folder is automatically created by default and pre-selected, ensuring it appears in the task modal on the workspace for convenient access by your users.'>
														<input
															type='checkbox'
															className='multi-select-checkbox'
															onClick={(e) => e.stopPropagation()}
															disabled={disabled || defaultChecked}
															onChange={() =>
																handleFolderCheckboxes(item)
															}
															checked={isChecked?.includes(item?.id)}
															style={{
																opacity: defaultChecked
																	? '0.5'
																	: '1',
															}}
														/>
													</Tooltip>
												)}

												{!defaultChecked && (
													<input
														type='checkbox'
														className='multi-select-checkbox'
														onClick={(e) => e.stopPropagation()}
														disabled={disabled || defaultChecked}
														onChange={() =>
															handleFolderCheckboxes(item)
														}
														checked={isChecked?.includes(item?.id)}
														style={{
															opacity: defaultChecked ? '0.5' : '1',
														}}
													/>
												)}
											</>
										)}
									</div>
								)}
								<div style={{ marginRight: '4px' }}>
									<FolderIcon style={{ width: '14px', height: '14px' }} />{' '}
								</div>
								<div className='truncated'>
									<span className='BodyTwoLight'>{item?.name}</span>
								</div>
							</div>
							<div className='d-flex align-items-center justify-content-evenly'>
								{headers?.some((header) => header.key === 'lastModified') && (
									<div
										style={{
											width: headers.find(
												(header) => header.key === 'lastModified',
											)?.width,
										}}
										className='d-flex align-items-center'
									>
										<span className='BodyTwoLight'>
											{new Date(item.updated_at).toLocaleDateString('en-US')}
										</span>
									</div>
								)}
								{headers?.some((header) => header.key === 'fileSize') && (
									<div
										style={{
											width:
												headers.find((header) => header.key === 'fileSize')
													?.width || 'auto',
										}}
										className='d-flex align-items-center'
									>
										<span className='BodyTwoLight'>-</span>
									</div>
								)}
								{headers?.some((header) => header.key === 'uploadedBy') && (
									<div
										style={{
											width: headers.find(
												(header) => header.key === 'uploadedBy',
											)?.width,
										}}
										className='d-flex align-items-center'
									>
										<span className='BodyTwoLight'>{uploadedBy}</span>
									</div>
								)}

								{headers?.some((header) => header.key === 'action') && (
									<div
										style={{
											width: headers.find((header) => header.key === 'action')
												?.width,
										}}
										className='d-flex align-items-center'
									>
										<div
											className='pointer'
											onClick={disabled ? null : handleMoreIcon}
											style={{ position: 'relative' }}
										>
											<MoreIcon />
											{isBoxOpen && (
												<>
													<div
														className='dropdown-box p-0'
														ref={dropdownRef}
														style={{ width: '120px' }}
													>
														<div
															style={{
																width: '120px',
																padding: '6px 10px',
															}}
															onClick={() =>
																handleModalOpen('RENAME')
															}
														>
															<span
																style={{
																	width: '107px',
																	display: 'flex',
																	flexDirection: 'start',
																	textAlign: 'start',
																	cursor: 'pointer',
																}}
																className='BodyTwoLight'
															>
																Rename
															</span>
														</div>
														<div
															style={{
																width: '120px',
																padding: '6px 10px',
															}}
															onClick={() =>
																handleModalOpen('CREATE_FOLDER')
															}
														>
															<span
																style={{
																	width: '107px',
																	display: 'flex',
																	flexDirection: 'start',
																	textAlign: 'start',
																	cursor: 'pointer',
																}}
																className='BodyTwoLight'
															>
																Create subfolder
															</span>
														</div>

														<Restricted
															AllowedUsers={standardPermissions}
														>
															<div
																style={{
																	width: '120px',
																	padding: '6px 10px',
																}}
																onClick={() =>
																	handleModalOpen('ACCESS')
																}
															>
																<span
																	style={{
																		width: '107px',
																		display: 'flex',
																		flexDirection: 'start',
																		textAlign: 'start',
																		cursor: 'pointer',
																	}}
																	className='BodyTwoLight'
																>
																	Access
																</span>
															</div>
														</Restricted>

														{!disableDelete && (
															<div
																style={{
																	width: '140px',
																	padding: '6px 10px',
																}}
																onClick={() => {
																	handleModalOpen(
																		'DELETE_FOLDER',
																	);
																	checkDefaultFolder(item?.id);
																}}
															>
																<span
																	style={{
																		width: '107px',
																		display: 'flex',
																		flexDirection: 'start',
																		textAlign: 'start',
																		cursor: 'pointer',
																	}}
																	className='BodyTwoLight'
																>
																	Delete
																</span>
															</div>
														)}
													</div>
												</>
											)}
										</div>
									</div>
								)}

								{ModalName == 'RENAME' && (
									<RenameFolderModal
										modalIsOpen={modalIsOpen}
										toggleModal={toggleModal}
										item={item}
										isFolder={true}
									/>
								)}
								{ModalName == 'CREATE_FOLDER' && (
									<CreateFolderModal
										modalIsOpen={modalIsOpen}
										toggleModal={toggleModal}
										item={item}
										title='Create Subfolder'
									/>
								)}
								{ModalName == 'DELETE_FOLDER' && (
									<DeleteModal
										isLoading={loading}
										modalIsOpen={modalIsOpen}
										toggleModal={toggleModal}
										ModalName={ModalName}
										item={item}
										isFolderDefault={isFolderDefault}
									/>
								)}
								{ModalName == 'ACCESS' && (
									<AccessModal
										modalIsOpen={modalIsOpen}
										toggleModal={toggleModal}
										ModalName={ModalName}
										item={item}
									/>
								)}
								{ModalName == 'ALREADY_EXISTS' && (
									<FileExistModal
										modalIsOpen={modalIsOpen}
										toggleModal={toggleModal}
									/>
								)}
								{ModalName == 'PROGRESS_BAR' && (
									<ProgressBarModal
										modalIsOpen={modalIsOpen}
										onClose={() => {
											toggleModal();
											dispatch(clientActions.resetUploadState());
										}}
										files={files}
									/>
								)}
							</div>
						</div>
						{children}
					</div>
				</label>
			</div>
		</>
	);
}
export default memo(DocumentFolder);
