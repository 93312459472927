import { Tooltip } from '@mui/material';
import { ReactComponent as InfoIcon } from '../../assets/icons/InfoIcon.svg';

function KPIComponent({ row, symbol }) {
	return (
		<div>
			<div
				style={{
					display: 'flex',
					backgroundColor: '#F7F9FA',
					height: '106px',
					width: '100%',
					borderRadius: '4px',
					boxShadow: '0px 8px 24px 0px rgba(149, 157, 165, 0.20)',
				}}
			>
				<div style={{ display: 'flex', marginLeft: '14px', marginTop: '14px' }}>
					<Tooltip
						title='The displayed data will update based on the chosen filters'
						placement='right-start'
					>
						<InfoIcon />
					</Tooltip>
				</div>
				<div
					className='d-flex align-items-center justify-content-around'
					style={{ marginInline: '150px', width: '1000px' }}
				>
					<div>
						<span className='HeadlineTwoBold'>Profit (Margin)</span>
						<div>
							<span className='BodyOnelight' style={{ color: row?.profit > 0 ? '#18c58d' : 'red' }}>
								{symbol}
								{row?.profit?.toFixed(2) || 0}{' '}
							</span>
							<span className='BodyOneLight'>
								{row?.revenue && row?.profit
									? ((row.profit / row.revenue) * 100).toFixed(2)
									: 0}
								%
							</span>
						</div>
					</div>
					<div>
						<span className='HeadlineTwoBold'>=</span>
					</div>
					<div>
						<span className='HeadlineTwoBold'>Revenue</span>
						<div>
							<span className='BodyOneLight'>
								{symbol}
								{row?.revenue.toFixed(2) || 0}
							</span>
						</div>
					</div>
					<div>
						<span className='HeadlineTwoBold'>-</span>
					</div>
					<div>
						<span className='HeadlineTwoBold'>Cost</span>
						<div>
							<span className='BodyOneLight'>
								{symbol}
								{row?.cost.toFixed(2) || 0}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default KPIComponent;
