import { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';

function PreviewRichText({ content, classNames = [] }) {
	const modules = {
		toolbar: false,
	};
	const quillRef = useRef(null);

	useEffect(() => {
		const container = quillRef.current?.editor?.container;
		if (container) {
			const editor = container.querySelector('.ql-editor');
			editor.classList.add(...(classNames || []));
			editor.style.padding = '0';

			container.style.backgroundColor = 'transparent';
		}
	}, [classNames]);

	return (
		<ReactQuill
			ref={quillRef}
			value={content}
			readOnly={true}
			modules={modules}
			className='w-100'
		/>
	);
}

export default PreviewRichText;
