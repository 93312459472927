import React from 'react';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import {
	TableItemWidth1,
	TableItemWidth3,
} from '../TableSpacing/TableSpacingStyles';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router';
import * as clientActions from '../../redux/actions/client-actions';
import useModal from '../Modal/useModal';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Modal, Tooltip } from '@mui/material';
import AddMyTaskModal from '../WorkSpace/Task Modal/AddMyTaskModal';
import TableRowItem from '../Others/TableRowItem';
import TableRowButton from '../Others/TableRowButton';
import { ReactComponent as TrashIcon } from '../../assets/icons/Trash-Light.svg';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import { useState } from 'react';
import DummyModal from '../Modal/DummyModal';
import EditWorkflowMyTaskModal from '../WorkSpace/Task Modal/EditWorkflowMyTaskModal';
import {
	convertDecimalToTime,
	truncateString,
} from '../../utilities/utilities';
import DeleteWorkflowMyTaskModal from './Modals/DeleteWorkflowMyTaskModal';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import TaskGuestsList from './TaskGuestsList';
import TaskDescriptionModal from './Modals/TaskDescriptionModal';
import ClientAdHocTask from './ClientAdHocTask';

function WorkflowMyTasksTable() {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const workflowMyTasks = useSelector(
		(state) => state.clientReducer.workflowMyTasks,
	);
	const form = useSelector((state) => state.workSpaceReducer.createTaskForm);
	const repeatTime = useSelector((state) => state.workSpaceReducer.repeatTime);
	const [myTaskDetails, setMyTaskDetails] = useState(null);
	const [deleteCurrentTask, setDeleteCurrentTasks] = useState(false);
	const cardAdded = useSelector((state) => state.workSpaceReducer.cardAdded);
	const teamMembers = useSelector((state) => state.teamReducer.teamMembers);
	const [expandModal, setExpandModal] = useState({
		value: '',
		title: '',
	});
	const users = useSelector((state) => state.workSpaceReducer.assignees);

	const submitForm = (taskCategoryType) => {
		let formDetails = { ...form, type: taskCategoryType };
		if (taskCategoryType === 'WORKFLOW') {
			formDetails = {
				...formDetails,
				metadata: {
					meeting_platform: form?.meeting_platform || null,
					meeting_link: form?.meeting_link || null,
					meeting_guests: form?.meeting_guests || [],
				},
				is_billable: formDetails?.is_billable == 1 ? true : false,
			};
			delete formDetails.meeting_platform;
			delete formDetails.meeting_link;
			delete formDetails.meeting_guests;
		}
		dispatch(WorkSpaceActions.createTask(formDetails, toggleModal, repeatTime));
	};

	const assignees = teamMembers
		?.filter((assignee) => assignee?.is_active == true)
		?.map((member) => {
			return {
				...member,
				full_name: member.name,
				id: member.user_id,
			};
		});

	const removeItem = (myTask) => {
		ModalType('CONFIRM_MODAL');
		toggleModal();
		setMyTaskDetails(myTask);
	};
	const removeMyTask = () => {
		dispatch(
			clientActions.deleteWorkflowMyTasks(
				myTaskDetails?.group_key,
				id,
				myTaskDetails?.id,
				deleteCurrentTask,
			),
		);
		setDeleteCurrentTasks(false);
	};

	useEffect(() => {
		dispatch(clientActions.getWorkflowMyTasks(id));
	}, [dispatch, cardAdded]);

	useEffect(() => {
		if (!modalIsOpen) {
			dispatch(WorkSpaceActions.resetCreateTaskForm());
		}
	}, [modalIsOpen]);

	return (
		<>
			{workflowMyTasks?.length < 1 ? (
				<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
					<h4 className='BodyTwoLight m-0 placeholder-text'>
						The Client has no My Tasks
					</h4>
				</div>
			) : (
				workflowMyTasks?.map((myTask, i) => {
					const taskAssignee = users?.find(
						(u) => u.id == myTask.assignee_user_id,
					);
					return (
						<ClientAdHocTask
							assignee_user={taskAssignee}
							key={myTask.id}
							task={myTask}
						/>
					);
				})
			)}
			{/* {edit && (
							<div
								className='d-flex justify-content-center information-header d-flex align-items-center'
								style={{ width: '100%' }}
							>
								<div
									className='pointer d-flex flex-row justify-content-start'
									style={{
										height: '22px',
										width: '6px',
										borderRadius: '3px',
										marginRight: '1rem',
										padding: 0,
										position: 'relative',
										// backgroundColor: '#fb7a03',
									}}
									onClick={() => {
										ModalType('ADD_MY_TASK_MODAL');
										toggleModal();
									}}
								>
									<AddIcon
										fontSize='small'
										sx={{
											color: '#fb7a03',
											fontSize: 21,
											position: 'absolute',
											margin: 0,
											padding: 0,
											top: 0,
											left: 0,
										}}
									/>
								</div>
								<h3
									className='BodyTwoLight mt-2 dark-text'
									style={{ color: '#FB7A03', cursor: 'pointer' }}
									onClick={() => {
										ModalType('ADD_MY_TASK_MODAL');
										toggleModal();
									}}
								>
									Add New Task
								</h3>
							</div>
						)} */}

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'ADD_MY_TASK_MODAL' && (
							<AddMyTaskModal
								clientId={id}
								onCancel={toggleModal}
								onSubmit={submitForm}
							/>
						)}
						{ModalName == 'EDIT_MY_TASK_MODAL' && (
							<EditWorkflowMyTaskModal
								clientId={id}
								onCancel={toggleModal}
								myTaskDetails={myTaskDetails}
								assignees={assignees}
							/>
						)}
						{ModalName == 'CONFIRM_MODAL' && (
							<DummyModal title={'Delete Task'} onClose={toggleModal}>
								<DeleteWorkflowMyTaskModal
									description={
										'You’re about to delete this task. Are you sure to want to continue?'
									}
									OnCancel={() => toggleModal()}
									Onsubmit={() => {
										toggleModal();
										removeMyTask();
									}}
									descriptionClassName='text-left'
									deleteCurrentTask={deleteCurrentTask}
									setDeleteCurrentTasks={setDeleteCurrentTasks}
								/>
							</DummyModal>
						)}
						{ModalName == 'MY_TASK_GUEST_MODAL' && (
							<TaskGuestsList
								guestList={myTaskDetails?.metadata?.meeting_guests}
								users={assignees}
								toggleModal={toggleModal}
							/>
						)}
						{ModalName == 'TASK_DESCRIPTION_MODAL' && (
							<DummyModal
								title={expandModal.title}
								onClose={toggleModal}
								height='400px'
							>
								<TaskDescriptionModal
									type={'text'}
									value={expandModal.value}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</>
	);
}

export default WorkflowMyTasksTable;
