import {
	CircularProgress,
	Paper,
	styled,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { ReactComponent as CloseIcon } from '../../assets/icons/Close.svg';
import LineGraph from '../sub_components/LineGraph';
import { useSelector } from 'react-redux';

const BaseStyledTableCell = styled(TableCell)({
	borderTop: '2px solid #FFFFFF',
	borderBottom: '2px solid #FFFFFF',
	height: '34px',
	padding: '8px 16px',
	fontFamily: 'Centra-No2',
	fontSize: '14px',
	fontStyle: 'normal',
	fontWeight: 300,
	lineHeight: '18px',
});

const StyledTableCell = styled(BaseStyledTableCell)({
	color: '#333333',
});

const StyledTableHeadCell = styled(BaseStyledTableCell)({
	color: '#696F6C',
	backgroundColor: '#F7F9FA',
});

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	backgroundColor: '#F7F9FA',
	'&:hover': {
		backgroundColor: theme.palette.action.hover,
	},
}));

const AnalyticsSlidingPanel = ({ isVisible, data, onClose, loading, currencySymbol }) => {
	const { workflowTableData, lineGraphData } = useSelector((state) => state.cubeReducer.data);

	return (
		<div
			style={{
				position: 'fixed',
				top: '40px',
				right: isVisible ? 0 : '-100%',
				width: '573px',
				height: '840px',
				backgroundColor: '#fff',
				marginRight: '12px',
				boxShadow: '0px 7px 29px 0px rgba(100, 100, 111, 0.20)',
				transition: 'right 0.3s ease-in-out',
				padding: '12px 20px 20px 20px',
				zIndex: 1000,
			}}
		>
			<div className='d-flex justify-content-between HeadlineTwoBold'>
				<h2>{data?.client}</h2>
				<CloseIcon onClick={onClose} className='pointer' />
			</div>
			<div
				className='d-flex w-100'
				style={{
					backgroundColor: '#EEF2F3',
					borderRadius: '4px',
					padding: '12px',
					marginTop: '12px',
				}}
			>
				<span className='HeadlineThreeBold'>Profitability By Workflow</span>
			</div>

			<TableContainer
				component={Paper}
				style={{ boxShadow: 'none', marginTop: '12px', maxHeight: '200px' }}
			>
				{loading ? (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							height: '200px',
						}}
					>
						<CircularProgress
							style={{
								color: '#fa7b03',
								height: '25px',
								width: '25px',
							}}
						/>
					</div>
				) : (
					<Table>
						<TableHead>
							<StyledTableRow>
								<StyledTableHeadCell>Workflow</StyledTableHeadCell>
								<StyledTableHeadCell>Revenue/Retainer</StyledTableHeadCell>
								<StyledTableHeadCell>Cost</StyledTableHeadCell>
								<StyledTableHeadCell>Profit</StyledTableHeadCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{(() => {
								let totalCost = 0;
								let totalRevenue = 0;
								let discountRevenue = 0;

								const rows = workflowTableData?.map((row, index) => {
									const revenue = parseFloat(row?.revenue || 0);
									const cost = parseFloat(row?.cost || 0);

									totalCost += cost;

									if (row?.workflowName.trim().toLowerCase() === 'discount') {
										discountRevenue += revenue;
									} else {
										totalRevenue += revenue;
									}

									return (
										<StyledTableRow key={row?.id || index} hover>
											<StyledTableCell>{row?.workflowName}</StyledTableCell>
											<StyledTableCell>
												{row?.workflowName.trim().toLowerCase() ===
												'discount'
													? `-${currencySymbol}${Math.abs(
															revenue,
													)?.toFixed(2)}`
													: `${currencySymbol}${revenue?.toFixed(2)}`}
											</StyledTableCell>
											<StyledTableCell>
												{row?.workflowName.trim().toLowerCase() ===
												'discount'
													? ''
													: `${currencySymbol}${cost?.toFixed(2)}`}
											</StyledTableCell>
											<StyledTableCell>
												{row?.workflowName.trim().toLowerCase() ===
												'discount'
													? ''
													: `${currencySymbol}${(revenue - cost)?.toFixed(
															2,
													)}`}
											</StyledTableCell>
										</StyledTableRow>
									);
								});

								const adjustedTotalRevenue =
									totalRevenue - Math.abs(discountRevenue);

								rows?.push(
									<StyledTableRow key='total' hover>
										<StyledTableCell>
											<b>Total</b>
										</StyledTableCell>
										<StyledTableCell>
											<b>
												{currencySymbol}
												{adjustedTotalRevenue?.toFixed(2)}
											</b>
										</StyledTableCell>
										<StyledTableCell>
											{currencySymbol}
											{totalCost?.toFixed(2)}
										</StyledTableCell>
										<StyledTableCell>
											<b>
												<span
													style={{
														color:
															adjustedTotalRevenue - totalCost >= 0
																? '#18c58d'
																: 'red',
													}}
												>
													{currencySymbol}
													{(adjustedTotalRevenue - totalCost)?.toFixed(2)}
												</span>
											</b>
										</StyledTableCell>
									</StyledTableRow>,
								);

								return rows;
							})()}
						</TableBody>
					</Table>
				)}
			</TableContainer>

			<div
				className='d-flex w-100'
				style={{
					backgroundColor: '#EEF2F3',
					borderRadius: '4px',
					padding: '12px',
					marginTop: '24px',
				}}
			>
				<span className='HeadlineThreeBold'>Profitability Overtime (YTD)</span>
			</div>
			{loading ? (
				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						height: '300px',
					}}
				>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '30px',
							width: '30px',
						}}
					/>
				</div>
			) : (
				lineGraphData?.length && <LineGraph lineGraphData={lineGraphData} />
			)}
		</div>
	);
};
export default AnalyticsSlidingPanel;
