/* eslint-disable no-mixed-spaces-and-tabs */
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
	convertDecimalToTime,
	executivePermissions,
	Member,
	standardPermissions,
} from '../../utilities/utilities';
import SkeletonLoading from '../Others/SkeletonLoading';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import TableRowItem from '../Others/TableRowItem';
import { TableItemWidth3, TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import HeadingTwo from '../TypoGraphy/HeadingTwo';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import { useLocation, useParams } from 'react-router';
import ExpandableTimeReportsTasksViewV2 from '../TimeReportsV2/ExpandableTimeReportsTasksViewV2';

function TimeReportsClientInfoV2({ clientData }) {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const start_date = searchParams.get('start_date');
	const end_date = searchParams.get('end_date');

	const loading = useSelector((state) => state.TimeReportsV2.loading);
	const loggedInUser = useSelector((state) => state.authReducer.user);
	const [selected, setSelected] = useState(false);
	const [workflowToExpand, setWorkflowExpand] = useState();
	const [userToEdit, setUserToEdit] = useState();
	const tasks = useSelector((state) => state.TimeReportsV2.tasks);

	const expandTasks = (type, client_workflow_id, user_id) => {
		setSelected(true);
		setUserToEdit(user_id);
		dispatch(TimeReportsActions.resetTasks());
		client_workflow_id !== null
			? setWorkflowExpand(client_workflow_id)
			: setWorkflowExpand(type);
		dispatch(
			TimeReportsActions.getTasksDetails(
				client_workflow_id,
				client_workflow_id == null ? id : null,
				user_id,
				type == 'meetings' ? true : false,
				start_date,
				end_date,
				true,
			),
		);
	};

	const totalValues = clientData?.users?.map((user) => {
		const budgeted_hours =
			parseFloat(
				user?.workflows?.reduce((acc, workflow) => acc + workflow?.budgeted_hours, 0),
			) +
			parseFloat(
				user?.workflow_my_tasks?.reduce(
					(acc, workflow_my_task) => acc + workflow_my_task?.budgeted_hours,
					0,
				),
			) +
			parseFloat(user?.meetings?.reduce((acc, meeting) => acc + meeting?.budgeted_hours, 0));

		const consumed_time =
			parseFloat(
				user?.workflows?.reduce(
					(acc, workflow) =>
						acc + (!workflow?.consumed_time ? 0 : parseFloat(workflow?.consumed_time)),
					0,
				),
			) +
			parseFloat(
				user?.workflow_my_tasks?.reduce(
					(acc, workflow_my_task) =>
						acc +
						(!workflow_my_task?.consumed_time
							? 0
							: parseFloat(workflow_my_task?.consumed_time)),
					0,
				),
			) +
			parseFloat(
				user?.meetings?.reduce(
					(acc, meeting) =>
						acc + (!meeting?.consumed_time ? 0 : parseFloat(meeting?.consumed_time)),
					0,
				),
			);

		return {
			userId: user?.user_id,
			totalBudgetedHours: convertDecimalToTime(budgeted_hours),
			totalConsumedTime: convertDecimalToTime(consumed_time),
			totalUtilizationHours: convertDecimalToTime(budgeted_hours - consumed_time),
		};
	});

	useEffect(() => {
		setSelected(false);
	}, [start_date, end_date]);

	return (
		<div>
			{clientData?.users?.filter(
				(user) =>
					user?.workflows?.length > 0 ||
					user?.workflow_my_tasks?.length > 0 ||
					user?.meetings?.length > 0,
			)?.length < 1 ? (
				<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
					<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
						<h4 className='BodyTwoLight'>No Users</h4>
					</div>
				</SkeletonLoading>
			) : (
				clientData?.users
					?.filter(
						(user) =>
							user?.workflows?.length > 0 ||
							user?.workflow_my_tasks?.length > 0 ||
							user?.meetings?.length > 0,
					)
					?.map((user) => {
						const loggedInUser_role = loggedInUser?.user_roles?.find((item) => {
							return item?.role_scopes
								?.map((role) => role.team_id)
								?.includes(clientData.team_id);
						})?.role_id;

						let isAllowed = false;
						if (
							loggedInUser?.userRole?.id === Member.ownerId ||
							loggedInUser?.userRole?.id === Member.superAdminId
						) {
							isAllowed = true;
						} else if (user?.user_roles?.scopes?.length < 1) {
							if (loggedInUser_role == Member.supervisorId) {
								isAllowed = true;
							} else if (loggedInUser_role == Member.teamManagerId) {
								if (user.role_id !== Member.ownerId) {
									isAllowed = true;
								} else {
									isAllowed = false;
								}
							}
						} else {
							user?.user_roles?.scopes?.some((scope) => {
								const roleFound = loggedInUser.user_roles?.find((item) =>
									item.role_scopes?.some(
										(role) => role?.team_id === scope?.team_id,
									),
								);
								const logged_in_role = roleFound?.role_id;
								const user_role = scope?.role_id;

								if (logged_in_role == Member.supervisorId) {
									isAllowed = true;
								} else if (logged_in_role == Member.teamManagerId) {
									if (user?.user_id == loggedInUser?.userId) {
										isAllowed = true;
									} else if (
										user_role == Member.teamMemberId ||
										user_role == Member.externalCollaborator
									) {
										isAllowed = true;
									} else {
										isAllowed = false;
									}
								} else if (
									(logged_in_role == Member?.teamMemberId ||
										logged_in_role == Member?.externalCollaborator) &&
									loggedInUser?.userId == user?.user_id
								) {
									isAllowed = true;
								} else {
									isAllowed = false;
								}
							});
						}

						return (
							<div id={user?.user_id} key={user?.user_id}>
								<Box
									sx={{
										backgroundColor: '#FCFDFD',
										width: '100%',
										padding: '20px 18px',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'space-between',
										gap: '1.5em',
										boxSizing: 'border-box',
										borderRadius: '4px 4px 0px 0px',
									}}
								>
									<SkeletonLoading
										loading={loading}
										variant='text'
										width='25%'
										height={45}
										key={user.user_id}
									>
										<Box>
											<HeadingTwo>{user?.full_name}</HeadingTwo>{' '}
										</Box>
									</SkeletonLoading>
								</Box>
								<div className='user-section-body'>
									<div className='team-members-body'>
										<SkeletonLoading
											loading={loading}
											variant='text'
											width='100%'
											height={45}
										>
											<TableHeader
												ClassName='new-table-header'
												border={false}
											>
												<TableHeaderItem
													labelClass='BodyTwoLight'
													label='Workflow'
													width={TableItemWidth5}
												/>
												<TableHeaderItem
													labelClass='BodyTwoLight'
													label='Budgeted Hours'
													width={TableItemWidth5}
												/>
												<TableHeaderItem
													labelClass='BodyTwoLight'
													label='Logged Time'
													width={TableItemWidth5}
												/>
												<TableHeaderItem
													labelClass='BodyTwoLight'
													label='Capacity'
													width={TableItemWidth5}
												/>
												<TableHeaderItem
													labelClass='BodyTwoLight'
													label=''
													width={TableItemWidth3}
												/>
											</TableHeader>
										</SkeletonLoading>
										<div className='ListingLoading scroll'>
											{user?.workflows?.length == 0 &&
											user?.workflow_my_tasks?.length == 0 &&
											user?.meetings?.length == 0 ? (
												<SkeletonLoading
													loading={loading}
													variant='text'
													width='100%'
													height={45}
												>
													<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
														<h4 className='BodyTwoLight'>
															No Workflows
														</h4>
													</div>
												</SkeletonLoading>
											) : (
												user?.workflows?.map((workflow, index) => {
													return (
														<SkeletonLoading
															loading={loading}
															variant='text'
															width='100%'
															height={45}
															key={index}
														>
															<div className='new-table-row d-flex align-items-center'>
																<TableRowItem
																	ClassName='text-left'
																	textClass='BodyTwoLight'
																	label={workflow.display_name}
																	width={TableItemWidth5}
																	input={false}
																/>

																<TableRowItem
																	ClassName='text-left'
																	textClass='BodyTwoLight'
																	label={
																		!workflow.budgeted_hours
																			? convertDecimalToTime(
																					0,
																			  )
																			: convertDecimalToTime(
																					workflow.budgeted_hours,
																			  )
																	}
																	width={TableItemWidth5}
																	input={false}
																/>
																<TableRowItem
																	ClassName='text-left'
																	textClass='BodyTwoLight'
																	label={
																		!workflow.consumed_time
																			? convertDecimalToTime(
																					0,
																			  )
																			: convertDecimalToTime(
																					workflow.consumed_time,
																			  )
																	}
																	width={TableItemWidth5}
																	input={false}
																/>
																<TableRowItem
																	ClassName='text-left'
																	textClass={`BodyTwoLight ${
																		workflow.budgeted_hours -
																			workflow.consumed_time <
																		0
																			? 'red-text'
																			: ''
																	}`}
																	label={convertDecimalToTime(
																		(!workflow.budgeted_hours
																			? 0
																			: workflow.budgeted_hours) -
																			(!workflow.consumed_time
																				? 0
																				: workflow.consumed_time),
																	)}
																	width={TableItemWidth5}
																	input={false}
																	toolTipText='Budgeted Hours - Logged Hours'
																/>
																<TableRowItem
																	className='text-right'
																	label=''
																	input={true}
																	width={TableItemWidth3}
																>
																	<div className='pointer'>
																		{selected &&
																		userToEdit ==
																			user.user_id &&
																		workflowToExpand ==
																			workflow?.client_workflow_id ? (
																			<span
																				onClick={() =>
																					setSelected(
																						false,
																					)
																				}
																			>
																				<ArrowUp />
																			</span>
																		) : (
																			<span
																				onClick={() =>
																					expandTasks(
																						'workflow',
																						workflow?.client_workflow_id,
																						user.user_id,
																					)
																				}
																			>
																				<ArrowDown />
																			</span>
																		)}
																	</div>
																</TableRowItem>
															</div>
															{selected &&
																userToEdit == user.user_id &&
																workflowToExpand ==
																	workflow?.client_workflow_id && (
																	<ExpandableTimeReportsTasksViewV2
																		tasks={tasks}
																		isAllowed={isAllowed}
																		userId={user?.user_id}
																	/>
																)}
														</SkeletonLoading>
													);
												})
											)}
											{user?.workflow_my_tasks?.length > 0 &&
												user?.workflow_my_tasks?.map(
													(workflow_my_task, index) => {
														return (
															<SkeletonLoading
																loading={loading}
																variant='text'
																width='100%'
																height={45}
																key={index}
															>
																<div className='new-table-row d-flex align-items-center'>
																	<TableRowItem
																		ClassName='text-left'
																		textClass='BodyTwoLight'
																		label={'Ad hoc Tasks'}
																		width={TableItemWidth5}
																		input={false}
																	/>

																	<TableRowItem
																		ClassName='text-left'
																		textClass='BodyTwoLight'
																		label={convertDecimalToTime(
																			workflow_my_task.budgeted_hours,
																		)}
																		width={TableItemWidth5}
																		input={false}
																	/>
																	<TableRowItem
																		ClassName='text-left'
																		textClass='BodyTwoLight'
																		label={convertDecimalToTime(
																			workflow_my_task.consumed_time,
																		)}
																		width={TableItemWidth5}
																		input={false}
																	/>
																	<TableRowItem
																		ClassName='text-left'
																		textClass={`BodyTwoLight ${
																			workflow_my_task.budgeted_hours -
																				workflow_my_task.consumed_time <
																			0
																				? 'red-text'
																				: ''
																		}`}
																		label={convertDecimalToTime(
																			(!workflow_my_task.budgeted_hours
																				? 0
																				: workflow_my_task.budgeted_hours) -
																				(!workflow_my_task.consumed_time
																					? 0
																					: workflow_my_task.consumed_time),
																		)}
																		width={TableItemWidth5}
																		input={false}
																		toolTipText='Budgeted Hours - Logged Hours'
																	/>
																	<TableRowItem
																		className='text-right'
																		label=''
																		input={true}
																		width={TableItemWidth3}
																	>
																		<div className='pointer'>
																			{selected &&
																			userToEdit ==
																				user.user_id &&
																			workflowToExpand ==
																				'workflow_my_tasks' ? (
																				<span
																					onClick={() =>
																						setSelected(
																							false,
																						)
																					}
																				>
																					<ArrowUp />
																				</span>
																			) : (
																				<span
																					onClick={() =>
																						expandTasks(
																							'workflow_my_tasks',
																							null,
																							user.user_id,
																						)
																					}
																				>
																					<ArrowDown />
																				</span>
																			)}
																		</div>
																	</TableRowItem>
																</div>
																{selected &&
																	workflowToExpand ==
																		'workflow_my_tasks' &&
																	userToEdit == user.user_id && (
																		<ExpandableTimeReportsTasksViewV2
																			tasks={tasks}
																			isAllowed={isAllowed}
																			userId={user.user_id}
																		/>
																	)}
															</SkeletonLoading>
														);
													},
												)}
											{user?.meetings?.length > 0 &&
												user?.meetings?.map((meeting, index) => {
													return (
														<SkeletonLoading
															loading={loading}
															variant='text'
															width='100%'
															height={45}
															key={index}
														>
															<div className='new-table-row d-flex align-items-center'>
																<TableRowItem
																	ClassName='text-left'
																	textClass='BodyTwoLight'
																	label={'Meetings'}
																	width={TableItemWidth5}
																	input={false}
																/>

																<TableRowItem
																	ClassName='text-left'
																	textClass='BodyTwoLight'
																	label={convertDecimalToTime(
																		meeting.budgeted_hours,
																	)}
																	width={TableItemWidth5}
																	input={false}
																/>
																<TableRowItem
																	ClassName='text-left'
																	textClass='BodyTwoLight'
																	label={convertDecimalToTime(
																		meeting.consumed_time,
																	)}
																	width={TableItemWidth5}
																	input={false}
																/>
																<TableRowItem
																	ClassName='text-left'
																	textClass={`BodyTwoLight ${
																		meeting.budgeted_hours -
																			meeting.consumed_time <
																		0
																			? 'red-text'
																			: ''
																	}`}
																	label={convertDecimalToTime(
																		(!meeting.budgeted_hours
																			? 0
																			: meeting.budgeted_hours) -
																			(!meeting.consumed_time
																				? 0
																				: meeting.consumed_time),
																	)}
																	width={TableItemWidth5}
																	input={false}
																	toolTipText='Budgeted Hours - Logged Hours'
																/>
																<TableRowItem
																	className='text-right'
																	label=''
																	input={true}
																	width={TableItemWidth3}
																>
																	<div className='pointer'>
																		{selected &&
																		userToEdit ==
																			user.user_id &&
																		workflowToExpand ==
																			'meetings' ? (
																			<span
																				onClick={() =>
																					setSelected(
																						false,
																					)
																				}
																			>
																				<ArrowUp />
																			</span>
																		) : (
																			<span
																				onClick={() =>
																					expandTasks(
																						'meetings',
																						null,
																						user.user_id,
																					)
																				}
																			>
																				<ArrowDown />
																			</span>
																		)}
																	</div>
																</TableRowItem>
															</div>
															{selected &&
																workflowToExpand == 'meetings' &&
																userToEdit == user.user_id && (
																	<ExpandableTimeReportsTasksViewV2
																		tasks={tasks}
																		isAllowed={isAllowed}
																		userId={user.user_id}
																		clientInfo={true}
																	/>
																)}
														</SkeletonLoading>
													);
												})}

											{(user?.workflows?.length > 0 ||
												user?.workflow_my_tasks?.length > 0 ||
												user?.meetings?.length > 0) && (
												<SkeletonLoading
													loading={loading}
													variant='text'
													width='100%'
													height={45}
												>
													<div className='new-table-row d-flex align-items-center'>
														<TableRowItem
															ClassName='text-left'
															textClass='BodyTwoLight'
															width={TableItemWidth5}
															input={false}
														/>
														<TableRowItem
															ClassName='text-left'
															textClass='BodyTwoBold'
															label={
																totalValues?.find(
																	(item) =>
																		item.userId ===
																		user.user_id,
																)?.totalBudgetedHours
															}
															width={TableItemWidth5}
															input={false}
														/>
														<TableRowItem
															ClassName='text-left'
															textClass='BodyTwoBold'
															label={
																totalValues?.find(
																	(item) =>
																		item.userId ===
																		user.user_id,
																)?.totalConsumedTime
															}
															width={TableItemWidth5}
															input={false}
														/>
														<TableRowItem
															ClassName='text-left'
															textClass={`BodyTwoBold ${
																totalValues?.find(
																	(item) =>
																		item.userId ===
																		user.user_id,
																)?.totalUtilizationHours < 0
																	? 'red-text'
																	: ''
															}`}
															label={
																totalValues?.find(
																	(item) =>
																		item.userId ===
																		user.user_id,
																)?.totalUtilizationHours
															}
															width={TableItemWidth5}
															input={false}
														/>
														<TableRowItem
															ClassName='text-left'
															textClass='BodyTwoLight'
															width={TableItemWidth3}
															input={false}
														/>
													</div>
												</SkeletonLoading>
											)}
										</div>
									</div>
								</div>
							</div>
						);
					})
			)}
		</div>
	);
}

export default TimeReportsClientInfoV2;
