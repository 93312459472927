import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CircularProgress } from '@mui/material';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const NewBarGraph = ({ data, currencySymbol = '', loading }) => {

    const isValidData = data && Array.isArray(data.datasets) && data.datasets.every(ds => Array.isArray(ds.data));

    const chartData = {
        labels: isValidData ? data.labels : [],
        datasets: isValidData
            ? data.datasets.map((dataset, index) => ({
                  label: dataset.label || `Dataset ${index + 1}`,
                  data: dataset.data || [],
                  backgroundColor: ['#B2DF8A', '#1F78B4', '#F2421B'][index % 3],
              }))
            : [],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        
        plugins: {
            legend: {
                position: 'bottom',
            },
            tooltip: {
                mode: 'index',
                intersect: false,
            },
            datalabels: {
                display: true,
                color: 'black',
                anchor: 'end',
                align: 'top',
                formatter: function (value) {
                    return currencySymbol + value;
                },
                font: {
                    weight: 'normal',
                },
            },
        },
        layout: {
            padding: {
                top: 30, 
            },
        },
        scales: {
            x: {
                stacked: false,
                beginAtZero: true,
                ticks: {
                    autoSkip: false,
                    maxRotation: 0,
                    minRotation: 0,
                },
            },
            y: {
                stacked: false,
                beginAtZero: false,
                ticks: {
                    callback: function (value) {
                        return currencySymbol + value;
                    },
                },
                min: isValidData
                    ? Math.min(
                          ...data.datasets.flatMap((dataset) => dataset.data || [0])
                      ) < 0
                        ? Math.min(
                              ...data.datasets.flatMap((dataset) => dataset.data || [0])
                          )
                        : 0
                    : 0,
            },
        },
    };

    const calculateContainerWidth = (dataLength) => {
        if (!dataLength || dataLength <= 10) {
            return '100%';
        } else if (dataLength > 20) {
            return `${1000 + (dataLength - 20) * 100}px`;
        } else {
            return '2000px';
        }
    };

    const containerWidth = isValidData
        ? calculateContainerWidth(data.datasets[0]?.data?.length || 0)
        : '100%';

    return (
        <div style={{ height: 400, overflowX: 'auto' }}>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '350px',
                    }}
                >
                    <CircularProgress
                        style={{
                            color: '#fa7b03',
                            height: '50px',
                            width: '50px',
                        }}
                    />
                </div>
            ) : (
                <div style={{ width: containerWidth, height: '350px' }}>
                    <Bar data={chartData} options={options} />
                </div>
            )}
        </div>
    );
};

export default NewBarGraph;
