/* eslint-disable no-mixed-spaces-and-tabs */
import { Modal, Tooltip } from '@mui/material';
import { ReactComponent as RecurrenceIcon } from '../../assets/icons/ArrowsCounterClockwise.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';

import { ReactComponent as CheckIcon } from '../../assets/icons/CheckCircle.svg';
import { ReactComponent as OccurrenceIcon } from '../../assets/icons/Occurrences.svg';
import { ReactComponent as CalenderIcon } from '../../assets/icons/CalendarBlank.svg';
import { ReactComponent as RepeatIcon } from '../../assets/icons/Repeat.svg';

import { memo } from 'react';
import {
	REPEAT_TYPE,
	ordinal_suffix_of,
	standardPermissions,
	weekOfMonth,
} from '../../utilities/utilities';
import moment from 'moment';
import { getWeeksInMonth } from 'date-fns/esm';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import RecurrenceModal from './Modals/RecurrenceModal';
import Restricted from '../Permissions/Restricted';
import { useSelector } from 'react-redux';
import { getUserSettings } from '../../hooks/getUserSettings';

function ClientWorkflowRecurrence({
	draft,
	client_workflow_id,
	repeat_type,
	repeat_interval,
	start_date,
	recur_preference,
	is_monthly_on_day,
	required_days,
	recurrence_end_on_type,
	recurrence_end_on_occurrence,
	recurrence_end_on_end_date,
	recurring_days,
	schedule_adjustment_action,
	user_team_role,
}) {
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const onReccurenceEdit = () => {
		ModalType('RECURRENCE_MODAL');
		toggleModal();
	};

	return (
		<div className='client-workflow-recurrence'>
			<div className='recurrence-header'>
				<div className='w-100 text-left d-flex align-items-center justify-content-between'>
					<span className='d-flex' style={{ gap: '12px' }}>
						<RecurrenceIcon />
						<h3 className='HeadlineThreeBold'>Recurrence</h3>
					</span>

					<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
						<Tooltip title='Edit Recurrence'>
							<EditIcon
								style={{
									color: '#fb7a03',
									cursor: 'pointer',
								}}
								onClick={onReccurenceEdit}
							/>
						</Tooltip>
					</Restricted>
				</div>

				{repeat_type ? (
					<p className='text-left BodyTwoLight' style={{ margin: 0, padding: 0 }}>
						This workflow will recur on
						{/* For Daily Recurrence */}
						{repeat_type === REPEAT_TYPE.DAILY &&
							` every ${recurring_days?.map((day) => ` ${day.slice(0, 3)}`)}`}
						{/* For Weekly Recurrence */}
						{repeat_type === REPEAT_TYPE.WEEKLY &&
							` ${moment(start_date).format('dddd')} of every ${
								repeat_interval != 1 ? ordinal_suffix_of(repeat_interval) : ''
							} week `}
						{/* For Monthly Recurrence */}
						{repeat_type === REPEAT_TYPE.MONTHLY && (
							<>
								{is_monthly_on_day
									? ` ${ordinal_suffix_of(
											moment(start_date).format('D'),
									  )} of every ${
											repeat_interval != 1
												? ordinal_suffix_of(repeat_interval)
												: ''
									  } month`
									: recur_preference
									? `
                                ${recur_preference === 1 ? '4th' : 'last'}
                                ${moment(start_date).format('dddd')} of every ${
											repeat_interval != 1
												? ordinal_suffix_of(repeat_interval)
												: ''
									  } month`
									: ` ${
											getWeeksInMonth(repeat_interval) ==
											weekOfMonth(moment(start_date))
												? 'last'
												: ordinal_suffix_of(weekOfMonth(moment(start_date)))
									  } ${moment(start_date).format('dddd')} of every ${
											repeat_interval != 1
												? ordinal_suffix_of(repeat_interval)
												: ''
									  } month`}
							</>
						)}
						{/* For Yearly Recurrence */}
						{repeat_type === REPEAT_TYPE.YEARLY &&
							` ${moment(start_date).format('MMMM DD')} of every ${
								repeat_interval != 1 ? ordinal_suffix_of(repeat_interval) : ''
							}  year`}
					</p>
				) : (
					<p
						className='text-left BodyTwoLight light-text'
						style={{ margin: 0, padding: 0 }}
					>
						Please set recurrence before activating
					</p>
				)}
			</div>

			<div className='recurrence-info'>
				<span
					className='d-flex align-items-center justify-content-center'
					style={{ gap: '8px' }}
				>
					<RepeatIcon />
					<span className='BodyTwoBold pt-1'>Repeats:</span>
					<span className='BodyTwoLight pt-1'>
						{repeat_type === REPEAT_TYPE.DAILY &&
							` Every ${
								recurring_days?.map((day) => ` ${day.slice(0, 3)}`)
							}`}
						{repeat_type === REPEAT_TYPE.WEEKLY &&
							`  Every ${
								repeat_interval != 1 ? ordinal_suffix_of(repeat_interval) : ''
							} week `}
						{repeat_type === REPEAT_TYPE.MONTHLY && (
							<>
								{is_monthly_on_day
									? `Every ${
											repeat_interval != 1
												? ordinal_suffix_of(repeat_interval)
												: ''
									  } month`
									: recur_preference
									? `
                                 Every ${
										repeat_interval != 1
											? ordinal_suffix_of(repeat_interval)
											: ''
									} month`
									: `  Every ${
											repeat_interval != 1
												? ordinal_suffix_of(repeat_interval)
												: ''
									  } month`}
							</>
						)}

						{repeat_type === REPEAT_TYPE.YEARLY &&
							`Every  ${
								repeat_interval != 1 ? ordinal_suffix_of(repeat_interval) : ''
							}  year`}
					</span>
				</span>
				<span
					className='d-flex align-items-center justify-content-center'
					style={{ gap: '8px' }}
				>
					<CalenderIcon />
					<span className='BodyTwoBold pt-1'>Starting on:</span>
					<span className='BodyTwoLight pt-1'>
						{moment(start_date).format(date_format)}
					</span>
				</span>
				<span
					className='d-flex align-items-center justify-content-center'
					style={{ gap: '8px' }}
				>
					<OccurrenceIcon />
					<span className='BodyTwoBold pt-1'>
						{recurrence_end_on_type === 'occurrence' ? 'Occurrences' : 'End on'}:
					</span>
					<span className='BodyTwoLight pt-1'>
						{recurrence_end_on_type === 'occurrence' && recurrence_end_on_occurrence}
						{recurrence_end_on_type === 'end_date' &&
							moment(recurrence_end_on_end_date).format(date_format)}
						{recurrence_end_on_type === 'never' && 'Never'}
					</span>
				</span>
				<span
					className='d-flex align-items-center justify-content-center'
					style={{ gap: '8px' }}
				>
					<CheckIcon />
					<span className='BodyTwoBold pt-1'>Days to Complete:</span>
					<span className='BodyTwoLight pt-1'>{required_days}</span>
				</span>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'RECURRENCE_MODAL' && (
							<DummyModal title={'Workflow Recurrence Details'} onClose={toggleModal}>
								<RecurrenceModal
									recurrence={{
										id: client_workflow_id,
										repeat_type: repeat_type,
										repeat_interval: repeat_interval,
										start_date: start_date,
										is_monthly_on_day: is_monthly_on_day || false,
										recur_preference: recur_preference || null,
										end_on: {
											type: recurrence_end_on_type,
											occurrence: recurrence_end_on_occurrence,
											end_date: recurrence_end_on_end_date,
										},
										required_days: required_days,
										schedule_adjustment_action,
										recurring_days,
									}}
									onCancel={toggleModal}
									draft={draft}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(ClientWorkflowRecurrence);
