import React, { memo, useCallback, useEffect, useRef } from 'react';
import Sidebar from '../sidebar/SideBar';
import SectionHeader from '../sidebar/SectionHeader';
import Filter from '../sidebar/Filter';
import Restricted from '../Permissions/Restricted';
import { useDispatch, useSelector } from 'react-redux';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import { generateUniqueId, Member, standardPermissions } from '../../utilities/utilities';
import { routes } from '../../utilities/routes';
import { useHistory } from 'react-router';
import moment from 'moment';
import ToggleButton from '../Inputs/ToggleButton';
import SavedFilters from '../sidebar/SavedFilters';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { Modal } from '@mui/material';
import SaveWorkspaceFilterModal from './SaveWorkspaceFilterModal';
import useModal from '../Modal/useModal';
import ConfirmationModal from '../Modal/ConfirmationModal';

const modalNames = {
    SAVE_FILTER_MODAL: 'SAVE_FILTER_MODAL',
    DELETE_FILTER_CONFIRM_MODAL: 'DELETE_FILTER_CONFIRM_MODAL',
};

const WorkspaceSidebar = ({ monthlyView, setModalIsOpen, SetVewSideBar }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.user);
    const filters = useSelector((state) => state.workSpaceReducer.filters);
    const weekDaysOff = useSelector((state) => state.workSpaceReducer.weekDaysOff);
    const showTeamMembersMeetings = useSelector(
        (state) => state.workSpaceReducer.showTeamMembersMeetings,
    );
    const userStatus = useSelector((state) => state.authReducer.userStatus);
    const history = useHistory();
    const utcTime = moment().utc();
    const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal(
        undefined,
        undefined,
        undefined,
        modalNames.SAVE_FILTER_MODAL,
    );
    const [filterToPerformActionOn, setFilterToPerformActionOn] = React.useState(null);
    const ModalRef = useRef();

    const appliedFiltersStorage = useLocalStorage('workspace_applied_filters');
    const storage = useLocalStorage('workspace_filters_v1');
    const storedFilters = storage.getLocalStorage() || {};
    const loggedInUserFilters = storedFilters[user?.userId] || [];

    const toggleWeekends = useCallback(() => {
        localStorage.setItem('weekend', !weekDaysOff);
        dispatch(WorkSpaceActions.setWeekend(!weekDaysOff));
    }, [weekDaysOff]);

    const toggleInitialView = useCallback(() => {
        dispatch(WorkSpaceActions.setMonthlyView(!monthlyView));
        dispatch(WorkSpaceActions.resetWorkspaceTasks());
        localStorage.setItem('monthly', !monthlyView);
        !monthlyView && SetVewSideBar(false);
    }, [monthlyView]);

    const toggleTeamMeetings = () => {
        dispatch(WorkSpaceActions.toggleTeamMeetings(!showTeamMembersMeetings));
    };

    const saveFilter = (filterName, newfilters, filterId = undefined) => {
        if (filterId) {
            const newStoredFilters = loggedInUserFilters?.map((item) => {
                if (item.id == filterId) {
                    return {
                        ...item,
                        name: filterName,
                        filters: newfilters,
                    };
                }
                return item;
            });
            storage.setLocalStorage({ ...storedFilters, [user.userId]: [...newStoredFilters] });
            dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, 'Filter updated'));
        } else {
            const id = generateUniqueId();
            const filterToStore = {
                filters: newfilters,
                id: id,
                name: filterName,
            };
            storage.setLocalStorage({
                ...storedFilters,
                [user.userId]: [...loggedInUserFilters, filterToStore],
            });
            dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, 'Filter saved'));
        }

        changeModalState();
    };

    const onSaveFilters = () => {
        if (loggedInUserFilters?.length == 10) {
            dispatch(
                WorkSpaceActions.setSnackBarIsOpen(
                    false,
                    true,
                    'Max Limit for custom filters 10 is reached. Please edit or delete existing filters',
                ),
            );
            return;
        }

        changeModalState();
    };

    const deleteFilter = () => {
        const newStoredFilters = loggedInUserFilters?.filter(
            (item) => item.id != filterToPerformActionOn?.id,
        );
        storage.setLocalStorage({ ...storedFilters, [user?.userId]: newStoredFilters });
        dispatch(WorkSpaceActions.setSnackBarIsOpen(false, true, 'Filter deleted'));

        changeModalState();
    };

    const applySavedFilter = (event) => {
        const { checked, value } = event.target;

        if (checked) {
            const filter = loggedInUserFilters.find((item) => item.id === value);
            dispatch(WorkSpaceActions.setFilters(filter.filters));
        } else {
            dispatch(WorkSpaceActions.setFilters());
        }
    };

    const onEditFilter = (selectedFilter) => () => {
        setFilterToPerformActionOn(selectedFilter);
        changeModalState();
    };

    const onDeleteFilter = (selectedFilter) => () => {
        setFilterToPerformActionOn(selectedFilter);
        changeModalState(modalNames.DELETE_FILTER_CONFIRM_MODAL);
    };

    const changeModalState = (modal = undefined) => {
        ModalType(modal || modalNames.SAVE_FILTER_MODAL);
        if (modalIsOpen) setFilterToPerformActionOn(null);
        toggleModal();
    };

    const clearAllFilters = () => {
        dispatch(WorkSpaceActions.setFilters());
    };

    let teams = useSelector((state) => state.workSpaceReducer.workspaceTeams);

    let filteredClients = useSelector((state) => state.workSpaceReducer.filteredWorkSpace?.clients);

    let filteredUsers = useSelector((state) => state.workSpaceReducer.filteredWorkSpace?.users);
    let filteredtaskStatuses = useSelector(
        (state) => state.workSpaceReducer.filteredWorkSpace?.taskStatuses,
    );
    let filteredWorkflows = useSelector((state) => state.workSpaceReducer.filteredWorkflows);
    if (filteredtaskStatuses) {
        filteredtaskStatuses = Object.entries(filteredtaskStatuses).map((obj) => ({
            value: obj[0],
            label:
                obj[1].charAt(0).toUpperCase() +
                obj[1]
                    .slice(1)
                    .toLowerCase()
                    .replace(/[^a-zA-Z ]/g, ' '),
            is_checked: filters?.taskStatuses?.includes(obj[0]),
        }));
    }
    if (filteredClients?.length > 0) {
        filteredClients = filteredClients
            ?.filter((client) => client.deleted !== true)
            ?.map((item) => {
                return {
                    ...item,
                    displayName: item?.dba?.trim() == '' ? item?.company_name : item?.dba,
                };
            })
            ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
            ?.map((filtered) => {
                return {
                    value: filtered.id,
                    label: filtered?.displayName,
                    is_checked: filters?.clients?.includes(filtered.id),
                };
            });
    }

    if (filteredWorkflows?.length > 0) {
        filteredWorkflows = filteredWorkflows.map((filtered) => {
            return {
                value: filtered.workflow_id,
                label: filtered?.display_name,
                is_checked: filters?.workflows?.includes(filtered.workflow_id),
            };
        });
    }

    if (filteredUsers?.length > 0) {
        filteredUsers = filteredUsers.map((filtered) => {
            const userAffected = userStatus?.users?.find((user) => user?.user_id == filtered?.id);
            return {
                value: filtered.id,
                label: filtered.full_name,
                is_checked: filters?.users?.includes(filtered.id),
                image: filtered.image_url,
                leave_requests: filtered.leave_requests,
                user_statuses: filtered.user_statuses,
                is_active: filtered.is_active,
                is_online:
                    user?.tenantId === userStatus?.tenant_id &&
                    userAffected &&
                    Math.abs(userAffected?.timeStamp?.diff(utcTime, 'seconds')) <= 60,
            };
        });
    }

    if (teams?.length > 0) {
        teams = teams.map((filtered) => {
            return {
                value: filtered.id,
                label: filtered.name,
                is_checked: filters?.teams?.includes(filtered.id),
            };
        });
    }

    const teamProfileNavigation = (teamId) => {
        const from = location.state || [];
        from.push({
            link: location.pathname,
            label: 'Workspace',
        });
        if (from.length > 2) from.shift();
        user?.user_roles?.map((item) => item.role_id)?.includes(Member.externalCollaborator)
            ? null
            : history.push(`${routes['team-profile']}${teamId}`, from);
    };

    const userProfileNavigation = (userId) => {
        const from = location.state || [];
        from.push({
            link: location.pathname,
            label: 'Workspace',
        });
        if (from.length > 2) from.shift();
        user?.userId == userId
            ? history.push(`${routes['account-settings']}`, from)
            : history.push(`${routes['user-profile']}${userId}`, from);
    };

    const clientProfileNavigation = (clientId) => {
        const from = location.state || [];
        from.push({
            link: location.pathname,
            label: 'Workspace',
        });
        if (from.length > 2) from.shift();
        history.push(`${routes['client-profile']}${clientId}`, from);
    };

    useEffect(() => {
        appliedFiltersStorage.setLocalStorage(filters);
    }, [filters]);
    return (
        <Sidebar title='My Workspace'>
            <div className='SidebarContentWrapper'>
                <div className='User-filters'>
                    <div className='SidebarListWrapper'>
                        <div className='FilterSpacing-1'>
                            <SectionHeader title='Explore' />

                            <div className='TeamMultiPickList w-100'>
                                <div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center w-100'>
                                    <div className='w-100 d-flex align-items-center justify-content-between'>
                                        <label className='BodyTwoLight white-text text-left m-0'>
                                            View Monthly Calendar
                                        </label>
                                        <ToggleButton
                                            checked={monthlyView}
                                            onChange={toggleInitialView}
                                        />
                                    </div>
                                </div>

                                <div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center'>
                                    <div className='w-100 d-flex align-items-center justify-content-between'>
                                        <label className='BodyTwoLight white-text text-left m-0 '>
                                            Enable Weekend
                                        </label>
                                        <ToggleButton
                                            checked={weekDaysOff}
                                            onChange={toggleWeekends}
                                        />
                                    </div>
                                </div>
                                {!monthlyView && (
                                    <div className='ItemWrapper pb-1 pt-1  the-item-wrapper align-items-center justify-content-center'>
                                        <div className='w-100 d-flex align-items-center justify-content-between'>
                                            <label className='BodyTwoLight white-text text-left m-0 w-75'>
                                                View Meetings
                                            </label>
                                            <ToggleButton
                                                checked={showTeamMembersMeetings}
                                                onChange={toggleTeamMeetings}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className='SidebarListWrapper'>
                        <div className='FilterSpacing-1'>
                            <div className='SectionHeader'>
                                <h2 className={'HeadlineThreeBold NameHeading'}>Filters</h2>
                                <div
                                    className='d-flex'
                                    style={{ gap: '4px', height: '100%', alignItems: 'center' }}
                                >
                                    <button
                                        className='clearButton p-0 m-0'
                                        onClick={clearAllFilters}
                                    >
                                        <h4 className='text-white BodyTwoBold m-0 pt-1'>
                                            Clear All
                                        </h4>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='SidebarListWrapper'>
                        <SavedFilters
                            title='Saved Filters'
                            onSave={onSaveFilters}
                            storedFilters={loggedInUserFilters}
                            appliedFilters={filters}
                            onChange={applySavedFilter}
                            onEditFilter={onEditFilter}
                            onDeleteFilter={onDeleteFilter}
                        />
                    </div>

                    <Restricted
                        AllowedUsers={[...standardPermissions, Member.teamMemberId]}
                        userTeamRole={user?.user_roles?.map((item) => item.role_id)}
                    >
                        <div className='SidebarListWrapper'>
                            <Filter
                                expandable={true}
                                title='People'
                                onClick={(option) => userProfileNavigation(option)}
                                options={filteredUsers}
                                onClear={() =>
                                    dispatch(WorkSpaceActions.handleClearUsersCheckbox())
                                }
                                onChange={(e) =>
                                    dispatch(
                                        WorkSpaceActions.handleUsersCheckbox(
                                            parseInt(e.target.value),
                                        ),
                                    )
                                }
                            />
                        </div>
                    </Restricted>

                    <div className='SidebarListWrapper'>
                        <Filter
                            expandable={true}
                            title='Teams'
                            options={teams}
                            onClick={(option) => teamProfileNavigation(option)}
                            onClear={() => dispatch(WorkSpaceActions.handleClearTeamCheckbox())}
                            onChange={(e) =>
                                dispatch(
                                    WorkSpaceActions.handleTeamCheckbox(parseInt(e.target.value)),
                                )
                            }
                        />
                    </div>

                    <div className='SidebarListWrapper'>
                        <Filter
                            expandable={true}
                            title='Clients'
                            onClick={(option) => clientProfileNavigation(option)}
                            options={filteredClients}
                            onClear={() => dispatch(WorkSpaceActions.handleClearClientsCheckbox())}
                            onChange={(e) =>
                                dispatch(
                                    WorkSpaceActions.handleClientsCheckbox(
                                        parseInt(e.target.value),
                                    ),
                                )
                            }
                        />
                    </div>

                    <div className='SidebarListWrapper'>
                        <Filter
                            expandable={true}
                            title='Workflows'
                            options={filteredWorkflows}
                            onClear={() =>
                                dispatch(WorkSpaceActions.handleClearWorkflowsCheckbox())
                            }
                            onChange={(e) =>
                                dispatch(
                                    WorkSpaceActions.handleWorkflowsCheckbox(
                                        parseInt(e.target.value),
                                    ),
                                )
                            }
                        />
                    </div>

                    <div className='SidebarListWrapper'>
                        <Filter
                            title='Task Status'
                            options={filteredtaskStatuses}
                            onClear={() =>
                                dispatch(WorkSpaceActions.handleClearTaskStatusesCheckbox())
                            }
                            onChange={(e) =>
                                dispatch(WorkSpaceActions.handleTaskStatusCheckbox(e.target.value))
                            }
                        />
                    </div>
                </div>
                <button
                    className='rounded-button-1'
                    onClick={() => {
                        dispatch(WorkSpaceActions.getAssignees());
                        setModalIsOpen(true);
                    }}
                >
                    Create Task
                </button>
            </div>

            <Modal
                onClose={changeModalState}
                open={modalIsOpen}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <>
                    {ModalName == modalNames.DELETE_FILTER_CONFIRM_MODAL && (
                        <ConfirmationModal
                            ref={ModalRef}
                            onClose={changeModalState}
                            onConfirm={deleteFilter}
                            title={'Delete Filter'}
                            description={`Are you sure you want to delete ${filterToPerformActionOn?.name} filter?`}
                        />
                    )}

                    {ModalName == modalNames.SAVE_FILTER_MODAL && (
                        <SaveWorkspaceFilterModal
                            people={filteredUsers}
                            teams={teams}
                            clients={filteredClients}
                            status={filteredtaskStatuses}
                            workflows={filteredWorkflows}
                            selectedFilter={filterToPerformActionOn}
                            onClose={changeModalState}
                            saveFilter={saveFilter}
                            ref={ModalRef}
                        />
                    )}
                </>
            </Modal>
        </Sidebar>
    );
};

export default memo(WorkspaceSidebar);
