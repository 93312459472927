import React from 'react';
import { CircularProgress } from '@mui/material';

const UserTableHeader = [
	{ key: 'client', label: 'Client' },
	{ key: 'billable', label: 'Billable Hours' },
	{ key: 'nonBillable', label: 'Non-Billable Hours' },
	{ key: 'totalLoggedTime', label: 'Logged Time' },
	{ key: 'totalCost', label: 'Total Cost' },
	{ key: 'revenue', label: 'Revenue' },
	{ key: 'profit', label: 'Profit' },
	{ key: 'margin', label: 'Margin' },
	{ key: 'view', label: 'Details' },
];

const AnalyticsTable = ({ data, loading, onViewClick, currencySymbol }) => {
	const [sortConfig, setSortConfig] = React.useState({ key: null, direction: 'ascending' });

	const sortedData = React.useMemo(() => {
		if (sortConfig.key) {
			return [...data].sort((a, b) => {
				if (a[sortConfig.key] < b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? -1 : 1;
				}
				if (a[sortConfig.key] > b[sortConfig.key]) {
					return sortConfig.direction === 'ascending' ? 1 : -1;
				}
				return 0;
			});
		}
		return data;
	}, [data, sortConfig]);

	const requestSort = (key) => {
		let direction = 'ascending';
		if (sortConfig.key === key && sortConfig.direction === 'ascending') {
			direction = 'descending';
		}
		setSortConfig({ key, direction });
	};

	return (
		<table className='TimeReportsPreviewTable' style={{ backgroundColor: '#F7F9FA' }}>
			<thead>
				<tr style={{ height: '56px' }}>
					{UserTableHeader?.map((header) => (
						<React.Fragment key={header.key}>
							<th>
								{header.label}
								{header.key !== 'client' && header.key !== 'view' && (
									<span
										style={{ cursor: 'pointer', marginLeft: '5px' }}
										onClick={() => requestSort(header.key)}
									>
										{sortConfig.key === header.key
											? sortConfig.direction === 'ascending'
												? '↑'
												: '↓'
											: '↕'}
									</span>
								)}
							</th>
						</React.Fragment>
					))}
				</tr>
			</thead>

			{loading ? (
				<tbody>
					<tr>
						<td colSpan={UserTableHeader.length} style={{ textAlign: 'center' }}>
							<CircularProgress
								style={{
									color: '#fa7b03',
									height: '25px',
									width: '25px',
								}}
							/>
						</td>
					</tr>
				</tbody>
			) : (
				<tbody>
					{sortedData?.length === 0 && (
						<tr style={{ height: '38px' }}>
							<td className='text-center light-text' colSpan={9}>
								No Data Found
							</td>
						</tr>
					)}
					{sortedData?.map((clientData) => (
						<React.Fragment key={clientData?.id}>
							<tr style={{ height: '38px' }}>
								<td>{clientData?.client}</td>
								<td>{clientData?.billable}</td>
								<td>{clientData?.nonBillable}</td>
								<td>{clientData?.totalLoggedTime}</td>
								<td>{currencySymbol}{clientData?.totalCost}</td>
								<td>{currencySymbol}{clientData?.revenue}</td>
								<td style={{ color: clientData?.profit < 0 ? 'red' : '#18c58d' }}>
                                {currencySymbol}{clientData?.profit}
								</td>
								<td>{clientData?.margin}%</td>
								<td>
									<span
										style={{ color: '#FB7A03', cursor: 'pointer' }}
										onClick={() => onViewClick(clientData)}
									>
										View
									</span>
								</td>
							</tr>
						</React.Fragment>
					))}
				</tbody>
			)}
		</table>
	);
};

export default AnalyticsTable;
