import { ResponsiveLine } from '@nivo/line';

const LineGraph = ({lineGraphData}) => {

    return (
        <div style={{ height: 450, width: '100%', display:'flex', alignItems:'center', justifyContent:'center', overflowX: 'auto' }}>
            <div style={{ width: '100%', height: 350  }}>
            <ResponsiveLine
                data={lineGraphData}
                margin={{ top: 0, bottom: 50, left: 40, right: 40}}
                xScale={{ type: 'point' }}
                yScale={{ type: 'linear', min: 'auto', max: 'auto', stacked: false, reverse: false }}
                axisTop={null}
                axisRight={null}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 0,
                    tickRotation: 0,
                    legend: '',
                    legendOffset: 36,
                    legendPosition: 'middle'
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 0,
                    tickRotation: 0,
                    tickValues: [],
                    legend: '',
                    legendOffset: -40,
                    legendPosition: 'middle'
                }}
                colors={{ datum: 'color' }}
                pointSize={0}
                pointColor={{ theme: 'background' }}
                pointBorderWidth={2}
                pointBorderColor={{ from: 'serieColor' }}
                pointLabelYOffset={-12}
                useMesh={true}
                legends={[
                    {
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 50,
                        itemsSpacing: 0,
                        itemDirection: 'left-to-right',
                        itemWidth: 80,
                    
                        itemHeight: 20,
                        itemOpacity: 0.75,
                        symbolSize: 12,
                        symbolShape: 'circle',
                        symbolBorderColor: 'rgba(0, 0, 0, .5)',
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemBackground: 'rgba(0, 0, 0, .03)',
                                    itemOpacity: 1
                                }
                            }
                        ]
                    }
                ]}
            />
            </div>
        </div>
    );
};


export default LineGraph;